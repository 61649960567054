"use strict";

Object.defineProperty(exports, "__esModule", {
  value: !0
});
var EntityType,
  MagnitudeOfDelay,
  GuidanceInstructionType,
  DrivingSide,
  GuidanceManeuver,
  TransitType,
  LegType,
  RelativeDirection,
  AbsoluteDirection,
  ScheduleType,
  SearchIndexSet,
  ConnectorSet,
  OpeningHours,
  VehicleLoadType,
  RouteType,
  Windingness,
  Hilliness,
  TravelMode,
  Avoid,
  VehicleEngineType,
  SectionType,
  RouteRepresentation,
  ComputeTravelTimeFor,
  AlternativeRouteType,
  RouteInstructionsType,
  TimezoneOptions,
  TileFormat,
  GeofenceMode,
  StaticMapLayer,
  MapTileLayer,
  MapTileStyle,
  MetroAreaQueryType,
  MetroAreaDetailType,
  ObjectType,
  TransitLineDetailType,
  TransitStopQueryType,
  TransitStopDetailType,
  OriginType,
  DestinationType,
  ModeType,
  TransitTypeFilter,
  AgencyType,
  TimeType,
  TransitRouteType,
  BikeType,
  TransitItineraryDetailType,
  RealTimeArrivalsQueryType,
  StopQueryType,
  Type,
  Style,
  Text,
  Text1,
  Text2,
  msRest = require("@azure/ms-rest-js"),
  msRestAzure = require("@azure/ms-rest-azure-js");
!function (e) {
  e.Country = "Country", e.CountrySubdivision = "CountrySubdivision", e.CountrySecondarySubdivision = "CountrySecondarySubdivision", e.CountryTertiarySubdivision = "CountryTertiarySubdivision", e.Municipality = "Municipality", e.MunicipalitySubdivision = "MunicipalitySubdivision", e.Neighbourhood = "Neighbourhood", e.PostalCodeArea = "PostalCodeArea";
}(EntityType = EntityType || {}), function (e) {
  e.Zero = "0", e.One = "1", e.Two = "2", e.Three = "3", e.Four = "4";
}(MagnitudeOfDelay = MagnitudeOfDelay || {}), function (e) {
  e.TURN = "TURN", e.ROADCHANGE = "ROAD_CHANGE", e.LOCATIONDEPARTURE = "LOCATION_DEPARTURE", e.LOCATIONARRIVAL = "LOCATION_ARRIVAL", e.DIRECTIONINFO = "DIRECTION_INFO", e.LOCATIONWAYPOINT = "LOCATION_WAYPOINT";
}(GuidanceInstructionType = GuidanceInstructionType || {}), function (e) {
  e.LEFT = "LEFT", e.RIGHT = "RIGHT";
}(DrivingSide = DrivingSide || {}), function (e) {
  e.ARRIVE = "ARRIVE", e.ARRIVELEFT = "ARRIVE_LEFT", e.ARRIVERIGHT = "ARRIVE_RIGHT", e.DEPART = "DEPART", e.STRAIGHT = "STRAIGHT", e.KEEPRIGHT = "KEEP_RIGHT", e.BEARRIGHT = "BEAR_RIGHT", e.TURNRIGHT = "TURN_RIGHT", e.SHARPRIGHT = "SHARP_RIGHT", e.KEEPLEFT = "KEEP_LEFT", e.BEARLEFT = "BEAR_LEFT", e.TURNLEFT = "TURN_LEFT", e.SHARPLEFT = "SHARP_LEFT", e.MAKEUTURN = "MAKE_UTURN", e.ENTERMOTORWAY = "ENTER_MOTORWAY", e.ENTERFREEWAY = "ENTER_FREEWAY", e.ENTERHIGHWAY = "ENTER_HIGHWAY", e.TAKEEXIT = "TAKE_EXIT", e.MOTORWAYEXITLEFT = "MOTORWAY_EXIT_LEFT", e.MOTORWAYEXITRIGHT = "MOTORWAY_EXIT_RIGHT", e.TAKEFERRY = "TAKE_FERRY", e.ROUNDABOUTCROSS = "ROUNDABOUT_CROSS", e.ROUNDABOUTRIGHT = "ROUNDABOUT_RIGHT", e.ROUNDABOUTLEFT = "ROUNDABOUT_LEFT", e.ROUNDABOUTBACK = "ROUNDABOUT_BACK", e.TRYMAKEUTURN = "TRY_MAKE_UTURN", e.FOLLOW = "FOLLOW", e.SWITCHPARALLELROAD = "SWITCH_PARALLEL_ROAD", e.SWITCHMAINROAD = "SWITCH_MAIN_ROAD", e.ENTRANCERAMP = "ENTRANCE_RAMP", e.WAYPOINTLEFT = "WAYPOINT_LEFT", e.WAYPOINTRIGHT = "WAYPOINT_RIGHT", e.WAYPOINTREACHED = "WAYPOINT_REACHED";
}(GuidanceManeuver = GuidanceManeuver || {}), function (e) {
  e.Bus = "Bus", e.CableCar = "CableCar", e.Ferry = "Ferry", e.Funicular = "Funicular", e.Gondola = "Gondola", e.Rail = "Rail", e.Tram = "Tram", e.Subway = "Subway";
}(TransitType = TransitType || {}), function (e) {
  e.Walk = "Walk", e.Bicycle = "Bicycle", e.Tram = "Tram", e.Subway = "Subway", e.Rail = "Rail", e.Bus = "Bus", e.Ferry = "Ferry", e.Cable = "Cable", e.Gondola = "Gondola", e.Funicular = "Funicular", e.PathWayWalk = "PathWayWalk", e.Wait = "Wait", e.WaitOnVehicle = "WaitOnVehicle";
}(LegType = LegType || {}), function (e) {
  e.Depart = "depart", e.HardLeft = "hardLeft", e.Left = "left", e.SlightlyLeft = "slightlyLeft", e.Continue = "continue", e.SlightlyRight = "slightlyRight", e.Right = "right", e.HardRight = "hardRight", e.CircleClockwise = "circleClockwise", e.CircleCounterclockwise = "circleCounterclockwise", e.Elevator = "elevator", e.UturnLeft = "uturnLeft", e.UturnRight = "uturnRight";
}(RelativeDirection = RelativeDirection || {}), function (e) {
  e.North = "north", e.Northeast = "northeast", e.East = "east", e.Southeast = "southeast", e.South = "south", e.Southwest = "southwest", e.West = "west", e.Northwest = "northwest";
}(AbsoluteDirection = AbsoluteDirection || {}), function (e) {
  e.ScheduledTime = "scheduledTime", e.RealTime = "realTime";
}(ScheduleType = ScheduleType || {}), function (e) {
  e.Addr = "Addr", e.Geo = "Geo", e.PAD = "PAD", e.POI = "POI", e.Str = "Str", e.Xstr = "Xstr";
}(SearchIndexSet = SearchIndexSet || {}), function (e) {
  e.StandardHouseholdCountrySpecific = "StandardHouseholdCountrySpecific", e.IEC62196Type1 = "IEC62196Type1", e.IEC62196Type1CCS = "IEC62196Type1CCS", e.IEC62196Type2CableAttached = "IEC62196Type2CableAttached", e.IEC62196Type2Outlet = "IEC62196Type2Outlet", e.IEC62196Type2CCS = "IEC62196Type2CCS", e.IEC62196Type3 = "IEC62196Type3", e.Chademo = "Chademo", e.IEC60309AC1PhaseBlue = "IEC60309AC1PhaseBlue", e.IEC60309DCWhite = "IEC60309DCWhite", e.Tesla = "Tesla";
}(ConnectorSet = ConnectorSet || {}), (OpeningHours || (OpeningHours = {})).NextSevenDays = "nextSevenDays", function (e) {
  e.USHazmatClass1 = "USHazmatClass1", e.USHazmatClass2 = "USHazmatClass2", e.USHazmatClass3 = "USHazmatClass3", e.USHazmatClass4 = "USHazmatClass4", e.USHazmatClass5 = "USHazmatClass5", e.USHazmatClass6 = "USHazmatClass6", e.USHazmatClass7 = "USHazmatClass7", e.USHazmatClass8 = "USHazmatClass8", e.USHazmatClass9 = "USHazmatClass9", e.OtherHazmatExplosive = "otherHazmatExplosive", e.OtherHazmatGeneral = "otherHazmatGeneral", e.OtherHazmatHarmfulToWater = "otherHazmatHarmfulToWater";
}(VehicleLoadType = VehicleLoadType || {}), function (e) {
  e.Fastest = "fastest", e.Shortest = "shortest", e.Eco = "eco", e.Thrilling = "thrilling";
}(RouteType = RouteType || {}), function (e) {
  e.Low = "low", e.Normal = "normal", e.High = "high";
}(Windingness = Windingness || {}), function (e) {
  e.Low = "low", e.Normal = "normal", e.High = "high";
}(Hilliness = Hilliness || {}), function (e) {
  e.Car = "car", e.Truck = "truck", e.Taxi = "taxi", e.Bus = "bus", e.Van = "van", e.Motorcycle = "motorcycle", e.Bicycle = "bicycle", e.Pedestrian = "pedestrian";
}(TravelMode = TravelMode || {}), function (e) {
  e.TollRoads = "tollRoads", e.Motorways = "motorways", e.Ferries = "ferries", e.UnpavedRoads = "unpavedRoads", e.Carpools = "carpools", e.AlreadyUsedRoads = "alreadyUsedRoads", e.BorderCrossings = "borderCrossings";
}(Avoid = Avoid || {}), function (e) {
  e.Combustion = "combustion", e.Electric = "electric";
}(VehicleEngineType = VehicleEngineType || {}), function (e) {
  e.CarTrain = "carTrain", e.Country = "country", e.Ferry = "ferry", e.Motorway = "motorway", e.Pedestrian = "pedestrian", e.TollRoad = "tollRoad", e.TollVignette = "tollVignette", e.Traffic = "traffic", e.TravelMode = "travelMode", e.Tunnel = "tunnel";
}(SectionType = SectionType || {}), function (e) {
  e.Polyline = "polyline", e.SummaryOnly = "summaryOnly", e.None = "none";
}(RouteRepresentation = RouteRepresentation || {}), function (e) {
  e.None = "none", e.All = "all";
}(ComputeTravelTimeFor = ComputeTravelTimeFor || {}), function (e) {
  e.AnyRoute = "anyRoute", e.BetterRoute = "betterRoute";
}(AlternativeRouteType = AlternativeRouteType || {}), function (e) {
  e.Coded = "coded", e.Text = "text", e.Tagged = "tagged";
}(RouteInstructionsType = RouteInstructionsType || {}), function (e) {
  e.None = "none", e.ZoneInfo = "zoneInfo", e.Transitions = "transitions", e.All = "all";
}(TimezoneOptions = TimezoneOptions || {}), function (e) {
  e.Png = "png", e.Pbf = "pbf";
}(TileFormat = TileFormat || {}), function (e) {
  e.All = "All", e.EnterAndExit = "EnterAndExit";
}(GeofenceMode = GeofenceMode || {}), function (e) {
  e.Basic = "basic", e.Hybrid = "hybrid", e.Labels = "labels";
}(StaticMapLayer = StaticMapLayer || {}), function (e) {
  e.Basic = "basic", e.Hybrid = "hybrid", e.Labels = "labels", e.Terra = "terra";
}(MapTileLayer = MapTileLayer || {}), function (e) {
  e.Main = "main", e.ShadedRelief = "shaded_relief";
}(MapTileStyle = MapTileStyle || {}), function (e) {
  e.Position = "position", e.CountryCode = "countryCode";
}(MetroAreaQueryType = MetroAreaQueryType || {}), function (e) {
  e.Agencies = "agencies", e.Alerts = "alerts", e.AlertDetails = "alertDetails", e.TransitTypes = "transitTypes";
}(MetroAreaDetailType = MetroAreaDetailType || {}), function (e) {
  e.Stop = "stop", e.DocklessBike = "docklessBike", e.DocklessElectricBike = "docklessElectricBike", e.DocklessElectricScooter = "docklessElectricScooter", e.DocklessScooter = "docklessScooter", e.DocklessMoped = "docklessMoped", e.CarShare = "carShare", e.DocklessVehicle = "docklessVehicle", e.BikeDock = "bikeDock";
}(ObjectType = ObjectType || {}), function (e) {
  e.Alerts = "alerts", e.AlertDetails = "alertDetails", e.Lines = "lines", e.Stops = "stops", e.Schedule = "schedule", e.Patterns = "patterns";
}(TransitLineDetailType = TransitLineDetailType || {}), function (e) {
  e.StopId = "stopId", e.StopKey = "stopKey";
}(TransitStopQueryType = TransitStopQueryType || {}), function (e) {
  e.Alerts = "alerts", e.AlertDetails = "alertDetails", e.Lines = "lines", e.LineGroups = "lineGroups";
}(TransitStopDetailType = TransitStopDetailType || {}), function (e) {
  e.Position = "position", e.StopId = "stopId", e.StopKey = "stopKey";
}(OriginType = OriginType || {}), function (e) {
  e.Position = "position", e.StopId = "stopId", e.StopKey = "stopKey";
}(DestinationType = DestinationType || {}), function (e) {
  e.Walk = "walk", e.Bike = "bike", e.PublicTransit = "publicTransit";
}(ModeType = ModeType || {}), function (e) {
  e.Bus = "bus", e.CableCar = "cableCar", e.Ferry = "ferry", e.Funicular = "funicular", e.Gondola = "gondola", e.Rail = "rail", e.Tram = "tram", e.Subway = "subway";
}(TransitTypeFilter = TransitTypeFilter || {}), function (e) {
  e.AgencyId = "agencyId", e.AgencyKey = "agencyKey", e.AgencyName = "agencyName";
}(AgencyType = AgencyType || {}), function (e) {
  e.Arrival = "arrival", e.Departure = "departure", e.Last = "last";
}(TimeType = TimeType || {}), function (e) {
  e.Optimal = "optimal", e.LeastWalk = "leastWalk", e.LeastTransfers = "leastTransfers";
}(TransitRouteType = TransitRouteType || {}), function (e) {
  e.PrivateBike = "privateBike", e.DockedBike = "dockedBike";
}(BikeType = BikeType || {}), function (e) {
  e.Geometry = "geometry", e.Schedule = "schedule";
}(TransitItineraryDetailType = TransitItineraryDetailType || {}), function (e) {
  e.Stops = "stops", e.Line = "line", e.LineAndStop = "lineAndStop", e.Position = "position";
}(RealTimeArrivalsQueryType = RealTimeArrivalsQueryType || {}), function (e) {
  e.StopId = "stopId", e.StopKey = "stopKey";
}(StopQueryType = StopQueryType || {}), function (e) {
  e.Main = "main", e.Minor = "minor";
}(Type = Type || {}), (Style || (Style = {})).Main = "main", function (e) {
  e.Yes = "yes", e.No = "no";
}(Text = Text || {}), function (e) {
  e.Yes = "yes", e.No = "no";
}(Text1 = Text1 || {}), function (e) {
  e.Yes = "yes", e.No = "no";
}(Text2 = Text2 || {});
var index = Object.freeze({
    __proto__: null,
    get EntityType() {
      return EntityType;
    },
    get MagnitudeOfDelay() {
      return MagnitudeOfDelay;
    },
    get GuidanceInstructionType() {
      return GuidanceInstructionType;
    },
    get DrivingSide() {
      return DrivingSide;
    },
    get GuidanceManeuver() {
      return GuidanceManeuver;
    },
    get TransitType() {
      return TransitType;
    },
    get LegType() {
      return LegType;
    },
    get RelativeDirection() {
      return RelativeDirection;
    },
    get AbsoluteDirection() {
      return AbsoluteDirection;
    },
    get ScheduleType() {
      return ScheduleType;
    },
    get SearchIndexSet() {
      return SearchIndexSet;
    },
    get ConnectorSet() {
      return ConnectorSet;
    },
    get OpeningHours() {
      return OpeningHours;
    },
    get VehicleLoadType() {
      return VehicleLoadType;
    },
    get RouteType() {
      return RouteType;
    },
    get Windingness() {
      return Windingness;
    },
    get Hilliness() {
      return Hilliness;
    },
    get TravelMode() {
      return TravelMode;
    },
    get Avoid() {
      return Avoid;
    },
    get VehicleEngineType() {
      return VehicleEngineType;
    },
    get SectionType() {
      return SectionType;
    },
    get RouteRepresentation() {
      return RouteRepresentation;
    },
    get ComputeTravelTimeFor() {
      return ComputeTravelTimeFor;
    },
    get AlternativeRouteType() {
      return AlternativeRouteType;
    },
    get RouteInstructionsType() {
      return RouteInstructionsType;
    },
    get TimezoneOptions() {
      return TimezoneOptions;
    },
    get TileFormat() {
      return TileFormat;
    },
    get GeofenceMode() {
      return GeofenceMode;
    },
    get StaticMapLayer() {
      return StaticMapLayer;
    },
    get MapTileLayer() {
      return MapTileLayer;
    },
    get MapTileStyle() {
      return MapTileStyle;
    },
    get MetroAreaQueryType() {
      return MetroAreaQueryType;
    },
    get MetroAreaDetailType() {
      return MetroAreaDetailType;
    },
    get ObjectType() {
      return ObjectType;
    },
    get TransitLineDetailType() {
      return TransitLineDetailType;
    },
    get TransitStopQueryType() {
      return TransitStopQueryType;
    },
    get TransitStopDetailType() {
      return TransitStopDetailType;
    },
    get OriginType() {
      return OriginType;
    },
    get DestinationType() {
      return DestinationType;
    },
    get ModeType() {
      return ModeType;
    },
    get TransitTypeFilter() {
      return TransitTypeFilter;
    },
    get AgencyType() {
      return AgencyType;
    },
    get TimeType() {
      return TimeType;
    },
    get TransitRouteType() {
      return TransitRouteType;
    },
    get BikeType() {
      return BikeType;
    },
    get TransitItineraryDetailType() {
      return TransitItineraryDetailType;
    },
    get RealTimeArrivalsQueryType() {
      return RealTimeArrivalsQueryType;
    },
    get StopQueryType() {
      return StopQueryType;
    },
    get Type() {
      return Type;
    },
    get Style() {
      return Style;
    },
    get Text() {
      return Text;
    },
    get Text1() {
      return Text1;
    },
    get Text2() {
      return Text2;
    }
  }),
  Aborter = function () {
    function r(e, t, a, r) {
      var i = this;
      void 0 === t && (t = 0), this._aborted = !1, this.children = [], this.abortEventListeners = [], this.parent = e, this.key = a, this.value = r, 0 < t && (this.timer = setTimeout(function () {
        i.abort.call(i);
      }, t), this.timer && "function" == typeof this.timer.unref && this.timer.unref());
    }
    return Object.defineProperty(r.prototype, "aborted", {
      get: function () {
        return this._aborted;
      },
      enumerable: !1,
      configurable: !0
    }), Object.defineProperty(r, "none", {
      get: function () {
        return new r(void 0, 0);
      },
      enumerable: !1,
      configurable: !0
    }), r.timeout = function (e) {
      return new r(void 0, e);
    }, r.prototype.withTimeout = function (e) {
      var t = new r(this, e);
      return this.children.push(t), t;
    }, r.prototype.withValue = function (e, t) {
      var a = new r(this, 0, e, t);
      return this.children.push(a), a;
    }, r.prototype.getValue = function (e) {
      for (var t = this; t; t = t.parent) if (t.key === e) return t.value;
    }, r.prototype.abort = function () {
      var t = this;
      this.aborted || (this.cancelTimer(), this.onabort && this.onabort.call(this), this.abortEventListeners.forEach(function (e) {
        e.call(t, void 0);
      }), this.children.forEach(function (e) {
        return e.cancelByParent();
      }), this._aborted = !0);
    }, r.prototype.addEventListener = function (e, t) {
      this.abortEventListeners.push(t);
    }, r.prototype.removeEventListener = function (e, t) {
      var a = this.abortEventListeners.indexOf(t);
      -1 < a && this.abortEventListeners.splice(a, 1);
    }, r.prototype.cancelByParent = function () {
      this.abort();
    }, r.prototype.cancelTimer = function () {
      this.timer && clearTimeout(this.timer);
    }, r;
  }(),
  Credential = function () {
    function e() {}
    return e.prototype.create = function (e, t) {
      throw new Error("Method should be implemented in children classes.");
    }, e;
  }(),
  extendStatics = function (e, t) {
    return (extendStatics = Object.setPrototypeOf || {
      __proto__: []
    } instanceof Array && function (e, t) {
      e.__proto__ = t;
    } || function (e, t) {
      for (var a in t) t.hasOwnProperty(a) && (e[a] = t[a]);
    })(e, t);
  };
function __extends(e, t) {
  function a() {
    this.constructor = e;
  }
  extendStatics(e, t), e.prototype = null === t ? Object.create(t) : (a.prototype = t.prototype, new a());
}
var __assign = function () {
  return (__assign = Object.assign || function (e) {
    for (var t, a = 1, r = arguments.length; a < r; a++) for (var i in t = arguments[a]) Object.prototype.hasOwnProperty.call(t, i) && (e[i] = t[i]);
    return e;
  }).apply(this, arguments);
};
function __awaiter(s, n, o, m) {
  return new (o = o || Promise)(function (e, t) {
    function a(e) {
      try {
        i(m.next(e));
      } catch (e) {
        t(e);
      }
    }
    function r(e) {
      try {
        i(m.throw(e));
      } catch (e) {
        t(e);
      }
    }
    function i(t) {
      t.done ? e(t.value) : new o(function (e) {
        e(t.value);
      }).then(a, r);
    }
    i((m = m.apply(s, n || [])).next());
  });
}
function __generator(a, r) {
  var i,
    s,
    n,
    e,
    o = {
      label: 0,
      sent: function () {
        if (1 & n[0]) throw n[1];
        return n[1];
      },
      trys: [],
      ops: []
    };
  return e = {
    next: t(0),
    throw: t(1),
    return: t(2)
  }, "function" == typeof Symbol && (e[Symbol.iterator] = function () {
    return this;
  }), e;
  function t(t) {
    return function (e) {
      return function (t) {
        if (i) throw new TypeError("Generator is already executing.");
        for (; o;) try {
          if (i = 1, s && (n = 2 & t[0] ? s.return : t[0] ? s.throw || ((n = s.return) && n.call(s), 0) : s.next) && !(n = n.call(s, t[1])).done) return n;
          switch (s = 0, n && (t = [2 & t[0], n.value]), t[0]) {
            case 0:
            case 1:
              n = t;
              break;
            case 4:
              return o.label++, {
                value: t[1],
                done: !1
              };
            case 5:
              o.label++, s = t[1], t = [0];
              continue;
            case 7:
              t = o.ops.pop(), o.trys.pop();
              continue;
            default:
              if (!(n = 0 < (n = o.trys).length && n[n.length - 1]) && (6 === t[0] || 2 === t[0])) {
                o = 0;
                continue;
              }
              if (3 === t[0] && (!n || t[1] > n[0] && t[1] < n[3])) {
                o.label = t[1];
                break;
              }
              if (6 === t[0] && o.label < n[1]) {
                o.label = n[1], n = t;
                break;
              }
              if (n && o.label < n[2]) {
                o.label = n[2], o.ops.push(t);
                break;
              }
              n[2] && o.ops.pop(), o.trys.pop();
              continue;
          }
          t = r.call(a, o);
        } catch (e) {
          t = [6, e], s = 0;
        } finally {
          i = n = 0;
        }
        if (5 & t[0]) throw t[1];
        return {
          value: t[0] ? t[1] : void 0,
          done: !0
        };
      }([t, e]);
    };
  }
}
var CredentialPolicy = function (e) {
    function t() {
      return null !== e && e.apply(this, arguments) || this;
    }
    return __extends(t, e), t.prototype.sendRequest = function (r) {
      return __awaiter(this, void 0, void 0, function () {
        var t, a;
        return __generator(this, function (e) {
          switch (e.label) {
            case 0:
              return a = (t = this._nextPolicy).sendRequest, [4, this.signRequest(r)];
            case 1:
              return [2, a.apply(t, [e.sent()])];
          }
        });
      });
    }, t.prototype.signRequest = function (e) {
      return e;
    }, t;
  }(msRest.BaseRequestPolicy),
  MapControlCredentialPolicy = function (i) {
    function e(e, t, a) {
      var r = i.call(this, e, t) || this;
      return r.map = a, r;
    }
    return __extends(e, i), e.prototype.signRequest = function (s) {
      return __awaiter(this, void 0, void 0, function () {
        var t, a, r, i;
        return __generator(this, function (e) {
          switch (e.label) {
            case 0:
              return [4, this.map.authentication.initialize()];
            case 1:
              if (e.sent(), (t = this.map.authentication.signRequest({
                url: s.url
              })).headers) for (a = 0, r = Object.keys(t.headers); a < r.length; a++) i = r[a], s.headers.set(i, t.headers[i]);
              return s.url = t.url, [2, s];
          }
        });
      });
    }, e;
  }(CredentialPolicy),
  MapControlCredential = function (a) {
    function e(e) {
      var t = a.call(this) || this;
      return t.map = e, t;
    }
    return __extends(e, a), e.prototype.create = function (e, t) {
      return new MapControlCredentialPolicy(e, t, this.map);
    }, e;
  }(Credential),
  SERVICE_VERSION = "1.0",
  HTTPURLConnection = {
    HTTP_CONFLICT: 409,
    HTTP_NOT_FOUND: 404,
    HTTP_PRECON_FAILED: 412,
    HTTP_RANGE_NOT_SATISFIABLE: 416
  },
  HeaderConstants = {
    AUTHORIZATION: "authorization",
    AUTHORIZATION_SCHEME: "Bearer",
    SAS_SCHEME: "jwt-sas",
    DATE: "date",
    MAP_AGENT: "Map-Agent",
    MS_AM_REQUEST_ORIGIN: "Ms-Am-Request-Origin",
    SERVICE_MODULE: "ServiceModule",
    X_MS_CLIENT_ID: "x-ms-client-id"
  },
  SasCredentialPolicy = function (i) {
    function e(e, t, a) {
      var r = i.call(this, e, t) || this;
      return r.tokenCredential = a, r.authorizationScheme = HeaderConstants.SAS_SCHEME, r;
    }
    return __extends(e, i), e.prototype.signRequest = function (e) {
      return e.headers || (e.headers = new msRest.HttpHeaders()), e.headers.set(HeaderConstants.AUTHORIZATION, this.authorizationScheme + " " + this.tokenCredential.token), e;
    }, e;
  }(CredentialPolicy),
  SasCredential = function (a) {
    function e(e) {
      var t = a.call(this) || this;
      return t.token = e, t;
    }
    return __extends(e, a), e.prototype.create = function (e, t) {
      return new SasCredentialPolicy(e, t, this);
    }, e;
  }(Credential);
function setURLParameter(e, t, a) {
  var r = new RegExp("([?&]+" + t + "=)[^&]*", "g"),
    i = !1;
  return e = e.replace(r, function (e, t) {
    return i = !0, t + a;
  }), i || (e.includes("?") ? e += "&" + t + "=" + a : e += "?" + t + "=" + a), e;
}
function streamToByteArray(r) {
  return new Promise(function (e, t) {
    var a = [];
    r.on("error", function (e) {
      return t(e);
    }), r.on("data", function (e) {
      return a.push(e);
    }), r.on("end", function () {
      return e(Buffer.concat(a));
    });
  });
}
function blobToByteArray(a) {
  return __awaiter(this, void 0, void 0, function () {
    var t;
    return __generator(this, function (e) {
      switch (e.label) {
        case 0:
          return t = Uint8Array.bind, [4, new Response(a).arrayBuffer()];
        case 1:
          return [2, new (t.apply(Uint8Array, [void 0, e.sent()]))()];
      }
    });
  });
}
function calculateBoundingBox(e) {
  for (var t = [1 / 0, 1 / 0, -1 / 0, -1 / 0], a = 0, r = e; a < r.length; a++) {
    var i = r[a];
    switch (i.geometry.type) {
      case "Point":
        addPointToBbox(t, i.geometry.coordinates);
        break;
      case "MultiPoint":
        i.geometry.coordinates.forEach(function (e) {
          return addPointToBbox(t, e);
        });
        break;
      case "LineString":
        addLineToBbox(t, i.geometry.coordinates);
        break;
      case "MultiLineString":
        i.geometry.coordinates.forEach(function (e) {
          return addLineToBbox(t, e);
        });
        break;
      case "Polygon":
        addPolyToBbox(t, i.geometry.coordinates);
        break;
      case "MultiPolygon":
        i.geometry.coordinates.forEach(function (e) {
          return addPolyToBbox(t, e);
        });
        break;
      default:
        throw new Error("The geometry type " + i.geometry.type + " isn't supported");
    }
  }
  return t;
}
function addPointToBbox(e, t) {
  e[0] = Math.min(e[0], t[0]), e[1] = Math.min(e[1], t[1]), e[2] = Math.max(e[2], t[0]), e[3] = Math.max(e[3], t[1]);
}
function addLineToBbox(e, t) {
  for (var a = 0, r = t; a < r.length; a++) {
    var i = r[a];
    e[0] = Math.min(e[0], i[0]), e[1] = Math.min(e[1], i[1]), e[2] = Math.max(e[2], i[0]), e[3] = Math.max(e[3], i[1]);
  }
}
function addPolyToBbox(e, t) {
  for (var a = 0, r = t; a < r.length; a++) for (var i = 0, s = r[a]; i < s.length; i++) {
    var n = s[i];
    e[0] = Math.min(e[0], n[0]), e[1] = Math.min(e[1], n[1]), e[2] = Math.max(e[2], n[0]), e[3] = Math.max(e[3], n[1]);
  }
}
var SubscriptionKeyCredentialPolicy = function (i) {
    function e(e, t, a) {
      var r = i.call(this, e, t) || this;
      return r.subscriptionKey = a, r;
    }
    return __extends(e, i), e.prototype.signRequest = function (e) {
      return e.url = setURLParameter(e.url, "subscription-key", this.subscriptionKey), e;
    }, e;
  }(CredentialPolicy),
  SubscriptionKeyCredential = function (a) {
    function e(e) {
      var t = a.call(this) || this;
      return t.subscriptionKey = e, t;
    }
    return __extends(e, a), e.prototype.create = function (e, t) {
      return new SubscriptionKeyCredentialPolicy(e, t, this.subscriptionKey);
    }, e;
  }(Credential),
  TokenCredentialPolicy = function (i) {
    function e(e, t, a) {
      var r = i.call(this, e, t) || this;
      return r.tokenCredential = a, r.authorizationScheme = HeaderConstants.AUTHORIZATION_SCHEME, r;
    }
    return __extends(e, i), e.prototype.signRequest = function (e) {
      return e.headers || (e.headers = new msRest.HttpHeaders()), e.headers.set(HeaderConstants.AUTHORIZATION, this.authorizationScheme + " " + this.tokenCredential.token), e.headers.set(HeaderConstants.X_MS_CLIENT_ID, this.tokenCredential.clientId), e;
    }, e;
  }(CredentialPolicy),
  TokenCredential = function (r) {
    function e(e, t) {
      var a = r.call(this) || this;
      return a.clientId = e, a.token = t, a;
    }
    return __extends(e, r), e.prototype.create = function (e, t) {
      return new TokenCredentialPolicy(e, t, this);
    }, e;
  }(Credential),
  Pipeline = function () {
    function e(e, t) {
      void 0 === t && (t = {}), this.factories = e, this.options = t;
    }
    return e.prototype.toServiceClientOptions = function () {
      return {
        httpClient: this.options.HTTPClient,
        httpPipelineLogger: this.options.logger,
        requestPolicyFactories: this.factories
      };
    }, e;
  }();
!function (e) {
  e.EXPONENTIAL = "exponential", e.FIXED = "fixed";
}(exports.RetryPolicyType || (exports.RetryPolicyType = {}));
var DEFAULT_RETRY_OPTIONS = {
    maxRetryDelayInMs: 12e4,
    maxTries: 4,
    retryDelayInMs: 4e3,
    retryPolicyType: exports.RetryPolicyType.EXPONENTIAL
  },
  RetryPolicy = function (i) {
    function e(e, t, a) {
      void 0 === a && (a = DEFAULT_RETRY_OPTIONS);
      var r = i.call(this, e, t) || this;
      return r.retryOptions = {
        retryPolicyType: "string" == typeof a.retryPolicyType ? a.retryPolicyType : DEFAULT_RETRY_OPTIONS.retryPolicyType,
        maxTries: "number" == typeof a.maxTries && 1 <= a.maxTries ? Math.floor(a.maxTries) : DEFAULT_RETRY_OPTIONS.maxTries,
        retryDelayInMs: "number" == typeof a.retryDelayInMs && 0 <= a.retryDelayInMs ? Math.min(a.retryDelayInMs, "number" == typeof a.maxRetryDelayInMs ? a.maxRetryDelayInMs : DEFAULT_RETRY_OPTIONS.maxRetryDelayInMs) : DEFAULT_RETRY_OPTIONS.retryDelayInMs,
        maxRetryDelayInMs: "number" == typeof a.maxRetryDelayInMs && 0 <= a.maxRetryDelayInMs ? a.maxRetryDelayInMs : DEFAULT_RETRY_OPTIONS.maxRetryDelayInMs
      }, r;
    }
    return __extends(e, i), e.prototype.sendRequest = function (t) {
      return __awaiter(this, void 0, void 0, function () {
        return __generator(this, function (e) {
          return [2, this.attemptSendRequest(t, 1)];
        });
      });
    }, e.prototype.attemptSendRequest = function (i, s) {
      return __awaiter(this, void 0, void 0, function () {
        var t, a, r;
        return __generator(this, function (e) {
          switch (e.label) {
            case 0:
              t = i.clone(), e.label = 1;
            case 1:
              return e.trys.push([1, 3,, 4]), this.logf(msRest.HttpPipelineLogLevel.INFO, "RetryPolicy: =====> Try=" + s), [4, this._nextPolicy.sendRequest(t)];
            case 2:
              return a = e.sent(), this.shouldRetry(s, a) ? [3, 4] : [2, a];
            case 3:
              if (r = e.sent(), this.logf(msRest.HttpPipelineLogLevel.ERROR, "RetryPolicy: Caught error, message: " + r.message + ", code: " + r.code), !this.shouldRetry(s, a, r)) throw r;
              return [3, 4];
            case 4:
              return [4, this.delay(s)];
            case 5:
              return e.sent(), [4, this.attemptSendRequest(i, ++s)];
            case 6:
              return [2, e.sent()];
          }
        });
      });
    }, e.prototype.shouldRetry = function (e, t, a) {
      if (e >= this.retryOptions.maxTries) return this.logf(msRest.HttpPipelineLogLevel.INFO, "RetryPolicy: Attempt(s) " + e + " >= maxTries " + this.retryOptions.maxTries + ", no further try."), !1;
      if (a) for (var r = 0, i = ["ETIMEDOUT", "ESOCKETTIMEDOUT", "ECONNREFUSED", "ECONNRESET", "ENOENT", "ENOTFOUND", "TIMEOUT", "REQUEST_SEND_ERROR"]; r < i.length; r++) {
        var s = i[r];
        if (a.name.toUpperCase().includes(s) || a.message.toUpperCase().includes(s) || a.code && a.code.toUpperCase().includes(s)) return this.logf(msRest.HttpPipelineLogLevel.INFO, "RetryPolicy: Network error " + s + " found, will retry."), !0;
      }
      if (t || a) {
        var n = t ? t.status : a ? a.statusCode : 0;
        if (503 === n || 500 === n) return this.logf(msRest.HttpPipelineLogLevel.INFO, "RetryPolicy: Will retry for status code " + n + "."), !0;
      }
      return !1;
    }, e.prototype.logf = function (e, t) {}, e.prototype.delay = function (a) {
      return __awaiter(this, void 0, void 0, function () {
        var t;
        return __generator(this, function (e) {
          switch (t = 0, this.retryOptions.retryPolicyType) {
            case exports.RetryPolicyType.EXPONENTIAL:
              t = Math.min((Math.pow(2, a) - 1) * this.retryOptions.retryDelayInMs, this.retryOptions.maxRetryDelayInMs);
              break;
            case exports.RetryPolicyType.FIXED:
              t = this.retryOptions.retryDelayInMs;
          }
          return this.logf(msRest.HttpPipelineLogLevel.INFO, "RetryPolicy: Delay for " + t + "ms"), [2, msRest.delay(t)];
        });
      });
    }, e;
  }(msRest.BaseRequestPolicy),
  RetryPolicyFactory = function () {
    function e(e) {
      this.retryOptions = e;
    }
    return e.prototype.create = function (e, t) {
      return new RetryPolicy(e, t, this.retryOptions);
    }, e;
  }(),
  DEFAULT_REQUEST_LOG_OPTIONS = {
    logWarningIfTryOverThreshold: 3e3
  },
  LoggingPolicy = function (i) {
    function e(e, t, a) {
      void 0 === a && (a = DEFAULT_REQUEST_LOG_OPTIONS);
      var r = i.call(this, e, t) || this;
      return r.tryCount = 0, r.operationStartTime = new Date(), r.requestStartTime = new Date(), r.loggingOptions = a, r;
    }
    return __extends(e, i), e.prototype.sendRequest = function (u) {
      return __awaiter(this, void 0, void 0, function () {
        var t, a, r, i, s, n, o, m, l, p;
        return __generator(this, function (e) {
          switch (e.label) {
            case 0:
              this.tryCount++, this.requestStartTime = new Date(), 1 === this.tryCount && (this.operationStartTime = this.requestStartTime), t = u.url, this.log(msRest.HttpPipelineLogLevel.INFO, "'" + t + "'==> OUTGOING REQUEST (Try number=" + this.tryCount + ")."), e.label = 1;
            case 1:
              return e.trys.push([1, 3,, 4]), [4, this._nextPolicy.sendRequest(u)];
            case 2:
              return a = e.sent(), r = new Date(), i = r.getTime() - this.requestStartTime.getTime(), s = r.getTime() - this.operationStartTime.getTime(), n = msRest.HttpPipelineLogLevel.INFO, o = "", this.shouldLog(msRest.HttpPipelineLogLevel.INFO) && (o = "Successfully Received Response. "), i >= this.loggingOptions.logWarningIfTryOverThreshold && this.shouldLog(msRest.HttpPipelineLogLevel.WARNING) && (n = msRest.HttpPipelineLogLevel.WARNING, o = "SLOW OPERATION. Duration > " + this.loggingOptions.logWarningIfTryOverThreshold + " ms. "), (400 <= a.status && a.status <= 499 && a.status !== HTTPURLConnection.HTTP_NOT_FOUND && a.status !== HTTPURLConnection.HTTP_CONFLICT && a.status !== HTTPURLConnection.HTTP_PRECON_FAILED && a.status !== HTTPURLConnection.HTTP_RANGE_NOT_SATISFIABLE || 500 <= a.status && a.status <= 509) && (m = "REQUEST ERROR: HTTP request failed with status code: " + a.status + ". ", o = m, n = msRest.HttpPipelineLogLevel.ERROR), l = "Request try:" + this.tryCount + ", status:" + a.status + " request duration:" + i + " ms, operation duration:" + s + " ms\n", this.log(n, o + l), [2, a];
            case 3:
              throw p = e.sent(), this.log(msRest.HttpPipelineLogLevel.ERROR, "Unexpected failure attempting to make request. Error message: " + p.message), p;
            case 4:
              return [2];
          }
        });
      });
    }, e;
  }(msRest.BaseRequestPolicy),
  LoggingPolicyFactory = function () {
    function e(e) {
      this.loggingOptions = e;
    }
    return e.prototype.create = function (e, t) {
      return new LoggingPolicy(e, t, this.loggingOptions);
    }, e;
  }(),
  CloudError = msRestAzure.CloudErrorMapper,
  BatchResponseSummary = {
    serializedName: "BatchResponse_summary",
    type: {
      name: "Composite",
      className: "BatchResponseSummary",
      modelProperties: {
        successfulRequests: {
          readOnly: !0,
          serializedName: "successfulRequests",
          type: {
            name: "Number"
          }
        },
        totalRequests: {
          readOnly: !0,
          serializedName: "totalRequests",
          type: {
            name: "Number"
          }
        }
      }
    }
  },
  BatchResponse = {
    serializedName: "BatchResponse",
    type: {
      name: "Composite",
      className: "BatchResponse",
      modelProperties: {
        summary: {
          readOnly: !0,
          serializedName: "summary",
          type: {
            name: "Composite",
            className: "BatchResponseSummary"
          }
        },
        batchItems: {
          readOnly: !0,
          serializedName: "batchItems",
          type: {
            name: "Sequence",
            element: {
              type: {
                name: "Object"
              }
            }
          }
        }
      }
    }
  },
  BatchRequestBodyBatchItemsItem = {
    serializedName: "BatchRequestBody_batchItemsItem",
    type: {
      name: "Composite",
      className: "BatchRequestBodyBatchItemsItem",
      modelProperties: {
        query: {
          serializedName: "query",
          type: {
            name: "String"
          }
        }
      }
    }
  },
  BatchRequestBody = {
    serializedName: "BatchRequestBody",
    type: {
      name: "Composite",
      className: "BatchRequestBody",
      modelProperties: {
        batchItems: {
          serializedName: "batchItems",
          type: {
            name: "Sequence",
            element: {
              type: {
                name: "Composite",
                className: "BatchRequestBodyBatchItemsItem"
              }
            }
          }
        }
      }
    }
  },
  CoordinateAbbreviated = {
    serializedName: "CoordinateAbbreviated",
    type: {
      name: "Composite",
      className: "CoordinateAbbreviated",
      modelProperties: {
        lat: {
          readOnly: !0,
          serializedName: "lat",
          type: {
            name: "Number"
          }
        },
        lon: {
          readOnly: !0,
          serializedName: "lon",
          type: {
            name: "Number"
          }
        }
      }
    }
  },
  GeoJSONGeometry = {
    serializedName: "GeoJSONGeometry",
    type: {
      name: "Composite",
      polymorphicDiscriminator: {
        serializedName: "type",
        clientName: "type"
      },
      uberParent: "GeoJSONGeometry",
      className: "GeoJSONGeometry",
      modelProperties: {
        type: {
          required: !0,
          serializedName: "type",
          type: {
            name: "String"
          }
        }
      }
    }
  },
  LineString = {
    serializedName: "LineString",
    type: {
      name: "Composite",
      polymorphicDiscriminator: GeoJSONGeometry.type.polymorphicDiscriminator,
      uberParent: "GeoJSONGeometry",
      className: "LineString",
      modelProperties: __assign(__assign({}, GeoJSONGeometry.type.modelProperties), {
        coordinates: {
          required: !0,
          serializedName: "coordinates",
          type: {
            name: "Sequence",
            element: {
              type: {
                name: "Sequence",
                element: {
                  type: {
                    name: "Number"
                  }
                }
              }
            }
          }
        }
      })
    }
  },
  SearchPolygonResult = {
    serializedName: "SearchPolygonResult",
    type: {
      name: "Composite",
      className: "SearchPolygonResult",
      modelProperties: {
        providerID: {
          readOnly: !0,
          serializedName: "providerID",
          type: {
            name: "String"
          }
        },
        error: {
          readOnly: !0,
          serializedName: "error",
          type: {
            name: "String"
          }
        },
        geometryData: {
          serializedName: "geometryData",
          type: {
            name: "Object"
          }
        }
      }
    }
  },
  SearchPolygonResponse = {
    serializedName: "SearchPolygonResponse",
    type: {
      name: "Composite",
      className: "SearchPolygonResponse",
      modelProperties: {
        additionalData: {
          readOnly: !0,
          serializedName: "additionalData",
          type: {
            name: "Sequence",
            element: {
              type: {
                name: "Composite",
                className: "SearchPolygonResult"
              }
            }
          }
        }
      }
    }
  },
  SearchFuzzySummary = {
    serializedName: "SearchFuzzySummary",
    type: {
      name: "Composite",
      className: "SearchFuzzySummary",
      modelProperties: {
        query: {
          readOnly: !0,
          serializedName: "query",
          type: {
            name: "String"
          }
        },
        queryType: {
          readOnly: !0,
          serializedName: "queryType",
          type: {
            name: "String"
          }
        },
        queryTime: {
          readOnly: !0,
          serializedName: "queryTime",
          type: {
            name: "Number"
          }
        },
        numResults: {
          readOnly: !0,
          serializedName: "numResults",
          type: {
            name: "Number"
          }
        },
        offset: {
          readOnly: !0,
          serializedName: "offset",
          type: {
            name: "Number"
          }
        },
        totalResults: {
          readOnly: !0,
          serializedName: "totalResults",
          type: {
            name: "Number"
          }
        },
        fuzzyLevel: {
          readOnly: !0,
          serializedName: "fuzzyLevel",
          type: {
            name: "Number"
          }
        }
      }
    }
  },
  SearchResultPoiClassificationName = {
    serializedName: "SearchResultPoiClassificationName",
    type: {
      name: "Composite",
      className: "SearchResultPoiClassificationName",
      modelProperties: {
        nameLocale: {
          readOnly: !0,
          serializedName: "nameLocale",
          type: {
            name: "String"
          }
        },
        name: {
          readOnly: !0,
          serializedName: "name",
          type: {
            name: "String"
          }
        }
      }
    }
  },
  SearchResultPoiClassification = {
    serializedName: "SearchResultPoiClassification",
    type: {
      name: "Composite",
      className: "SearchResultPoiClassification",
      modelProperties: {
        code: {
          readOnly: !0,
          serializedName: "code",
          type: {
            name: "String"
          }
        },
        names: {
          readOnly: !0,
          serializedName: "names",
          type: {
            name: "Sequence",
            element: {
              type: {
                name: "Composite",
                className: "SearchResultPoiClassificationName"
              }
            }
          }
        }
      }
    }
  },
  SearchResultPoiBrand = {
    serializedName: "SearchResultPoiBrand",
    type: {
      name: "Composite",
      className: "SearchResultPoiBrand",
      modelProperties: {
        name: {
          readOnly: !0,
          serializedName: "name",
          type: {
            name: "String"
          }
        }
      }
    }
  },
  SearchResultPoi = {
    serializedName: "SearchResultPoi",
    type: {
      name: "Composite",
      className: "SearchResultPoi",
      modelProperties: {
        name: {
          readOnly: !0,
          serializedName: "name",
          type: {
            name: "String"
          }
        },
        phone: {
          readOnly: !0,
          serializedName: "phone",
          type: {
            name: "String"
          }
        },
        url: {
          readOnly: !0,
          serializedName: "url",
          type: {
            name: "String"
          }
        },
        categories: {
          readOnly: !0,
          serializedName: "categories",
          type: {
            name: "Sequence",
            element: {
              type: {
                name: "String"
              }
            }
          }
        },
        classifications: {
          readOnly: !0,
          serializedName: "classifications",
          type: {
            name: "Sequence",
            element: {
              type: {
                name: "Composite",
                className: "SearchResultPoiClassification"
              }
            }
          }
        },
        brands: {
          readOnly: !0,
          serializedName: "brands",
          type: {
            name: "Sequence",
            element: {
              type: {
                name: "Composite",
                className: "SearchResultPoiBrand"
              }
            }
          }
        }
      }
    }
  },
  SearchResultAddress = {
    serializedName: "SearchResultAddress",
    type: {
      name: "Composite",
      className: "SearchResultAddress",
      modelProperties: {
        buildingNumber: {
          readOnly: !0,
          serializedName: "buildingNumber",
          type: {
            name: "String"
          }
        },
        street: {
          readOnly: !0,
          serializedName: "street",
          type: {
            name: "String"
          }
        },
        crossStreet: {
          readOnly: !0,
          serializedName: "crossStreet",
          type: {
            name: "String"
          }
        },
        streetNumber: {
          readOnly: !0,
          serializedName: "streetNumber",
          type: {
            name: "String"
          }
        },
        routeNumbers: {
          readOnly: !0,
          serializedName: "routeNumbers",
          type: {
            name: "Sequence",
            element: {
              type: {
                name: "Number"
              }
            }
          }
        },
        streetName: {
          readOnly: !0,
          serializedName: "streetName",
          type: {
            name: "String"
          }
        },
        streetNameAndNumber: {
          readOnly: !0,
          serializedName: "streetNameAndNumber",
          type: {
            name: "String"
          }
        },
        municipality: {
          readOnly: !0,
          serializedName: "municipality",
          type: {
            name: "String"
          }
        },
        municipalitySubdivision: {
          readOnly: !0,
          serializedName: "municipalitySubdivision",
          type: {
            name: "String"
          }
        },
        countryTertiarySubdivision: {
          readOnly: !0,
          serializedName: "countryTertiarySubdivision",
          type: {
            name: "String"
          }
        },
        countrySecondarySubdivision: {
          readOnly: !0,
          serializedName: "countrySecondarySubdivision",
          type: {
            name: "String"
          }
        },
        countrySubdivision: {
          readOnly: !0,
          serializedName: "countrySubdivision",
          type: {
            name: "String"
          }
        },
        postalCode: {
          readOnly: !0,
          serializedName: "postalCode",
          type: {
            name: "String"
          }
        },
        extendedPostalCode: {
          readOnly: !0,
          serializedName: "extendedPostalCode",
          type: {
            name: "String"
          }
        },
        countryCode: {
          readOnly: !0,
          serializedName: "countryCode",
          type: {
            name: "String"
          }
        },
        country: {
          readOnly: !0,
          serializedName: "country",
          type: {
            name: "String"
          }
        },
        countryCodeISO3: {
          readOnly: !0,
          serializedName: "countryCodeISO3",
          type: {
            name: "String"
          }
        },
        freeformAddress: {
          readOnly: !0,
          serializedName: "freeformAddress",
          type: {
            name: "String"
          }
        },
        countrySubdivisionName: {
          readOnly: !0,
          serializedName: "countrySubdivisionName",
          type: {
            name: "String"
          }
        },
        localName: {
          readOnly: !0,
          serializedName: "localName",
          type: {
            name: "String"
          }
        }
      }
    }
  },
  SearchResultViewport = {
    serializedName: "SearchResultViewport",
    type: {
      name: "Composite",
      className: "SearchResultViewport",
      modelProperties: {
        topLeftPoint: {
          serializedName: "topLeftPoint",
          type: {
            name: "Composite",
            className: "CoordinateAbbreviated"
          }
        },
        btmRightPoint: {
          serializedName: "btmRightPoint",
          type: {
            name: "Composite",
            className: "CoordinateAbbreviated"
          }
        }
      }
    }
  },
  SearchResultEntryPoint = {
    serializedName: "SearchResultEntryPoint",
    type: {
      name: "Composite",
      className: "SearchResultEntryPoint",
      modelProperties: {
        type: {
          readOnly: !0,
          serializedName: "type",
          type: {
            name: "String"
          }
        },
        position: {
          serializedName: "position",
          type: {
            name: "Composite",
            className: "CoordinateAbbreviated"
          }
        }
      }
    }
  },
  SearchResultAddressRanges = {
    serializedName: "SearchResultAddressRanges",
    type: {
      name: "Composite",
      className: "SearchResultAddressRanges",
      modelProperties: {
        rangeLeft: {
          serializedName: "rangeLeft",
          type: {
            name: "String"
          }
        },
        rangeRight: {
          serializedName: "rangeRight",
          type: {
            name: "String"
          }
        },
        from: {
          serializedName: "from",
          type: {
            name: "Composite",
            className: "CoordinateAbbreviated"
          }
        },
        to: {
          serializedName: "to",
          type: {
            name: "Composite",
            className: "CoordinateAbbreviated"
          }
        }
      }
    }
  },
  DataSourcesGeometry = {
    serializedName: "DataSourcesGeometry",
    type: {
      name: "Composite",
      className: "DataSourcesGeometry",
      modelProperties: {
        id: {
          readOnly: !0,
          serializedName: "id",
          type: {
            name: "String"
          }
        }
      }
    }
  },
  DataSources = {
    serializedName: "DataSources",
    type: {
      name: "Composite",
      className: "DataSources",
      modelProperties: {
        geometry: {
          serializedName: "geometry",
          type: {
            name: "Composite",
            className: "DataSourcesGeometry"
          }
        }
      }
    }
  },
  SearchFuzzyResult = {
    serializedName: "SearchFuzzyResult",
    type: {
      name: "Composite",
      className: "SearchFuzzyResult",
      modelProperties: {
        type: {
          readOnly: !0,
          serializedName: "type",
          type: {
            name: "String"
          }
        },
        id: {
          readOnly: !0,
          serializedName: "id",
          type: {
            name: "String"
          }
        },
        score: {
          serializedName: "score",
          type: {
            name: "Number"
          }
        },
        info: {
          readOnly: !0,
          serializedName: "info",
          type: {
            name: "String"
          }
        },
        entityType: {
          serializedName: "entityType",
          type: {
            name: "Enum",
            allowedValues: ["Country", "CountrySubdivision", "CountrySecondarySubdivision", "CountryTertiarySubdivision", "Municipality", "MunicipalitySubdivision", "Neighbourhood", "PostalCodeArea"]
          }
        },
        poi: {
          serializedName: "poi",
          type: {
            name: "Composite",
            className: "SearchResultPoi"
          }
        },
        address: {
          serializedName: "address",
          type: {
            name: "Composite",
            className: "SearchResultAddress"
          }
        },
        position: {
          serializedName: "position",
          type: {
            name: "Composite",
            className: "CoordinateAbbreviated"
          }
        },
        viewport: {
          serializedName: "viewport",
          type: {
            name: "Composite",
            className: "SearchResultViewport"
          }
        },
        entryPoints: {
          serializedName: "entryPoints",
          type: {
            name: "Sequence",
            element: {
              type: {
                name: "Composite",
                className: "SearchResultEntryPoint"
              }
            }
          }
        },
        addressRanges: {
          serializedName: "addressRanges",
          type: {
            name: "Composite",
            className: "SearchResultAddressRanges"
          }
        },
        dataSources: {
          serializedName: "dataSources",
          type: {
            name: "Composite",
            className: "DataSources"
          }
        }
      }
    }
  },
  SearchFuzzyResponse = {
    serializedName: "SearchFuzzyResponse",
    type: {
      name: "Composite",
      className: "SearchFuzzyResponse",
      modelProperties: {
        summary: {
          serializedName: "summary",
          type: {
            name: "Composite",
            className: "SearchFuzzySummary"
          }
        },
        results: {
          readOnly: !0,
          serializedName: "results",
          type: {
            name: "Sequence",
            element: {
              type: {
                name: "Composite",
                className: "SearchFuzzyResult"
              }
            }
          }
        }
      }
    }
  },
  SearchSummaryGeoBias = {
    serializedName: "SearchSummaryGeoBias",
    type: {
      name: "Composite",
      className: "SearchSummaryGeoBias",
      modelProperties: {
        lat: {
          readOnly: !0,
          serializedName: "lat",
          type: {
            name: "Number"
          }
        },
        lon: {
          readOnly: !0,
          serializedName: "lon",
          type: {
            name: "Number"
          }
        }
      }
    }
  },
  SearchPoiSummary = {
    serializedName: "SearchPoiSummary",
    type: {
      name: "Composite",
      className: "SearchPoiSummary",
      modelProperties: {
        query: {
          readOnly: !0,
          serializedName: "query",
          type: {
            name: "String"
          }
        },
        queryType: {
          readOnly: !0,
          serializedName: "queryType",
          type: {
            name: "String"
          }
        },
        queryTime: {
          readOnly: !0,
          serializedName: "queryTime",
          type: {
            name: "Number"
          }
        },
        numResults: {
          readOnly: !0,
          serializedName: "numResults",
          type: {
            name: "Number"
          }
        },
        offset: {
          readOnly: !0,
          serializedName: "offset",
          type: {
            name: "Number"
          }
        },
        totalResults: {
          readOnly: !0,
          serializedName: "totalResults",
          type: {
            name: "Number"
          }
        },
        fuzzyLevel: {
          readOnly: !0,
          serializedName: "fuzzyLevel",
          type: {
            name: "Number"
          }
        },
        geoBias: {
          serializedName: "geoBias",
          type: {
            name: "Composite",
            className: "SearchSummaryGeoBias"
          }
        }
      }
    }
  },
  SearchPoiResult = {
    serializedName: "SearchPoiResult",
    type: {
      name: "Composite",
      className: "SearchPoiResult",
      modelProperties: {
        type: {
          readOnly: !0,
          serializedName: "type",
          type: {
            name: "String"
          }
        },
        id: {
          readOnly: !0,
          serializedName: "id",
          type: {
            name: "String"
          }
        },
        score: {
          serializedName: "score",
          type: {
            name: "Number"
          }
        },
        dist: {
          serializedName: "dist",
          type: {
            name: "Number"
          }
        },
        info: {
          readOnly: !0,
          serializedName: "info",
          type: {
            name: "String"
          }
        },
        entityType: {
          serializedName: "entityType",
          type: {
            name: "Enum",
            allowedValues: ["Country", "CountrySubdivision", "CountrySecondarySubdivision", "CountryTertiarySubdivision", "Municipality", "MunicipalitySubdivision", "Neighbourhood", "PostalCodeArea"]
          }
        },
        poi: {
          serializedName: "poi",
          type: {
            name: "Composite",
            className: "SearchResultPoi"
          }
        },
        address: {
          serializedName: "address",
          type: {
            name: "Composite",
            className: "SearchResultAddress"
          }
        },
        position: {
          serializedName: "position",
          type: {
            name: "Composite",
            className: "CoordinateAbbreviated"
          }
        },
        viewport: {
          serializedName: "viewport",
          type: {
            name: "Composite",
            className: "SearchResultViewport"
          }
        },
        entryPoints: {
          serializedName: "entryPoints",
          type: {
            name: "Sequence",
            element: {
              type: {
                name: "Composite",
                className: "SearchResultEntryPoint"
              }
            }
          }
        }
      }
    }
  },
  SearchPoiResponse = {
    serializedName: "SearchPoiResponse",
    type: {
      name: "Composite",
      className: "SearchPoiResponse",
      modelProperties: {
        summary: {
          serializedName: "summary",
          type: {
            name: "Composite",
            className: "SearchPoiSummary"
          }
        },
        results: {
          readOnly: !0,
          serializedName: "results",
          type: {
            name: "Sequence",
            element: {
              type: {
                name: "Composite",
                className: "SearchPoiResult"
              }
            }
          }
        }
      }
    }
  },
  SearchNearbySummary = {
    serializedName: "SearchNearbySummary",
    type: {
      name: "Composite",
      className: "SearchNearbySummary",
      modelProperties: {
        queryType: {
          readOnly: !0,
          serializedName: "queryType",
          type: {
            name: "String"
          }
        },
        queryTime: {
          readOnly: !0,
          serializedName: "queryTime",
          type: {
            name: "Number"
          }
        },
        numResults: {
          readOnly: !0,
          serializedName: "numResults",
          type: {
            name: "Number"
          }
        },
        offset: {
          readOnly: !0,
          serializedName: "offset",
          type: {
            name: "Number"
          }
        },
        totalResults: {
          readOnly: !0,
          serializedName: "totalResults",
          type: {
            name: "Number"
          }
        },
        fuzzyLevel: {
          readOnly: !0,
          serializedName: "fuzzyLevel",
          type: {
            name: "Number"
          }
        },
        geoBias: {
          serializedName: "geoBias",
          type: {
            name: "Composite",
            className: "SearchSummaryGeoBias"
          }
        }
      }
    }
  },
  SearchNearbyResult = {
    serializedName: "SearchNearbyResult",
    type: {
      name: "Composite",
      className: "SearchNearbyResult",
      modelProperties: {
        type: {
          readOnly: !0,
          serializedName: "type",
          type: {
            name: "String"
          }
        },
        id: {
          readOnly: !0,
          serializedName: "id",
          type: {
            name: "String"
          }
        },
        score: {
          serializedName: "score",
          type: {
            name: "Number"
          }
        },
        dist: {
          serializedName: "dist",
          type: {
            name: "Number"
          }
        },
        info: {
          readOnly: !0,
          serializedName: "info",
          type: {
            name: "String"
          }
        },
        poi: {
          serializedName: "poi",
          type: {
            name: "Composite",
            className: "SearchResultPoi"
          }
        },
        address: {
          serializedName: "address",
          type: {
            name: "Composite",
            className: "SearchResultAddress"
          }
        },
        position: {
          serializedName: "position",
          type: {
            name: "Composite",
            className: "CoordinateAbbreviated"
          }
        },
        viewport: {
          serializedName: "viewport",
          type: {
            name: "Composite",
            className: "SearchResultViewport"
          }
        },
        entryPoints: {
          serializedName: "entryPoints",
          type: {
            name: "Sequence",
            element: {
              type: {
                name: "Composite",
                className: "SearchResultEntryPoint"
              }
            }
          }
        }
      }
    }
  },
  SearchNearbyResponse = {
    serializedName: "SearchNearbyResponse",
    type: {
      name: "Composite",
      className: "SearchNearbyResponse",
      modelProperties: {
        summary: {
          serializedName: "summary",
          type: {
            name: "Composite",
            className: "SearchNearbySummary"
          }
        },
        results: {
          readOnly: !0,
          serializedName: "results",
          type: {
            name: "Sequence",
            element: {
              type: {
                name: "Composite",
                className: "SearchNearbyResult"
              }
            }
          }
        }
      }
    }
  },
  SearchPoiCategorySummary = {
    serializedName: "SearchPoiCategorySummary",
    type: {
      name: "Composite",
      className: "SearchPoiCategorySummary",
      modelProperties: {
        query: {
          readOnly: !0,
          serializedName: "query",
          type: {
            name: "String"
          }
        },
        queryType: {
          readOnly: !0,
          serializedName: "queryType",
          type: {
            name: "String"
          }
        },
        queryTime: {
          readOnly: !0,
          serializedName: "queryTime",
          type: {
            name: "Number"
          }
        },
        numResults: {
          readOnly: !0,
          serializedName: "numResults",
          type: {
            name: "Number"
          }
        },
        offset: {
          readOnly: !0,
          serializedName: "offset",
          type: {
            name: "Number"
          }
        },
        totalResults: {
          readOnly: !0,
          serializedName: "totalResults",
          type: {
            name: "Number"
          }
        },
        fuzzyLevel: {
          readOnly: !0,
          serializedName: "fuzzyLevel",
          type: {
            name: "Number"
          }
        },
        geoBias: {
          serializedName: "geoBias",
          type: {
            name: "Composite",
            className: "SearchSummaryGeoBias"
          }
        }
      }
    }
  },
  SearchPoiCategoryResult = {
    serializedName: "SearchPoiCategoryResult",
    type: {
      name: "Composite",
      className: "SearchPoiCategoryResult",
      modelProperties: {
        type: {
          readOnly: !0,
          serializedName: "type",
          type: {
            name: "String"
          }
        },
        id: {
          readOnly: !0,
          serializedName: "id",
          type: {
            name: "String"
          }
        },
        score: {
          serializedName: "score",
          type: {
            name: "Number"
          }
        },
        dist: {
          serializedName: "dist",
          type: {
            name: "Number"
          }
        },
        info: {
          readOnly: !0,
          serializedName: "info",
          type: {
            name: "String"
          }
        },
        entityType: {
          serializedName: "entityType",
          type: {
            name: "Enum",
            allowedValues: ["Country", "CountrySubdivision", "CountrySecondarySubdivision", "CountryTertiarySubdivision", "Municipality", "MunicipalitySubdivision", "Neighbourhood", "PostalCodeArea"]
          }
        },
        poi: {
          serializedName: "poi",
          type: {
            name: "Composite",
            className: "SearchResultPoi"
          }
        },
        address: {
          serializedName: "address",
          type: {
            name: "Composite",
            className: "SearchResultAddress"
          }
        },
        position: {
          serializedName: "position",
          type: {
            name: "Composite",
            className: "CoordinateAbbreviated"
          }
        },
        viewport: {
          serializedName: "viewport",
          type: {
            name: "Composite",
            className: "SearchResultViewport"
          }
        },
        entryPoints: {
          serializedName: "entryPoints",
          type: {
            name: "Sequence",
            element: {
              type: {
                name: "Composite",
                className: "SearchResultEntryPoint"
              }
            }
          }
        }
      }
    }
  },
  SearchPoiCategoryResponse = {
    serializedName: "SearchPoiCategoryResponse",
    type: {
      name: "Composite",
      className: "SearchPoiCategoryResponse",
      modelProperties: {
        summary: {
          serializedName: "summary",
          type: {
            name: "Composite",
            className: "SearchPoiCategorySummary"
          }
        },
        results: {
          readOnly: !0,
          serializedName: "results",
          type: {
            name: "Sequence",
            element: {
              type: {
                name: "Composite",
                className: "SearchPoiCategoryResult"
              }
            }
          }
        }
      }
    }
  },
  SearchAddressSummary = {
    serializedName: "SearchAddressSummary",
    type: {
      name: "Composite",
      className: "SearchAddressSummary",
      modelProperties: {
        query: {
          readOnly: !0,
          serializedName: "query",
          type: {
            name: "String"
          }
        },
        queryType: {
          readOnly: !0,
          serializedName: "queryType",
          type: {
            name: "String"
          }
        },
        queryTime: {
          readOnly: !0,
          serializedName: "queryTime",
          type: {
            name: "Number"
          }
        },
        numResults: {
          readOnly: !0,
          serializedName: "numResults",
          type: {
            name: "Number"
          }
        },
        offset: {
          readOnly: !0,
          serializedName: "offset",
          type: {
            name: "Number"
          }
        },
        totalResults: {
          readOnly: !0,
          serializedName: "totalResults",
          type: {
            name: "Number"
          }
        },
        fuzzyLevel: {
          readOnly: !0,
          serializedName: "fuzzyLevel",
          type: {
            name: "Number"
          }
        }
      }
    }
  },
  SearchAddressResult = {
    serializedName: "SearchAddressResult",
    type: {
      name: "Composite",
      className: "SearchAddressResult",
      modelProperties: {
        type: {
          readOnly: !0,
          serializedName: "type",
          type: {
            name: "String"
          }
        },
        id: {
          readOnly: !0,
          serializedName: "id",
          type: {
            name: "String"
          }
        },
        score: {
          serializedName: "score",
          type: {
            name: "Number"
          }
        },
        address: {
          serializedName: "address",
          type: {
            name: "Composite",
            className: "SearchResultAddress"
          }
        },
        position: {
          serializedName: "position",
          type: {
            name: "Composite",
            className: "CoordinateAbbreviated"
          }
        },
        viewport: {
          serializedName: "viewport",
          type: {
            name: "Composite",
            className: "SearchResultViewport"
          }
        },
        entryPoints: {
          serializedName: "entryPoints",
          type: {
            name: "Sequence",
            element: {
              type: {
                name: "Composite",
                className: "SearchResultEntryPoint"
              }
            }
          }
        },
        dataSources: {
          serializedName: "dataSources",
          type: {
            name: "Composite",
            className: "DataSources"
          }
        }
      }
    }
  },
  SearchAddressResponse = {
    serializedName: "SearchAddressResponse",
    type: {
      name: "Composite",
      className: "SearchAddressResponse",
      modelProperties: {
        summary: {
          serializedName: "summary",
          type: {
            name: "Composite",
            className: "SearchAddressSummary"
          }
        },
        results: {
          readOnly: !0,
          serializedName: "results",
          type: {
            name: "Sequence",
            element: {
              type: {
                name: "Composite",
                className: "SearchAddressResult"
              }
            }
          }
        }
      }
    }
  },
  SearchAddressReverseSummary = {
    serializedName: "SearchAddressReverseSummary",
    type: {
      name: "Composite",
      className: "SearchAddressReverseSummary",
      modelProperties: {
        queryTime: {
          readOnly: !0,
          serializedName: "queryTime",
          type: {
            name: "Number"
          }
        },
        numResults: {
          readOnly: !0,
          serializedName: "numResults",
          type: {
            name: "Number"
          }
        }
      }
    }
  },
  SearchAddressReverseResult = {
    serializedName: "SearchAddressReverseResult",
    type: {
      name: "Composite",
      className: "SearchAddressReverseResult",
      modelProperties: {
        address: {
          serializedName: "address",
          type: {
            name: "Composite",
            className: "SearchResultAddress"
          }
        },
        position: {
          readOnly: !0,
          serializedName: "position",
          type: {
            name: "String"
          }
        },
        matchType: {
          readOnly: !0,
          serializedName: "matchType",
          type: {
            name: "String"
          }
        }
      }
    }
  },
  SearchAddressReverseResponse = {
    serializedName: "SearchAddressReverseResponse",
    type: {
      name: "Composite",
      className: "SearchAddressReverseResponse",
      modelProperties: {
        summary: {
          serializedName: "summary",
          type: {
            name: "Composite",
            className: "SearchAddressReverseSummary"
          }
        },
        addresses: {
          readOnly: !0,
          serializedName: "addresses",
          type: {
            name: "Sequence",
            element: {
              type: {
                name: "Composite",
                className: "SearchAddressReverseResult"
              }
            }
          }
        }
      }
    }
  },
  SearchAddressReverseCrossStreetSummary = {
    serializedName: "SearchAddressReverseCrossStreetSummary",
    type: {
      name: "Composite",
      className: "SearchAddressReverseCrossStreetSummary",
      modelProperties: {
        queryTime: {
          readOnly: !0,
          serializedName: "queryTime",
          type: {
            name: "Number"
          }
        },
        numResults: {
          readOnly: !0,
          serializedName: "numResults",
          type: {
            name: "Number"
          }
        }
      }
    }
  },
  SearchAddressReverseCrossStreetResult = {
    serializedName: "SearchAddressReverseCrossStreetResult",
    type: {
      name: "Composite",
      className: "SearchAddressReverseCrossStreetResult",
      modelProperties: {
        address: {
          serializedName: "address",
          type: {
            name: "Composite",
            className: "SearchResultAddress"
          }
        },
        position: {
          readOnly: !0,
          serializedName: "position",
          type: {
            name: "String"
          }
        }
      }
    }
  },
  SearchAddressReverseCrossStreetResponse = {
    serializedName: "SearchAddressReverseCrossStreetResponse",
    type: {
      name: "Composite",
      className: "SearchAddressReverseCrossStreetResponse",
      modelProperties: {
        summary: {
          serializedName: "summary",
          type: {
            name: "Composite",
            className: "SearchAddressReverseCrossStreetSummary"
          }
        },
        addresses: {
          readOnly: !0,
          serializedName: "addresses",
          type: {
            name: "Sequence",
            element: {
              type: {
                name: "Composite",
                className: "SearchAddressReverseCrossStreetResult"
              }
            }
          }
        }
      }
    }
  },
  SearchAddressStructuredSummary = {
    serializedName: "SearchAddressStructuredSummary",
    type: {
      name: "Composite",
      className: "SearchAddressStructuredSummary",
      modelProperties: {
        query: {
          readOnly: !0,
          serializedName: "query",
          type: {
            name: "String"
          }
        },
        queryType: {
          readOnly: !0,
          serializedName: "queryType",
          type: {
            name: "String"
          }
        },
        queryTime: {
          readOnly: !0,
          serializedName: "queryTime",
          type: {
            name: "Number"
          }
        },
        numResults: {
          readOnly: !0,
          serializedName: "numResults",
          type: {
            name: "Number"
          }
        },
        limit: {
          readOnly: !0,
          serializedName: "limit",
          type: {
            name: "Number"
          }
        },
        offset: {
          readOnly: !0,
          serializedName: "offset",
          type: {
            name: "Number"
          }
        },
        totalResults: {
          readOnly: !0,
          serializedName: "totalResults",
          type: {
            name: "Number"
          }
        },
        fuzzyLevel: {
          readOnly: !0,
          serializedName: "fuzzyLevel",
          type: {
            name: "Number"
          }
        },
        geoBias: {
          serializedName: "geoBias",
          type: {
            name: "Composite",
            className: "SearchSummaryGeoBias"
          }
        }
      }
    }
  },
  SearchAddressStructuredResult = {
    serializedName: "SearchAddressStructuredResult",
    type: {
      name: "Composite",
      className: "SearchAddressStructuredResult",
      modelProperties: {
        type: {
          readOnly: !0,
          serializedName: "type",
          type: {
            name: "String"
          }
        },
        id: {
          readOnly: !0,
          serializedName: "id",
          type: {
            name: "String"
          }
        },
        score: {
          serializedName: "score",
          type: {
            name: "Number"
          }
        },
        dist: {
          serializedName: "dist",
          type: {
            name: "Number"
          }
        },
        address: {
          serializedName: "address",
          type: {
            name: "Composite",
            className: "SearchResultAddress"
          }
        },
        position: {
          serializedName: "position",
          type: {
            name: "Composite",
            className: "CoordinateAbbreviated"
          }
        },
        viewport: {
          serializedName: "viewport",
          type: {
            name: "Composite",
            className: "SearchResultViewport"
          }
        },
        entryPoints: {
          serializedName: "entryPoints",
          type: {
            name: "Sequence",
            element: {
              type: {
                name: "Composite",
                className: "SearchResultEntryPoint"
              }
            }
          }
        },
        addressRanges: {
          serializedName: "addressRanges",
          type: {
            name: "Composite",
            className: "SearchResultAddressRanges"
          }
        }
      }
    }
  },
  SearchAddressStructuredResponse = {
    serializedName: "SearchAddressStructuredResponse",
    type: {
      name: "Composite",
      className: "SearchAddressStructuredResponse",
      modelProperties: {
        summary: {
          serializedName: "summary",
          type: {
            name: "Composite",
            className: "SearchAddressStructuredSummary"
          }
        },
        results: {
          readOnly: !0,
          serializedName: "results",
          type: {
            name: "Sequence",
            element: {
              type: {
                name: "Composite",
                className: "SearchAddressStructuredResult"
              }
            }
          }
        }
      }
    }
  },
  SearchGeometrySummary = {
    serializedName: "SearchGeometrySummary",
    type: {
      name: "Composite",
      className: "SearchGeometrySummary",
      modelProperties: {
        query: {
          readOnly: !0,
          serializedName: "query",
          type: {
            name: "String"
          }
        },
        queryType: {
          readOnly: !0,
          serializedName: "queryType",
          type: {
            name: "String"
          }
        },
        queryTime: {
          readOnly: !0,
          serializedName: "queryTime",
          type: {
            name: "Number"
          }
        },
        numResults: {
          readOnly: !0,
          serializedName: "numResults",
          type: {
            name: "Number"
          }
        },
        offset: {
          readOnly: !0,
          serializedName: "offset",
          type: {
            name: "Number"
          }
        },
        totalResults: {
          readOnly: !0,
          serializedName: "totalResults",
          type: {
            name: "Number"
          }
        },
        fuzzyLevel: {
          readOnly: !0,
          serializedName: "fuzzyLevel",
          type: {
            name: "Number"
          }
        }
      }
    }
  },
  SearchGeometryResult = {
    serializedName: "SearchGeometryResult",
    type: {
      name: "Composite",
      className: "SearchGeometryResult",
      modelProperties: {
        type: {
          readOnly: !0,
          serializedName: "type",
          type: {
            name: "String"
          }
        },
        id: {
          readOnly: !0,
          serializedName: "id",
          type: {
            name: "String"
          }
        },
        score: {
          serializedName: "score",
          type: {
            name: "Number"
          }
        },
        info: {
          readOnly: !0,
          serializedName: "info",
          type: {
            name: "String"
          }
        },
        entityType: {
          serializedName: "entityType",
          type: {
            name: "Enum",
            allowedValues: ["Country", "CountrySubdivision", "CountrySecondarySubdivision", "CountryTertiarySubdivision", "Municipality", "MunicipalitySubdivision", "Neighbourhood", "PostalCodeArea"]
          }
        },
        poi: {
          serializedName: "poi",
          type: {
            name: "Composite",
            className: "SearchResultPoi"
          }
        },
        address: {
          serializedName: "address",
          type: {
            name: "Composite",
            className: "SearchResultAddress"
          }
        },
        position: {
          serializedName: "position",
          type: {
            name: "Composite",
            className: "CoordinateAbbreviated"
          }
        },
        viewport: {
          serializedName: "viewport",
          type: {
            name: "Composite",
            className: "SearchResultViewport"
          }
        },
        entryPoints: {
          serializedName: "entryPoints",
          type: {
            name: "Sequence",
            element: {
              type: {
                name: "Composite",
                className: "SearchResultEntryPoint"
              }
            }
          }
        }
      }
    }
  },
  SearchGeometryResponse = {
    serializedName: "SearchGeometryResponse",
    type: {
      name: "Composite",
      className: "SearchGeometryResponse",
      modelProperties: {
        summary: {
          serializedName: "summary",
          type: {
            name: "Composite",
            className: "SearchGeometrySummary"
          }
        },
        results: {
          readOnly: !0,
          serializedName: "results",
          type: {
            name: "Sequence",
            element: {
              type: {
                name: "Composite",
                className: "SearchGeometryResult"
              }
            }
          }
        }
      }
    }
  },
  SearchAlongRouteSummary = {
    serializedName: "SearchAlongRouteSummary",
    type: {
      name: "Composite",
      className: "SearchAlongRouteSummary",
      modelProperties: {
        query: {
          readOnly: !0,
          serializedName: "query",
          type: {
            name: "String"
          }
        },
        queryType: {
          readOnly: !0,
          serializedName: "queryType",
          type: {
            name: "String"
          }
        },
        queryTime: {
          readOnly: !0,
          serializedName: "queryTime",
          type: {
            name: "Number"
          }
        },
        numResults: {
          readOnly: !0,
          serializedName: "numResults",
          type: {
            name: "Number"
          }
        },
        offset: {
          readOnly: !0,
          serializedName: "offset",
          type: {
            name: "Number"
          }
        },
        totalResults: {
          readOnly: !0,
          serializedName: "totalResults",
          type: {
            name: "Number"
          }
        },
        fuzzyLevel: {
          readOnly: !0,
          serializedName: "fuzzyLevel",
          type: {
            name: "Number"
          }
        }
      }
    }
  },
  SearchAlongRouteResult = {
    serializedName: "SearchAlongRouteResult",
    type: {
      name: "Composite",
      className: "SearchAlongRouteResult",
      modelProperties: {
        type: {
          readOnly: !0,
          serializedName: "type",
          type: {
            name: "String"
          }
        },
        id: {
          readOnly: !0,
          serializedName: "id",
          type: {
            name: "String"
          }
        },
        score: {
          serializedName: "score",
          type: {
            name: "Number"
          }
        },
        info: {
          readOnly: !0,
          serializedName: "info",
          type: {
            name: "String"
          }
        },
        entityType: {
          serializedName: "entityType",
          type: {
            name: "Enum",
            allowedValues: ["Country", "CountrySubdivision", "CountrySecondarySubdivision", "CountryTertiarySubdivision", "Municipality", "MunicipalitySubdivision", "Neighbourhood", "PostalCodeArea"]
          }
        },
        poi: {
          serializedName: "poi",
          type: {
            name: "Composite",
            className: "SearchResultPoi"
          }
        },
        address: {
          serializedName: "address",
          type: {
            name: "Composite",
            className: "SearchResultAddress"
          }
        },
        position: {
          serializedName: "position",
          type: {
            name: "Composite",
            className: "CoordinateAbbreviated"
          }
        },
        viewport: {
          serializedName: "viewport",
          type: {
            name: "Composite",
            className: "SearchResultViewport"
          }
        },
        entryPoints: {
          serializedName: "entryPoints",
          type: {
            name: "Sequence",
            element: {
              type: {
                name: "Composite",
                className: "SearchResultEntryPoint"
              }
            }
          }
        },
        dist: {
          serializedName: "dist",
          type: {
            name: "Number"
          }
        },
        detourTime: {
          readOnly: !0,
          serializedName: "detourTime",
          type: {
            name: "Number"
          }
        }
      }
    }
  },
  SearchAlongRouteResponse = {
    serializedName: "SearchAlongRouteResponse",
    type: {
      name: "Composite",
      className: "SearchAlongRouteResponse",
      modelProperties: {
        summary: {
          serializedName: "summary",
          type: {
            name: "Composite",
            className: "SearchAlongRouteSummary"
          }
        },
        results: {
          readOnly: !0,
          serializedName: "results",
          type: {
            name: "Sequence",
            element: {
              type: {
                name: "Composite",
                className: "SearchAlongRouteResult"
              }
            }
          }
        }
      }
    }
  },
  SearchInsideGeometryRequestBody = {
    serializedName: "SearchInsideGeometryRequestBody",
    type: {
      name: "Composite",
      className: "SearchInsideGeometryRequestBody",
      modelProperties: {
        geometry: {
          serializedName: "geometry",
          type: {
            name: "Object"
          }
        }
      }
    }
  },
  SearchAlongRouteRequestBody = {
    serializedName: "SearchAlongRouteRequestBody",
    type: {
      name: "Composite",
      className: "SearchAlongRouteRequestBody",
      modelProperties: {
        route: {
          serializedName: "route",
          type: {
            name: "Composite",
            className: "LineString"
          }
        }
      }
    }
  },
  MultiPoint = {
    serializedName: "MultiPoint",
    type: {
      name: "Composite",
      polymorphicDiscriminator: GeoJSONGeometry.type.polymorphicDiscriminator,
      uberParent: "GeoJSONGeometry",
      className: "MultiPoint",
      modelProperties: __assign(__assign({}, GeoJSONGeometry.type.modelProperties), {
        coordinates: {
          required: !0,
          serializedName: "coordinates",
          type: {
            name: "Sequence",
            element: {
              type: {
                name: "Sequence",
                element: {
                  type: {
                    name: "Number"
                  }
                }
              }
            }
          }
        }
      })
    }
  },
  Point = {
    serializedName: "Point",
    type: {
      name: "Composite",
      polymorphicDiscriminator: GeoJSONGeometry.type.polymorphicDiscriminator,
      uberParent: "GeoJSONGeometry",
      className: "Point",
      modelProperties: __assign(__assign({}, GeoJSONGeometry.type.modelProperties), {
        coordinates: {
          required: !0,
          serializedName: "coordinates",
          type: {
            name: "Sequence",
            element: {
              type: {
                name: "Number"
              }
            }
          }
        }
      })
    }
  },
  MultiPolygon = {
    serializedName: "MultiPolygon",
    type: {
      name: "Composite",
      polymorphicDiscriminator: GeoJSONGeometry.type.polymorphicDiscriminator,
      uberParent: "GeoJSONGeometry",
      className: "MultiPolygon",
      modelProperties: __assign(__assign({}, GeoJSONGeometry.type.modelProperties), {
        coordinates: {
          required: !0,
          serializedName: "coordinates",
          type: {
            name: "Sequence",
            element: {
              type: {
                name: "Sequence",
                element: {
                  type: {
                    name: "Sequence",
                    element: {
                      type: {
                        name: "Sequence",
                        element: {
                          type: {
                            name: "Number"
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      })
    }
  },
  GeoJSONGeometryCollection = {
    serializedName: "GeoJSONGeometryCollection",
    type: {
      name: "Composite",
      className: "GeoJSONGeometryCollection",
      modelProperties: {
        type: {
          serializedName: "type",
          type: {
            name: "String"
          }
        },
        geometries: {
          required: !0,
          serializedName: "geometries",
          type: {
            name: "Sequence",
            element: {
              type: {
                name: "Composite",
                className: "GeoJSONGeometry"
              }
            }
          }
        }
      }
    }
  },
  Coordinate = {
    serializedName: "Coordinate",
    type: {
      name: "Composite",
      className: "Coordinate",
      modelProperties: {
        latitude: {
          readOnly: !0,
          serializedName: "latitude",
          type: {
            name: "Number"
          }
        },
        longitude: {
          readOnly: !0,
          serializedName: "longitude",
          type: {
            name: "Number"
          }
        }
      }
    }
  },
  RouteDirectionsRequestBodySupportingPoints = {
    serializedName: "RouteDirectionsRequestBody_supportingPoints",
    type: {
      name: "Composite",
      className: "RouteDirectionsRequestBodySupportingPoints",
      modelProperties: __assign({}, GeoJSONGeometryCollection.type.modelProperties)
    }
  },
  RouteDirectionsRequestBody = {
    serializedName: "RouteDirectionsRequestBody",
    type: {
      name: "Composite",
      className: "RouteDirectionsRequestBody",
      modelProperties: {
        supportingPoints: {
          serializedName: "supportingPoints",
          type: {
            name: "Composite",
            className: "RouteDirectionsRequestBodySupportingPoints"
          }
        },
        avoidVignette: {
          serializedName: "avoidVignette",
          type: {
            name: "Sequence",
            element: {
              type: {
                name: "String"
              }
            }
          }
        },
        allowVignette: {
          serializedName: "allowVignette",
          type: {
            name: "Sequence",
            element: {
              type: {
                name: "String"
              }
            }
          }
        },
        avoidAreas: {
          serializedName: "avoidAreas",
          type: {
            name: "Composite",
            className: "MultiPolygon"
          }
        }
      }
    }
  },
  RouteDirectionsSummary = {
    serializedName: "RouteDirectionsSummary",
    type: {
      name: "Composite",
      className: "RouteDirectionsSummary",
      modelProperties: {
        lengthInMeters: {
          readOnly: !0,
          serializedName: "lengthInMeters",
          type: {
            name: "Number"
          }
        },
        travelTimeInSeconds: {
          readOnly: !0,
          serializedName: "travelTimeInSeconds",
          type: {
            name: "Number"
          }
        },
        trafficDelayInSeconds: {
          readOnly: !0,
          serializedName: "trafficDelayInSeconds",
          type: {
            name: "Number"
          }
        },
        departureTime: {
          readOnly: !0,
          serializedName: "departureTime",
          type: {
            name: "String"
          }
        },
        arrivalTime: {
          readOnly: !0,
          serializedName: "arrivalTime",
          type: {
            name: "String"
          }
        }
      }
    }
  },
  RouteResultLegSummary = {
    serializedName: "RouteResultLegSummary",
    type: {
      name: "Composite",
      className: "RouteResultLegSummary",
      modelProperties: {
        lengthInMeters: {
          readOnly: !0,
          serializedName: "lengthInMeters",
          type: {
            name: "Number"
          }
        },
        travelTimeInSeconds: {
          readOnly: !0,
          serializedName: "travelTimeInSeconds",
          type: {
            name: "Number"
          }
        },
        trafficDelayInSeconds: {
          readOnly: !0,
          serializedName: "trafficDelayInSeconds",
          type: {
            name: "Number"
          }
        },
        departureTime: {
          readOnly: !0,
          serializedName: "departureTime",
          type: {
            name: "String"
          }
        },
        arrivalTime: {
          readOnly: !0,
          serializedName: "arrivalTime",
          type: {
            name: "String"
          }
        },
        noTrafficTravelTimeInSeconds: {
          readOnly: !0,
          serializedName: "noTrafficTravelTimeInSeconds",
          type: {
            name: "Number"
          }
        },
        historicTrafficTravelTimeInSeconds: {
          readOnly: !0,
          serializedName: "historicTrafficTravelTimeInSeconds",
          type: {
            name: "Number"
          }
        },
        liveTrafficIncidentsTravelTimeInSeconds: {
          readOnly: !0,
          serializedName: "liveTrafficIncidentsTravelTimeInSeconds",
          type: {
            name: "Number"
          }
        },
        fuelConsumptionInLiters: {
          readOnly: !0,
          serializedName: "fuelConsumptionInLiters",
          type: {
            name: "Number"
          }
        },
        batteryConsumptionInkWh: {
          readOnly: !0,
          serializedName: "batteryConsumptionInkWh",
          type: {
            name: "Number"
          }
        }
      }
    }
  },
  RouteResultLeg = {
    serializedName: "RouteResultLeg",
    type: {
      name: "Composite",
      className: "RouteResultLeg",
      modelProperties: {
        summary: {
          serializedName: "summary",
          type: {
            name: "Composite",
            className: "RouteResultLegSummary"
          }
        },
        points: {
          readOnly: !0,
          serializedName: "points",
          type: {
            name: "Sequence",
            element: {
              type: {
                name: "Composite",
                className: "Coordinate"
              }
            }
          }
        }
      }
    }
  },
  RouteResultSectionTecCause = {
    serializedName: "RouteResultSectionTecCause",
    type: {
      name: "Composite",
      className: "RouteResultSectionTecCause",
      modelProperties: {
        mainCauseCode: {
          readOnly: !0,
          serializedName: "mainCauseCode",
          type: {
            name: "Number"
          }
        },
        subCauseCode: {
          readOnly: !0,
          serializedName: "subCauseCode",
          type: {
            name: "Number"
          }
        }
      }
    }
  },
  RouteResultSectionTec = {
    serializedName: "RouteResultSectionTec",
    type: {
      name: "Composite",
      className: "RouteResultSectionTec",
      modelProperties: {
        effectCode: {
          readOnly: !0,
          serializedName: "effectCode",
          type: {
            name: "Number"
          }
        },
        causes: {
          serializedName: "causes",
          type: {
            name: "Sequence",
            element: {
              type: {
                name: "Composite",
                className: "RouteResultSectionTecCause"
              }
            }
          }
        }
      }
    }
  },
  RouteResultSection = {
    serializedName: "RouteResultSection",
    type: {
      name: "Composite",
      className: "RouteResultSection",
      modelProperties: {
        startPointIndex: {
          readOnly: !0,
          serializedName: "startPointIndex",
          type: {
            name: "Number"
          }
        },
        endPointIndex: {
          readOnly: !0,
          serializedName: "endPointIndex",
          type: {
            name: "Number"
          }
        },
        sectionType: {
          readOnly: !0,
          serializedName: "sectionType",
          type: {
            name: "String"
          }
        },
        travelMode: {
          readOnly: !0,
          serializedName: "travelMode",
          type: {
            name: "String"
          }
        },
        simpleCategory: {
          readOnly: !0,
          serializedName: "simpleCategory",
          type: {
            name: "String"
          }
        },
        effectiveSpeedInKmh: {
          readOnly: !0,
          serializedName: "effectiveSpeedInKmh",
          type: {
            name: "Number"
          }
        },
        delayInSeconds: {
          readOnly: !0,
          serializedName: "delayInSeconds",
          type: {
            name: "Number"
          }
        },
        magnitudeOfDelay: {
          readOnly: !0,
          serializedName: "magnitudeOfDelay",
          type: {
            name: "Enum",
            allowedValues: ["0", "1", "2", "3", "4"]
          }
        },
        tec: {
          serializedName: "tec",
          type: {
            name: "Composite",
            className: "RouteResultSectionTec"
          }
        }
      }
    }
  },
  RouteResultInstruction = {
    serializedName: "RouteResultInstruction",
    type: {
      name: "Composite",
      className: "RouteResultInstruction",
      modelProperties: {
        routeOffsetInMeters: {
          readOnly: !0,
          serializedName: "routeOffsetInMeters",
          type: {
            name: "Number"
          }
        },
        travelTimeInSeconds: {
          readOnly: !0,
          serializedName: "travelTimeInSeconds",
          type: {
            name: "Number"
          }
        },
        point: {
          serializedName: "point",
          type: {
            name: "Composite",
            className: "Coordinate"
          }
        },
        instructionType: {
          serializedName: "instructionType",
          type: {
            name: "Enum",
            allowedValues: ["TURN", "ROAD_CHANGE", "LOCATION_DEPARTURE", "LOCATION_ARRIVAL", "DIRECTION_INFO", "LOCATION_WAYPOINT"]
          }
        },
        street: {
          readOnly: !0,
          serializedName: "street",
          type: {
            name: "String"
          }
        },
        countryCode: {
          readOnly: !0,
          serializedName: "countryCode",
          type: {
            name: "String"
          }
        },
        possibleCombineWithNext: {
          readOnly: !0,
          serializedName: "possibleCombineWithNext",
          type: {
            name: "Boolean"
          }
        },
        drivingSide: {
          readOnly: !0,
          serializedName: "drivingSide",
          type: {
            name: "Enum",
            allowedValues: ["LEFT", "RIGHT"]
          }
        },
        maneuver: {
          readOnly: !0,
          serializedName: "maneuver",
          type: {
            name: "Enum",
            allowedValues: ["ARRIVE", "ARRIVE_LEFT", "ARRIVE_RIGHT", "DEPART", "STRAIGHT", "KEEP_RIGHT", "BEAR_RIGHT", "TURN_RIGHT", "SHARP_RIGHT", "KEEP_LEFT", "BEAR_LEFT", "TURN_LEFT", "SHARP_LEFT", "MAKE_UTURN", "ENTER_MOTORWAY", "ENTER_FREEWAY", "ENTER_HIGHWAY", "TAKE_EXIT", "MOTORWAY_EXIT_LEFT", "MOTORWAY_EXIT_RIGHT", "TAKE_FERRY", "ROUNDABOUT_CROSS", "ROUNDABOUT_RIGHT", "ROUNDABOUT_LEFT", "ROUNDABOUT_BACK", "TRY_MAKE_UTURN", "FOLLOW", "SWITCH_PARALLEL_ROAD", "SWITCH_MAIN_ROAD", "ENTRANCE_RAMP", "WAYPOINT_LEFT", "WAYPOINT_RIGHT", "WAYPOINT_REACHED"]
          }
        },
        message: {
          readOnly: !0,
          serializedName: "message",
          type: {
            name: "String"
          }
        }
      }
    }
  },
  RouteResultInstructionGroup = {
    serializedName: "RouteResultInstructionGroup",
    type: {
      name: "Composite",
      className: "RouteResultInstructionGroup",
      modelProperties: {
        firstInstructionIndex: {
          readOnly: !0,
          serializedName: "firstInstructionIndex",
          type: {
            name: "Number"
          }
        },
        lastInstructionIndex: {
          readOnly: !0,
          serializedName: "lastInstructionIndex",
          type: {
            name: "Number"
          }
        },
        groupLengthInMeters: {
          readOnly: !0,
          serializedName: "groupLengthInMeters",
          type: {
            name: "Number"
          }
        },
        groupMessage: {
          readOnly: !0,
          serializedName: "groupMessage",
          type: {
            name: "String"
          }
        }
      }
    }
  },
  RouteResultGuidance = {
    serializedName: "RouteResultGuidance",
    type: {
      name: "Composite",
      className: "RouteResultGuidance",
      modelProperties: {
        instructions: {
          readOnly: !0,
          serializedName: "instructions",
          type: {
            name: "Sequence",
            element: {
              type: {
                name: "Composite",
                className: "RouteResultInstruction"
              }
            }
          }
        },
        instructionGroups: {
          readOnly: !0,
          serializedName: "instructionGroups",
          type: {
            name: "Sequence",
            element: {
              type: {
                name: "Composite",
                className: "RouteResultInstructionGroup"
              }
            }
          }
        }
      }
    }
  },
  RouteDirectionsResult = {
    serializedName: "RouteDirectionsResult",
    type: {
      name: "Composite",
      className: "RouteDirectionsResult",
      modelProperties: {
        summary: {
          serializedName: "summary",
          type: {
            name: "Composite",
            className: "RouteDirectionsSummary"
          }
        },
        legs: {
          readOnly: !0,
          serializedName: "legs",
          type: {
            name: "Sequence",
            element: {
              type: {
                name: "Composite",
                className: "RouteResultLeg"
              }
            }
          }
        },
        sections: {
          readOnly: !0,
          serializedName: "sections",
          type: {
            name: "Sequence",
            element: {
              type: {
                name: "Composite",
                className: "RouteResultSection"
              }
            }
          }
        },
        guidance: {
          serializedName: "guidance",
          type: {
            name: "Composite",
            className: "RouteResultGuidance"
          }
        }
      }
    }
  },
  RouteOptimizedWaypoint = {
    serializedName: "RouteOptimizedWaypoint",
    type: {
      name: "Composite",
      className: "RouteOptimizedWaypoint",
      modelProperties: {
        providedIndex: {
          readOnly: !0,
          serializedName: "providedIndex",
          type: {
            name: "Number"
          }
        },
        optimizedIndex: {
          readOnly: !0,
          serializedName: "optimizedIndex",
          type: {
            name: "Number"
          }
        }
      }
    }
  },
  RouteResponseReportEffectiveSetting = {
    serializedName: "RouteResponseReportEffectiveSetting",
    type: {
      name: "Composite",
      className: "RouteResponseReportEffectiveSetting",
      modelProperties: {
        key: {
          readOnly: !0,
          serializedName: "key",
          type: {
            name: "String"
          }
        },
        value: {
          readOnly: !0,
          serializedName: "value",
          type: {
            name: "String"
          }
        }
      }
    }
  },
  RouteResponseReport = {
    serializedName: "RouteResponseReport",
    type: {
      name: "Composite",
      className: "RouteResponseReport",
      modelProperties: {
        effectiveSettings: {
          readOnly: !0,
          serializedName: "effectiveSettings",
          type: {
            name: "Sequence",
            element: {
              type: {
                name: "Composite",
                className: "RouteResponseReportEffectiveSetting"
              }
            }
          }
        }
      }
    }
  },
  RouteDirectionsResponse = {
    serializedName: "RouteDirectionsResponse",
    type: {
      name: "Composite",
      className: "RouteDirectionsResponse",
      modelProperties: {
        formatVersion: {
          readOnly: !0,
          serializedName: "formatVersion",
          type: {
            name: "String"
          }
        },
        copyright: {
          readOnly: !0,
          serializedName: "copyright",
          type: {
            name: "String"
          }
        },
        privacy: {
          readOnly: !0,
          serializedName: "privacy",
          type: {
            name: "String"
          }
        },
        routes: {
          readOnly: !0,
          serializedName: "routes",
          type: {
            name: "Sequence",
            element: {
              type: {
                name: "Composite",
                className: "RouteDirectionsResult"
              }
            }
          }
        },
        optimizedWaypoints: {
          readOnly: !0,
          serializedName: "optimizedWaypoints",
          type: {
            name: "Sequence",
            element: {
              type: {
                name: "Composite",
                className: "RouteOptimizedWaypoint"
              }
            }
          }
        },
        report: {
          serializedName: "report",
          type: {
            name: "Composite",
            className: "RouteResponseReport"
          }
        }
      }
    }
  },
  RouteRange = {
    serializedName: "RouteRange",
    type: {
      name: "Composite",
      className: "RouteRange",
      modelProperties: {
        center: {
          serializedName: "center",
          type: {
            name: "Composite",
            className: "Coordinate"
          }
        },
        boundary: {
          readOnly: !0,
          serializedName: "boundary",
          type: {
            name: "Sequence",
            element: {
              type: {
                name: "Composite",
                className: "Coordinate"
              }
            }
          }
        }
      }
    }
  },
  RouteRangeResponse = {
    serializedName: "RouteRangeResponse",
    type: {
      name: "Composite",
      className: "RouteRangeResponse",
      modelProperties: {
        formatVersion: {
          readOnly: !0,
          serializedName: "formatVersion",
          type: {
            name: "String"
          }
        },
        copyright: {
          readOnly: !0,
          serializedName: "copyright",
          type: {
            name: "String"
          }
        },
        privacy: {
          readOnly: !0,
          serializedName: "privacy",
          type: {
            name: "String"
          }
        },
        reachableRange: {
          serializedName: "reachableRange",
          type: {
            name: "Composite",
            className: "RouteRange"
          }
        },
        report: {
          serializedName: "report",
          type: {
            name: "Composite",
            className: "RouteResponseReport"
          }
        }
      }
    }
  },
  RouteMatrixResultResponse = {
    serializedName: "RouteMatrixResultResponse",
    type: {
      name: "Composite",
      className: "RouteMatrixResultResponse",
      modelProperties: {
        routeSummary: {
          serializedName: "routeSummary",
          type: {
            name: "Composite",
            className: "RouteResultLegSummary"
          }
        }
      }
    }
  },
  RouteMatrixResult = {
    serializedName: "RouteMatrixResult",
    type: {
      name: "Composite",
      className: "RouteMatrixResult",
      modelProperties: {
        statusCode: {
          readOnly: !0,
          serializedName: "statusCode",
          type: {
            name: "Number"
          }
        },
        response: {
          serializedName: "response",
          type: {
            name: "Composite",
            className: "RouteMatrixResultResponse"
          }
        }
      }
    }
  },
  RouteMatrixSummary = {
    serializedName: "RouteMatrixSummary",
    type: {
      name: "Composite",
      className: "RouteMatrixSummary",
      modelProperties: {
        successfulRoutes: {
          readOnly: !0,
          serializedName: "successfulRoutes",
          type: {
            name: "Number"
          }
        },
        totalRoutes: {
          readOnly: !0,
          serializedName: "totalRoutes",
          type: {
            name: "Number"
          }
        }
      }
    }
  },
  RouteMatrixResponse = {
    serializedName: "RouteMatrixResponse",
    type: {
      name: "Composite",
      className: "RouteMatrixResponse",
      modelProperties: {
        formatVersion: {
          readOnly: !0,
          serializedName: "formatVersion",
          type: {
            name: "String"
          }
        },
        matrix: {
          readOnly: !0,
          serializedName: "matrix",
          type: {
            name: "Sequence",
            element: {
              type: {
                name: "Sequence",
                element: {
                  type: {
                    name: "Composite",
                    className: "RouteMatrixResult"
                  }
                }
              }
            }
          }
        },
        summary: {
          serializedName: "summary",
          type: {
            name: "Composite",
            className: "RouteMatrixSummary"
          }
        }
      }
    }
  },
  RouteMatrixRequestBody = {
    serializedName: "RouteMatrixRequestBody",
    type: {
      name: "Composite",
      className: "RouteMatrixRequestBody",
      modelProperties: {
        origins: {
          serializedName: "origins",
          type: {
            name: "Composite",
            className: "MultiPoint"
          }
        },
        destinations: {
          serializedName: "destinations",
          type: {
            name: "Composite",
            className: "MultiPoint"
          }
        }
      }
    }
  },
  Country = {
    serializedName: "Country",
    type: {
      name: "Composite",
      className: "Country",
      modelProperties: {
        name: {
          readOnly: !0,
          serializedName: "Name",
          type: {
            name: "String"
          }
        },
        code: {
          readOnly: !0,
          serializedName: "Code",
          type: {
            name: "String"
          }
        }
      }
    }
  },
  TimeTransition = {
    serializedName: "TimeTransition",
    type: {
      name: "Composite",
      className: "TimeTransition",
      modelProperties: {
        tag: {
          readOnly: !0,
          serializedName: "Tag",
          type: {
            name: "String"
          }
        },
        standardOffset: {
          readOnly: !0,
          serializedName: "StandardOffset",
          type: {
            name: "String"
          }
        },
        daylightSavings: {
          readOnly: !0,
          serializedName: "DaylightSavings",
          type: {
            name: "String"
          }
        },
        utcStart: {
          readOnly: !0,
          serializedName: "UtcStart",
          type: {
            name: "DateTime"
          }
        },
        utcEnd: {
          readOnly: !0,
          serializedName: "UtcEnd",
          type: {
            name: "DateTime"
          }
        }
      }
    }
  },
  Names = {
    serializedName: "Names",
    type: {
      name: "Composite",
      className: "Names",
      modelProperties: {
        iSO6391LanguageCode: {
          readOnly: !0,
          serializedName: "ISO6391LanguageCode",
          type: {
            name: "String"
          }
        },
        generic: {
          readOnly: !0,
          serializedName: "Generic",
          type: {
            name: "String"
          }
        },
        standard: {
          readOnly: !0,
          serializedName: "Standard",
          type: {
            name: "String"
          }
        },
        daylight: {
          readOnly: !0,
          serializedName: "Daylight",
          type: {
            name: "String"
          }
        }
      }
    }
  },
  ReferenceTimeById = {
    serializedName: "ReferenceTimeById",
    type: {
      name: "Composite",
      className: "ReferenceTimeById",
      modelProperties: {
        tag: {
          readOnly: !0,
          serializedName: "Tag",
          type: {
            name: "String"
          }
        },
        standardOffset: {
          readOnly: !0,
          serializedName: "StandardOffset",
          type: {
            name: "String"
          }
        },
        daylightSavings: {
          readOnly: !0,
          serializedName: "DaylightSavings",
          type: {
            name: "String"
          }
        },
        wallTime: {
          readOnly: !0,
          serializedName: "WallTime",
          type: {
            name: "String"
          }
        },
        posixTzValidYear: {
          readOnly: !0,
          serializedName: "PosixTzValidYear",
          type: {
            name: "Number"
          }
        },
        posixTz: {
          readOnly: !0,
          serializedName: "PosixTz",
          type: {
            name: "String"
          }
        }
      }
    }
  },
  RepresentativePoint = {
    serializedName: "RepresentativePoint",
    type: {
      name: "Composite",
      className: "RepresentativePoint",
      modelProperties: {
        latitude: {
          readOnly: !0,
          serializedName: "Latitude",
          type: {
            name: "Number"
          }
        },
        longitude: {
          readOnly: !0,
          serializedName: "Longitude",
          type: {
            name: "Number"
          }
        }
      }
    }
  },
  TimezoneById = {
    serializedName: "TimezoneById",
    type: {
      name: "Composite",
      className: "TimezoneById",
      modelProperties: {
        id: {
          readOnly: !0,
          serializedName: "Id",
          type: {
            name: "String"
          }
        },
        aliases: {
          readOnly: !0,
          serializedName: "Aliases",
          type: {
            name: "Sequence",
            element: {
              type: {
                name: "String"
              }
            }
          }
        },
        countries: {
          readOnly: !0,
          serializedName: "Countries",
          type: {
            name: "Sequence",
            element: {
              type: {
                name: "Composite",
                className: "Country"
              }
            }
          }
        },
        names: {
          serializedName: "Names",
          type: {
            name: "Composite",
            className: "Names"
          }
        },
        referenceTime: {
          serializedName: "ReferenceTime",
          type: {
            name: "Composite",
            className: "ReferenceTimeById"
          }
        },
        representativePoint: {
          serializedName: "RepresentativePoint",
          type: {
            name: "Composite",
            className: "RepresentativePoint"
          }
        },
        timeTransitions: {
          readOnly: !0,
          serializedName: "TimeTransitions",
          type: {
            name: "Sequence",
            element: {
              type: {
                name: "Composite",
                className: "TimeTransition"
              }
            }
          }
        }
      }
    }
  },
  TimezoneByIdResult = {
    serializedName: "TimezoneByIdResult",
    type: {
      name: "Composite",
      className: "TimezoneByIdResult",
      modelProperties: {
        version: {
          readOnly: !0,
          serializedName: "Version",
          type: {
            name: "String"
          }
        },
        referenceUtcTimestamp: {
          readOnly: !0,
          serializedName: "ReferenceUtcTimestamp",
          type: {
            name: "DateTime"
          }
        },
        timeZones: {
          serializedName: "TimeZones",
          type: {
            name: "Sequence",
            element: {
              type: {
                name: "Composite",
                className: "TimezoneById"
              }
            }
          }
        },
        count: {
          readOnly: !0,
          serializedName: "Count",
          type: {
            name: "Number"
          }
        }
      }
    }
  },
  ReferenceTimeByCoordinates = {
    serializedName: "ReferenceTimeByCoordinates",
    type: {
      name: "Composite",
      className: "ReferenceTimeByCoordinates",
      modelProperties: {
        tag: {
          readOnly: !0,
          serializedName: "Tag",
          type: {
            name: "String"
          }
        },
        standardOffset: {
          readOnly: !0,
          serializedName: "StandardOffset",
          type: {
            name: "String"
          }
        },
        daylightSavings: {
          readOnly: !0,
          serializedName: "DaylightSavings",
          type: {
            name: "String"
          }
        },
        wallTime: {
          readOnly: !0,
          serializedName: "WallTime",
          type: {
            name: "String"
          }
        },
        posixTzValidYear: {
          readOnly: !0,
          serializedName: "PosixTzValidYear",
          type: {
            name: "Number"
          }
        },
        posixTz: {
          readOnly: !0,
          serializedName: "PosixTz",
          type: {
            name: "String"
          }
        },
        sunrise: {
          readOnly: !0,
          serializedName: "Sunrise",
          type: {
            name: "String"
          }
        },
        sunset: {
          readOnly: !0,
          serializedName: "Sunset",
          type: {
            name: "String"
          }
        }
      }
    }
  },
  TimeZoneByCoordinates = {
    serializedName: "TimeZoneByCoordinates",
    type: {
      name: "Composite",
      className: "TimeZoneByCoordinates",
      modelProperties: {
        id: {
          readOnly: !0,
          serializedName: "Id",
          type: {
            name: "String"
          }
        },
        aliases: {
          readOnly: !0,
          serializedName: "Aliases",
          type: {
            name: "Sequence",
            element: {
              type: {
                name: "String"
              }
            }
          }
        },
        countries: {
          readOnly: !0,
          serializedName: "Countries",
          type: {
            name: "Sequence",
            element: {
              type: {
                name: "Composite",
                className: "Country"
              }
            }
          }
        },
        names: {
          serializedName: "Names",
          type: {
            name: "Composite",
            className: "Names"
          }
        },
        referenceTime: {
          serializedName: "ReferenceTime",
          type: {
            name: "Composite",
            className: "ReferenceTimeByCoordinates"
          }
        },
        representativePoint: {
          serializedName: "RepresentativePoint",
          type: {
            name: "Composite",
            className: "RepresentativePoint"
          }
        },
        timeTransitions: {
          readOnly: !0,
          serializedName: "TimeTransitions",
          type: {
            name: "Sequence",
            element: {
              type: {
                name: "Composite",
                className: "TimeTransition"
              }
            }
          }
        }
      }
    }
  },
  TimezoneByCoordinatesResult = {
    serializedName: "TimezoneByCoordinatesResult",
    type: {
      name: "Composite",
      className: "TimezoneByCoordinatesResult",
      modelProperties: {
        version: {
          readOnly: !0,
          serializedName: "Version",
          type: {
            name: "String"
          }
        },
        referenceUtcTimestamp: {
          readOnly: !0,
          serializedName: "ReferenceUtcTimestamp",
          type: {
            name: "DateTime"
          }
        },
        timeZones: {
          serializedName: "TimeZones",
          type: {
            name: "Sequence",
            element: {
              type: {
                name: "Composite",
                className: "TimeZoneByCoordinates"
              }
            }
          }
        },
        count: {
          readOnly: !0,
          serializedName: "Count",
          type: {
            name: "Number"
          }
        }
      }
    }
  },
  TimezoneEnumWindow = {
    serializedName: "TimezoneEnumWindow",
    type: {
      name: "Composite",
      className: "TimezoneEnumWindow",
      modelProperties: {
        windowsId: {
          readOnly: !0,
          serializedName: "WindowsId",
          type: {
            name: "String"
          }
        },
        territory: {
          readOnly: !0,
          serializedName: "Territory",
          type: {
            name: "String"
          }
        },
        ianaIds: {
          serializedName: "IanaIds",
          type: {
            name: "Sequence",
            element: {
              type: {
                name: "String"
              }
            }
          }
        }
      }
    }
  },
  IanaId = {
    serializedName: "IanaId",
    type: {
      name: "Composite",
      className: "IanaId",
      modelProperties: {
        id: {
          readOnly: !0,
          serializedName: "id",
          type: {
            name: "String"
          }
        },
        isAlias: {
          readOnly: !0,
          serializedName: "isAlias",
          type: {
            name: "Boolean"
          }
        },
        aliasOf: {
          readOnly: !0,
          serializedName: "aliasOf",
          type: {
            name: "String"
          }
        },
        hasZone1970Location: {
          readOnly: !0,
          serializedName: "hasZone1970Location",
          type: {
            name: "Boolean"
          }
        }
      }
    }
  },
  TimezoneIanaVersionResult = {
    serializedName: "TimezoneIanaVersionResult",
    type: {
      name: "Composite",
      className: "TimezoneIanaVersionResult",
      modelProperties: {
        version: {
          readOnly: !0,
          serializedName: "version",
          type: {
            name: "String"
          }
        }
      }
    }
  },
  RegionCountry = {
    serializedName: "Region_country",
    type: {
      name: "Composite",
      className: "RegionCountry",
      modelProperties: {
        iSO3: {
          readOnly: !0,
          serializedName: "ISO3",
          type: {
            name: "String"
          }
        },
        label: {
          readOnly: !0,
          serializedName: "label",
          type: {
            name: "String"
          }
        }
      }
    }
  },
  Region = {
    serializedName: "Region",
    type: {
      name: "Composite",
      className: "Region",
      modelProperties: {
        copyrights: {
          readOnly: !0,
          serializedName: "copyrights",
          type: {
            name: "Sequence",
            element: {
              type: {
                name: "String"
              }
            }
          }
        },
        country: {
          readOnly: !0,
          serializedName: "country",
          type: {
            name: "Composite",
            className: "RegionCountry"
          }
        }
      }
    }
  },
  CopyrightBoundingResult = {
    serializedName: "CopyrightBoundingResult",
    type: {
      name: "Composite",
      className: "CopyrightBoundingResult",
      modelProperties: {
        formatVersion: {
          readOnly: !0,
          serializedName: "formatVersion",
          type: {
            name: "String"
          }
        },
        generalCopyrights: {
          readOnly: !0,
          serializedName: "generalCopyrights",
          type: {
            name: "Sequence",
            element: {
              type: {
                name: "String"
              }
            }
          }
        },
        regions: {
          readOnly: !0,
          serializedName: "regions",
          type: {
            name: "Sequence",
            element: {
              type: {
                name: "Composite",
                className: "Region"
              }
            }
          }
        }
      }
    }
  },
  CopyrightCaptionResult = {
    serializedName: "CopyrightCaptionResult",
    type: {
      name: "Composite",
      className: "CopyrightCaptionResult",
      modelProperties: {
        formatVersion: {
          readOnly: !0,
          serializedName: "formatVersion",
          type: {
            name: "String"
          }
        },
        copyrightsCaption: {
          readOnly: !0,
          serializedName: "copyrightsCaption",
          type: {
            name: "String"
          }
        }
      }
    }
  },
  CopyrightWorldResult = {
    serializedName: "CopyrightWorldResult",
    type: {
      name: "Composite",
      className: "CopyrightWorldResult",
      modelProperties: {
        formatVersion: {
          readOnly: !0,
          serializedName: "formatVersion",
          type: {
            name: "String"
          }
        },
        generalCopyrights: {
          readOnly: !0,
          serializedName: "generalCopyrights",
          type: {
            name: "Sequence",
            element: {
              type: {
                name: "String"
              }
            }
          }
        },
        regions: {
          readOnly: !0,
          serializedName: "regions",
          type: {
            name: "Sequence",
            element: {
              type: {
                name: "Composite",
                className: "Region"
              }
            }
          }
        }
      }
    }
  },
  CopyrightTileResult = {
    serializedName: "CopyrightTileResult",
    type: {
      name: "Composite",
      className: "CopyrightTileResult",
      modelProperties: {
        formatVersion: {
          readOnly: !0,
          serializedName: "formatVersion",
          type: {
            name: "String"
          }
        },
        generalCopyrights: {
          readOnly: !0,
          serializedName: "generalCopyrights",
          type: {
            name: "Sequence",
            element: {
              type: {
                name: "String"
              }
            }
          }
        },
        regions: {
          readOnly: !0,
          serializedName: "regions",
          type: {
            name: "Sequence",
            element: {
              type: {
                name: "Composite",
                className: "Region"
              }
            }
          }
        }
      }
    }
  },
  Polygon = {
    serializedName: "Polygon",
    type: {
      name: "Composite",
      polymorphicDiscriminator: GeoJSONGeometry.type.polymorphicDiscriminator,
      uberParent: "GeoJSONGeometry",
      className: "Polygon",
      modelProperties: __assign(__assign({}, GeoJSONGeometry.type.modelProperties), {
        coordinates: {
          required: !0,
          serializedName: "coordinates",
          type: {
            name: "Sequence",
            element: {
              type: {
                name: "Sequence",
                element: {
                  type: {
                    name: "Sequence",
                    element: {
                      type: {
                        name: "Number"
                      }
                    }
                  }
                }
              }
            }
          }
        }
      })
    }
  },
  SpatialCoordinate = {
    serializedName: "SpatialCoordinate",
    type: {
      name: "Composite",
      className: "SpatialCoordinate",
      modelProperties: {
        lat: {
          readOnly: !0,
          serializedName: "lat",
          type: {
            name: "Number"
          }
        },
        lon: {
          readOnly: !0,
          serializedName: "lon",
          type: {
            name: "Number"
          }
        }
      }
    }
  },
  GeoJSONFeatureCollectionFeaturesItem = {
    serializedName: "GeoJSONFeatureCollection_featuresItem",
    type: {
      name: "Composite",
      className: "GeoJSONFeatureCollectionFeaturesItem",
      modelProperties: {
        type: {
          serializedName: "type",
          type: {
            name: "String"
          }
        },
        geometry: {
          serializedName: "geometry",
          type: {
            name: "Composite",
            className: "GeoJSONGeometry"
          }
        },
        properties: {
          serializedName: "properties",
          type: {
            name: "Object"
          }
        }
      }
    }
  },
  GeoJSONFeatureCollection = {
    serializedName: "GeoJSONFeatureCollection",
    type: {
      name: "Composite",
      className: "GeoJSONFeatureCollection",
      modelProperties: {
        type: {
          required: !0,
          serializedName: "type",
          type: {
            name: "String"
          }
        },
        features: {
          required: !0,
          serializedName: "features",
          type: {
            name: "Sequence",
            element: {
              type: {
                name: "Composite",
                className: "GeoJSONFeatureCollectionFeaturesItem"
              }
            }
          }
        }
      }
    }
  },
  MultiLineString = {
    serializedName: "MultiLineString",
    type: {
      name: "Composite",
      polymorphicDiscriminator: GeoJSONGeometry.type.polymorphicDiscriminator,
      uberParent: "GeoJSONGeometry",
      className: "MultiLineString",
      modelProperties: __assign(__assign({}, GeoJSONGeometry.type.modelProperties), {
        coordinates: {
          required: !0,
          serializedName: "coordinates",
          type: {
            name: "Sequence",
            element: {
              type: {
                name: "Sequence",
                element: {
                  type: {
                    name: "Sequence",
                    element: {
                      type: {
                        name: "Number"
                      }
                    }
                  }
                }
              }
            }
          }
        }
      })
    }
  },
  GeofenceGeometry = {
    serializedName: "GeofenceGeometry",
    type: {
      name: "Composite",
      className: "GeofenceGeometry",
      modelProperties: {
        deviceId: {
          readOnly: !0,
          serializedName: "deviceId",
          type: {
            name: "String"
          }
        },
        udId: {
          readOnly: !0,
          serializedName: "udId",
          type: {
            name: "String"
          }
        },
        geometryId: {
          readOnly: !0,
          serializedName: "geometryId",
          type: {
            name: "String"
          }
        },
        distance: {
          readOnly: !0,
          serializedName: "distance",
          type: {
            name: "Number"
          }
        },
        nearestLat: {
          readOnly: !0,
          serializedName: "nearestLat",
          type: {
            name: "Number"
          }
        },
        nearestLon: {
          readOnly: !0,
          serializedName: "nearestLon",
          type: {
            name: "Number"
          }
        }
      }
    }
  },
  GeofenceResponse = {
    serializedName: "GeofenceResponse",
    type: {
      name: "Composite",
      className: "GeofenceResponse",
      modelProperties: {
        geometries: {
          readOnly: !0,
          serializedName: "geometries",
          type: {
            name: "Sequence",
            element: {
              type: {
                name: "Composite",
                className: "GeofenceGeometry"
              }
            }
          }
        },
        expiredGeofenceGeometryId: {
          readOnly: !0,
          serializedName: "expiredGeofenceGeometryId",
          type: {
            name: "Sequence",
            element: {
              type: {
                name: "String"
              }
            }
          }
        },
        invalidPeriodGeofenceGeometryId: {
          readOnly: !0,
          serializedName: "invalidPeriodGeofenceGeometryId",
          type: {
            name: "Sequence",
            element: {
              type: {
                name: "String"
              }
            }
          }
        },
        isEventPublished: {
          readOnly: !0,
          serializedName: "isEventPublished",
          type: {
            name: "Boolean"
          }
        }
      }
    }
  },
  BufferRequestBody = {
    serializedName: "BufferRequestBody",
    type: {
      name: "Composite",
      className: "BufferRequestBody",
      modelProperties: {
        geometries: {
          serializedName: "geometries",
          type: {
            name: "Composite",
            className: "GeoJSONFeatureCollection"
          }
        },
        distances: {
          serializedName: "distances",
          type: {
            name: "Sequence",
            element: {
              type: {
                name: "Number"
              }
            }
          }
        }
      }
    }
  },
  BufferResponseSummary = {
    serializedName: "BufferResponse_summary",
    type: {
      name: "Composite",
      className: "BufferResponseSummary",
      modelProperties: {
        udid: {
          readOnly: !0,
          serializedName: "udid",
          type: {
            name: "String"
          }
        },
        information: {
          readOnly: !0,
          serializedName: "information",
          type: {
            name: "String"
          }
        }
      }
    }
  },
  BufferResponse = {
    serializedName: "BufferResponse",
    type: {
      name: "Composite",
      className: "BufferResponse",
      modelProperties: {
        summary: {
          readOnly: !0,
          serializedName: "summary",
          type: {
            name: "Composite",
            className: "BufferResponseSummary"
          }
        },
        result: {
          serializedName: "result",
          type: {
            name: "Composite",
            className: "GeoJSONFeatureCollection"
          }
        }
      }
    }
  },
  GetClosestPointSummary = {
    serializedName: "GetClosestPointSummary",
    type: {
      name: "Composite",
      className: "GetClosestPointSummary",
      modelProperties: {
        sourcePoint: {
          serializedName: "sourcePoint",
          type: {
            name: "Composite",
            className: "SpatialCoordinate"
          }
        },
        udid: {
          readOnly: !0,
          serializedName: "udid",
          type: {
            name: "String"
          }
        },
        information: {
          readOnly: !0,
          serializedName: "information",
          type: {
            name: "String"
          }
        }
      }
    }
  },
  ClosestPointResultEntry = {
    serializedName: "ClosestPointResultEntry",
    type: {
      name: "Composite",
      className: "ClosestPointResultEntry",
      modelProperties: {
        distanceInMeters: {
          readOnly: !0,
          serializedName: "distanceInMeters",
          type: {
            name: "Number"
          }
        },
        position: {
          serializedName: "position",
          type: {
            name: "Composite",
            className: "SpatialCoordinate"
          }
        },
        geometryId: {
          readOnly: !0,
          serializedName: "geometryId",
          type: {
            name: "String"
          }
        }
      }
    }
  },
  GetClosestPointResponse = {
    serializedName: "GetClosestPointResponse",
    type: {
      name: "Composite",
      className: "GetClosestPointResponse",
      modelProperties: {
        summary: {
          serializedName: "summary",
          type: {
            name: "Composite",
            className: "GetClosestPointSummary"
          }
        },
        result: {
          serializedName: "result",
          type: {
            name: "Sequence",
            element: {
              type: {
                name: "Composite",
                className: "ClosestPointResultEntry"
              }
            }
          }
        }
      }
    }
  },
  PostClosestPointSummary = {
    serializedName: "PostClosestPointSummary",
    type: {
      name: "Composite",
      className: "PostClosestPointSummary",
      modelProperties: {
        sourcePoint: {
          serializedName: "sourcePoint",
          type: {
            name: "Composite",
            className: "SpatialCoordinate"
          }
        },
        udid: {
          readOnly: !0,
          serializedName: "udid",
          type: {
            name: "String"
          }
        },
        information: {
          readOnly: !0,
          serializedName: "information",
          type: {
            name: "String"
          }
        }
      }
    }
  },
  PostClosestPointResponse = {
    serializedName: "PostClosestPointResponse",
    type: {
      name: "Composite",
      className: "PostClosestPointResponse",
      modelProperties: {
        summary: {
          serializedName: "summary",
          type: {
            name: "Composite",
            className: "PostClosestPointSummary"
          }
        },
        result: {
          serializedName: "result",
          type: {
            name: "Sequence",
            element: {
              type: {
                name: "Composite",
                className: "ClosestPointResultEntry"
              }
            }
          }
        }
      }
    }
  },
  GetPointInPolygonSummary = {
    serializedName: "GetPointInPolygonSummary",
    type: {
      name: "Composite",
      className: "GetPointInPolygonSummary",
      modelProperties: {
        sourcePoint: {
          serializedName: "sourcePoint",
          type: {
            name: "Composite",
            className: "SpatialCoordinate"
          }
        },
        udid: {
          readOnly: !0,
          serializedName: "udid",
          type: {
            name: "String"
          }
        },
        information: {
          readOnly: !0,
          serializedName: "information",
          type: {
            name: "String"
          }
        }
      }
    }
  },
  PointInPolygonResult = {
    serializedName: "PointInPolygonResult",
    type: {
      name: "Composite",
      className: "PointInPolygonResult",
      modelProperties: {
        pointInPolygons: {
          readOnly: !0,
          serializedName: "pointInPolygons",
          type: {
            name: "Boolean"
          }
        },
        intersectingGeometries: {
          readOnly: !0,
          serializedName: "intersectingGeometries",
          type: {
            name: "Sequence",
            element: {
              type: {
                name: "String"
              }
            }
          }
        }
      }
    }
  },
  GetPointInPolygonResponse = {
    serializedName: "GetPointInPolygonResponse",
    type: {
      name: "Composite",
      className: "GetPointInPolygonResponse",
      modelProperties: {
        summary: {
          serializedName: "summary",
          type: {
            name: "Composite",
            className: "GetPointInPolygonSummary"
          }
        },
        result: {
          serializedName: "result",
          type: {
            name: "Composite",
            className: "PointInPolygonResult"
          }
        }
      }
    }
  },
  PostPointInPolygonSummary = {
    serializedName: "PostPointInPolygonSummary",
    type: {
      name: "Composite",
      className: "PostPointInPolygonSummary",
      modelProperties: {
        sourcePoint: {
          serializedName: "sourcePoint",
          type: {
            name: "Composite",
            className: "SpatialCoordinate"
          }
        },
        udid: {
          readOnly: !0,
          serializedName: "udid",
          type: {
            name: "String"
          }
        },
        information: {
          readOnly: !0,
          serializedName: "information",
          type: {
            name: "String"
          }
        }
      }
    }
  },
  PostPointInPolygonResponse = {
    serializedName: "PostPointInPolygonResponse",
    type: {
      name: "Composite",
      className: "PostPointInPolygonResponse",
      modelProperties: {
        summary: {
          serializedName: "summary",
          type: {
            name: "Composite",
            className: "PostPointInPolygonSummary"
          }
        },
        result: {
          serializedName: "result",
          type: {
            name: "Composite",
            className: "PointInPolygonResult"
          }
        }
      }
    }
  },
  GreatCircleDistanceResponseSummary = {
    serializedName: "GreatCircleDistanceResponse_summary",
    type: {
      name: "Composite",
      className: "GreatCircleDistanceResponseSummary",
      modelProperties: {
        sourcePoint: {
          serializedName: "sourcePoint",
          type: {
            name: "Composite",
            className: "SpatialCoordinate"
          }
        },
        targetPoint: {
          serializedName: "targetPoint",
          type: {
            name: "Composite",
            className: "SpatialCoordinate"
          }
        }
      }
    }
  },
  GreatCircleDistanceResponseResult = {
    serializedName: "GreatCircleDistanceResponse_result",
    type: {
      name: "Composite",
      className: "GreatCircleDistanceResponseResult",
      modelProperties: {
        distanceInMeters: {
          readOnly: !0,
          serializedName: "distanceInMeters",
          type: {
            name: "Number"
          }
        }
      }
    }
  },
  GreatCircleDistanceResponse = {
    serializedName: "GreatCircleDistanceResponse",
    type: {
      name: "Composite",
      className: "GreatCircleDistanceResponse",
      modelProperties: {
        summary: {
          readOnly: !0,
          serializedName: "summary",
          type: {
            name: "Composite",
            className: "GreatCircleDistanceResponseSummary"
          }
        },
        result: {
          readOnly: !0,
          serializedName: "result",
          type: {
            name: "Composite",
            className: "GreatCircleDistanceResponseResult"
          }
        }
      }
    }
  },
  SearchPostSearchFuzzyBatchPreviewHeaders = {
    serializedName: "search-postsearchfuzzybatchpreview-headers",
    type: {
      name: "Composite",
      className: "SearchPostSearchFuzzyBatchPreviewHeaders",
      modelProperties: {
        location: {
          serializedName: "location",
          type: {
            name: "String"
          }
        }
      }
    }
  },
  SearchPostSearchAddressBatchPreviewHeaders = {
    serializedName: "search-postsearchaddressbatchpreview-headers",
    type: {
      name: "Composite",
      className: "SearchPostSearchAddressBatchPreviewHeaders",
      modelProperties: {
        location: {
          serializedName: "location",
          type: {
            name: "String"
          }
        }
      }
    }
  },
  SearchPostSearchAddressReverseBatchPreviewHeaders = {
    serializedName: "search-postsearchaddressreversebatchpreview-headers",
    type: {
      name: "Composite",
      className: "SearchPostSearchAddressReverseBatchPreviewHeaders",
      modelProperties: {
        location: {
          serializedName: "location",
          type: {
            name: "String"
          }
        }
      }
    }
  },
  RoutePostRouteMatrixPreviewHeaders = {
    serializedName: "route-postroutematrixpreview-headers",
    type: {
      name: "Composite",
      className: "RoutePostRouteMatrixPreviewHeaders",
      modelProperties: {
        location: {
          serializedName: "location",
          type: {
            name: "String"
          }
        }
      }
    }
  },
  RoutePostRouteDirectionsBatchPreviewHeaders = {
    serializedName: "route-postroutedirectionsbatchpreview-headers",
    type: {
      name: "Composite",
      className: "RoutePostRouteDirectionsBatchPreviewHeaders",
      modelProperties: {
        location: {
          serializedName: "location",
          type: {
            name: "String"
          }
        }
      }
    }
  },
  SpatialGetGeofenceHeaders = {
    serializedName: "spatial-getgeofence-headers",
    type: {
      name: "Composite",
      className: "SpatialGetGeofenceHeaders",
      modelProperties: {
        xCorrelationId: {
          serializedName: "x-correlation-id",
          type: {
            name: "String"
          }
        }
      }
    }
  },
  SpatialPostGeofenceHeaders = {
    serializedName: "spatial-postgeofence-headers",
    type: {
      name: "Composite",
      className: "SpatialPostGeofenceHeaders",
      modelProperties: {
        xCorrelationId: {
          serializedName: "x-correlation-id",
          type: {
            name: "String"
          }
        }
      }
    }
  },
  discriminators = {
    GeoJSONGeometry: GeoJSONGeometry,
    "GeoJSONGeometry.LineString": LineString,
    "GeoJSONGeometry.MultiPoint": MultiPoint,
    "GeoJSONGeometry.Point": Point,
    "GeoJSONGeometry.MultiPolygon": MultiPolygon,
    "GeoJSONGeometry.Polygon": Polygon,
    "GeoJSONGeometry.MultiLineString": MultiLineString
  },
  Mappers = Object.freeze({
    __proto__: null,
    discriminators: discriminators,
    BatchRequestBody: BatchRequestBody,
    BatchRequestBodyBatchItemsItem: BatchRequestBodyBatchItemsItem,
    BatchResponse: BatchResponse,
    BatchResponseSummary: BatchResponseSummary,
    CloudError: CloudError,
    CoordinateAbbreviated: CoordinateAbbreviated,
    DataSources: DataSources,
    DataSourcesGeometry: DataSourcesGeometry,
    GeoJSONGeometry: GeoJSONGeometry,
    LineString: LineString,
    MultiLineString: MultiLineString,
    MultiPoint: MultiPoint,
    MultiPolygon: MultiPolygon,
    Point: Point,
    Polygon: Polygon,
    SearchAddressResponse: SearchAddressResponse,
    SearchAddressResult: SearchAddressResult,
    SearchAddressReverseCrossStreetResponse: SearchAddressReverseCrossStreetResponse,
    SearchAddressReverseCrossStreetResult: SearchAddressReverseCrossStreetResult,
    SearchAddressReverseCrossStreetSummary: SearchAddressReverseCrossStreetSummary,
    SearchAddressReverseResponse: SearchAddressReverseResponse,
    SearchAddressReverseResult: SearchAddressReverseResult,
    SearchAddressReverseSummary: SearchAddressReverseSummary,
    SearchAddressStructuredResponse: SearchAddressStructuredResponse,
    SearchAddressStructuredResult: SearchAddressStructuredResult,
    SearchAddressStructuredSummary: SearchAddressStructuredSummary,
    SearchAddressSummary: SearchAddressSummary,
    SearchAlongRouteRequestBody: SearchAlongRouteRequestBody,
    SearchAlongRouteResponse: SearchAlongRouteResponse,
    SearchAlongRouteResult: SearchAlongRouteResult,
    SearchAlongRouteSummary: SearchAlongRouteSummary,
    SearchFuzzyResponse: SearchFuzzyResponse,
    SearchFuzzyResult: SearchFuzzyResult,
    SearchFuzzySummary: SearchFuzzySummary,
    SearchGeometryResponse: SearchGeometryResponse,
    SearchGeometryResult: SearchGeometryResult,
    SearchGeometrySummary: SearchGeometrySummary,
    SearchInsideGeometryRequestBody: SearchInsideGeometryRequestBody,
    SearchNearbyResponse: SearchNearbyResponse,
    SearchNearbyResult: SearchNearbyResult,
    SearchNearbySummary: SearchNearbySummary,
    SearchPoiCategoryResponse: SearchPoiCategoryResponse,
    SearchPoiCategoryResult: SearchPoiCategoryResult,
    SearchPoiCategorySummary: SearchPoiCategorySummary,
    SearchPoiResponse: SearchPoiResponse,
    SearchPoiResult: SearchPoiResult,
    SearchPoiSummary: SearchPoiSummary,
    SearchPolygonResponse: SearchPolygonResponse,
    SearchPolygonResult: SearchPolygonResult,
    SearchPostSearchAddressBatchPreviewHeaders: SearchPostSearchAddressBatchPreviewHeaders,
    SearchPostSearchAddressReverseBatchPreviewHeaders: SearchPostSearchAddressReverseBatchPreviewHeaders,
    SearchPostSearchFuzzyBatchPreviewHeaders: SearchPostSearchFuzzyBatchPreviewHeaders,
    SearchResultAddress: SearchResultAddress,
    SearchResultAddressRanges: SearchResultAddressRanges,
    SearchResultEntryPoint: SearchResultEntryPoint,
    SearchResultPoi: SearchResultPoi,
    SearchResultPoiBrand: SearchResultPoiBrand,
    SearchResultPoiClassification: SearchResultPoiClassification,
    SearchResultPoiClassificationName: SearchResultPoiClassificationName,
    SearchResultViewport: SearchResultViewport,
    SearchSummaryGeoBias: SearchSummaryGeoBias
  }),
  accelerationEfficiency = {
    parameterPath: ["options", "accelerationEfficiency"],
    mapper: {
      serializedName: "accelerationEfficiency",
      type: {
        name: "Number"
      }
    }
  },
  acceptLanguage0 = {
    parameterPath: "acceptLanguage",
    mapper: {
      serializedName: "accept-language",
      defaultValue: "en-US",
      type: {
        name: "String"
      }
    }
  },
  acceptLanguage1 = {
    parameterPath: ["options", "acceptLanguage"],
    mapper: {
      serializedName: "Accept-Language",
      type: {
        name: "String"
      }
    }
  },
  agency = {
    parameterPath: ["options", "agency"],
    mapper: {
      serializedName: "agency",
      type: {
        name: "Sequence",
        element: {
          type: {
            name: "String"
          }
        }
      }
    },
    collectionFormat: msRest.QueryCollectionFormat.Csv
  },
  allowFreeformNewline = {
    parameterPath: ["options", "allowFreeformNewline"],
    mapper: {
      serializedName: "allowFreeformNewline",
      type: {
        name: "Boolean"
      }
    }
  },
  alternativeType = {
    parameterPath: ["options", "alternativeType"],
    mapper: {
      serializedName: "alternativeType",
      type: {
        name: "Enum",
        allowedValues: ["anyRoute", "betterRoute"]
      }
    }
  },
  apiVersion = {
    parameterPath: "apiVersion",
    mapper: {
      required: !0,
      serializedName: "api-version",
      type: {
        name: "String"
      }
    }
  },
  arriveAt = {
    parameterPath: ["options", "arriveAt"],
    mapper: {
      serializedName: "arriveAt",
      type: {
        name: "DateTime"
      }
    }
  },
  auxiliaryPowerInkW = {
    parameterPath: ["options", "auxiliaryPowerInkW"],
    mapper: {
      serializedName: "auxiliaryPowerInkW",
      type: {
        name: "String"
      }
    }
  },
  auxiliaryPowerInLitersPerHour = {
    parameterPath: ["options", "auxiliaryPowerInLitersPerHour"],
    mapper: {
      serializedName: "auxiliaryPowerInLitersPerHour",
      type: {
        name: "Number"
      }
    }
  },
  avoid = {
    parameterPath: ["options", "avoid"],
    mapper: {
      serializedName: "avoid",
      type: {
        name: "Sequence",
        element: {
          type: {
            name: "Enum",
            allowedValues: ["tollRoads", "motorways", "ferries", "unpavedRoads", "carpools", "alreadyUsedRoads", "borderCrossings"]
          }
        }
      }
    }
  },
  bbox = {
    parameterPath: ["options", "bbox"],
    mapper: {
      serializedName: "bbox",
      type: {
        name: "String"
      }
    }
  },
  brandSet = {
    parameterPath: ["options", "brandSet"],
    mapper: {
      serializedName: "brandSet",
      type: {
        name: "Sequence",
        element: {
          type: {
            name: "String"
          }
        }
      }
    },
    collectionFormat: msRest.QueryCollectionFormat.Csv
  },
  btmRight = {
    parameterPath: ["options", "btmRight"],
    mapper: {
      serializedName: "btmRight",
      type: {
        name: "String"
      }
    }
  },
  center = {
    parameterPath: ["options", "center"],
    mapper: {
      serializedName: "center",
      type: {
        name: "String"
      }
    }
  },
  computeBestOrder = {
    parameterPath: ["options", "computeBestOrder"],
    mapper: {
      serializedName: "computeBestOrder",
      type: {
        name: "Boolean"
      }
    }
  },
  computeTravelTimeFor = {
    parameterPath: ["options", "computeTravelTimeFor"],
    mapper: {
      serializedName: "computeTravelTimeFor",
      type: {
        name: "Enum",
        allowedValues: ["none", "all"]
      }
    }
  },
  connectorSet = {
    parameterPath: ["options", "connectorSet"],
    mapper: {
      serializedName: "connectorSet",
      type: {
        name: "Sequence",
        element: {
          type: {
            name: "Enum",
            allowedValues: ["StandardHouseholdCountrySpecific", "IEC62196Type1", "IEC62196Type1CCS", "IEC62196Type2CableAttached", "IEC62196Type2Outlet", "IEC62196Type2CCS", "IEC62196Type3", "Chademo", "IEC60309AC1PhaseBlue", "IEC60309DCWhite", "Tesla"]
          }
        }
      }
    },
    collectionFormat: msRest.QueryCollectionFormat.Csv
  },
  constantSpeedConsumptionInkWhPerHundredkm = {
    parameterPath: ["options", "constantSpeedConsumptionInkWhPerHundredkm"],
    mapper: {
      serializedName: "constantSpeedConsumptionInkWhPerHundredkm",
      type: {
        name: "String"
      }
    }
  },
  constantSpeedConsumptionInLitersPerHundredkm = {
    parameterPath: ["options", "constantSpeedConsumptionInLitersPerHundredkm"],
    mapper: {
      serializedName: "constantSpeedConsumptionInLitersPerHundredkm",
      type: {
        name: "Number"
      }
    }
  },
  countryCode = {
    parameterPath: "countryCode",
    mapper: {
      required: !0,
      serializedName: "countryCode",
      type: {
        name: "String"
      }
    }
  },
  countrySecondarySubdivision = {
    parameterPath: ["options", "countrySecondarySubdivision"],
    mapper: {
      serializedName: "countrySecondarySubdivision",
      type: {
        name: "String"
      }
    }
  },
  countrySet = {
    parameterPath: ["options", "countrySet"],
    mapper: {
      serializedName: "countrySet",
      type: {
        name: "Sequence",
        element: {
          type: {
            name: "String"
          }
        }
      }
    },
    collectionFormat: msRest.QueryCollectionFormat.Csv
  },
  countrySubdivision = {
    parameterPath: ["options", "countrySubdivision"],
    mapper: {
      serializedName: "countrySubdivision",
      type: {
        name: "String"
      }
    }
  },
  countryTertiarySubdivision = {
    parameterPath: ["options", "countryTertiarySubdivision"],
    mapper: {
      serializedName: "countryTertiarySubdivision",
      type: {
        name: "String"
      }
    }
  },
  crossStreet = {
    parameterPath: ["options", "crossStreet"],
    mapper: {
      serializedName: "crossStreet",
      type: {
        name: "String"
      }
    }
  },
  currentChargeInkWh = {
    parameterPath: ["options", "currentChargeInkWh"],
    mapper: {
      serializedName: "currentChargeInkWh",
      type: {
        name: "String"
      }
    }
  },
  currentFuelInLiters = {
    parameterPath: ["options", "currentFuelInLiters"],
    mapper: {
      serializedName: "currentFuelInLiters",
      type: {
        name: "Number"
      }
    }
  },
  decelerationEfficiency = {
    parameterPath: ["options", "decelerationEfficiency"],
    mapper: {
      serializedName: "decelerationEfficiency",
      type: {
        name: "Number"
      }
    }
  },
  departAt = {
    parameterPath: ["options", "departAt"],
    mapper: {
      serializedName: "departAt",
      type: {
        name: "DateTime"
      }
    }
  },
  detailType0 = {
    parameterPath: "detailType",
    mapper: {
      required: !0,
      serializedName: "detailType",
      type: {
        name: "Sequence",
        element: {
          type: {
            name: "Enum",
            allowedValues: ["agencies", "alerts", "alertDetails", "transitTypes"]
          }
        }
      }
    },
    collectionFormat: msRest.QueryCollectionFormat.Csv
  },
  detailType1 = {
    parameterPath: ["options", "detailType"],
    mapper: {
      serializedName: "detailType",
      type: {
        name: "Sequence",
        element: {
          type: {
            name: "Enum",
            allowedValues: ["alerts", "alertDetails", "lines", "stops", "schedule", "patterns"]
          }
        }
      }
    },
    collectionFormat: msRest.QueryCollectionFormat.Csv
  },
  detailType2 = {
    parameterPath: ["options", "detailType"],
    mapper: {
      serializedName: "detailType",
      type: {
        name: "Sequence",
        element: {
          type: {
            name: "Enum",
            allowedValues: ["alerts", "alertDetails", "lines", "lineGroups"]
          }
        }
      }
    },
    collectionFormat: msRest.QueryCollectionFormat.Csv
  },
  detailType3 = {
    parameterPath: ["options", "detailType"],
    mapper: {
      serializedName: "detailType",
      type: {
        name: "Sequence",
        element: {
          type: {
            name: "Enum",
            allowedValues: ["geometry", "schedule"]
          }
        }
      }
    },
    collectionFormat: msRest.QueryCollectionFormat.Csv
  },
  deviceId = {
    parameterPath: "deviceId",
    mapper: {
      required: !0,
      serializedName: "deviceId",
      type: {
        name: "String"
      }
    }
  },
  distances = {
    parameterPath: "distances",
    mapper: {
      required: !0,
      serializedName: "distances",
      type: {
        name: "String"
      }
    }
  },
  downhillEfficiency = {
    parameterPath: ["options", "downhillEfficiency"],
    mapper: {
      serializedName: "downhillEfficiency",
      type: {
        name: "Number"
      }
    }
  },
  energyBudgetInkWh = {
    parameterPath: ["options", "energyBudgetInkWh"],
    mapper: {
      serializedName: "energyBudgetInkWh",
      type: {
        name: "Number"
      }
    }
  },
  entityType = {
    parameterPath: ["options", "entityType"],
    mapper: {
      serializedName: "entityType",
      type: {
        name: "Enum",
        allowedValues: ["Country", "CountrySubdivision", "CountrySecondarySubdivision", "CountryTertiarySubdivision", "Municipality", "MunicipalitySubdivision", "Neighbourhood", "PostalCodeArea"]
      }
    }
  },
  extendedPostalCodesFor = {
    parameterPath: ["options", "extendedPostalCodesFor"],
    mapper: {
      serializedName: "extendedPostalCodesFor",
      type: {
        name: "String"
      }
    }
  },
  format0 = {
    parameterPath: "format",
    mapper: {
      required: !0,
      isConstant: !0,
      serializedName: "format",
      defaultValue: "json",
      type: {
        name: "String"
      }
    }
  },
  format1 = {
    parameterPath: "format",
    mapper: {
      required: !0,
      isConstant: !0,
      serializedName: "format",
      defaultValue: "png",
      type: {
        name: "String"
      }
    }
  },
  format2 = {
    parameterPath: "format",
    mapper: {
      required: !0,
      serializedName: "format",
      type: {
        name: "Enum",
        allowedValues: ["png", "pbf"]
      }
    }
  },
  fuelBudgetInLiters = {
    parameterPath: ["options", "fuelBudgetInLiters"],
    mapper: {
      serializedName: "fuelBudgetInLiters",
      type: {
        name: "Number"
      }
    }
  },
  fuelEnergyDensityInMJoulesPerLiter = {
    parameterPath: ["options", "fuelEnergyDensityInMJoulesPerLiter"],
    mapper: {
      serializedName: "fuelEnergyDensityInMJoulesPerLiter",
      type: {
        name: "Number"
      }
    }
  },
  geometries = {
    parameterPath: "geometries",
    mapper: {
      required: !0,
      serializedName: "geometries",
      type: {
        name: "String"
      }
    }
  },
  heading = {
    parameterPath: ["options", "heading"],
    mapper: {
      serializedName: "heading",
      constraints: {
        InclusiveMaximum: 360,
        InclusiveMinimum: -360
      },
      type: {
        name: "Number"
      }
    }
  },
  height = {
    parameterPath: ["options", "height"],
    mapper: {
      serializedName: "height",
      constraints: {
        InclusiveMaximum: 8192,
        InclusiveMinimum: 1
      },
      type: {
        name: "Number"
      }
    }
  },
  hilliness = {
    parameterPath: ["options", "hilliness"],
    mapper: {
      serializedName: "hilliness",
      type: {
        name: "Enum",
        allowedValues: ["low", "normal", "high"]
      }
    }
  },
  idxSet = {
    parameterPath: ["options", "idxSet"],
    mapper: {
      serializedName: "idxSet",
      type: {
        name: "Sequence",
        element: {
          type: {
            name: "Enum",
            allowedValues: ["Addr", "Geo", "PAD", "POI", "Str", "Xstr"]
          }
        }
      }
    },
    collectionFormat: msRest.QueryCollectionFormat.Csv
  },
  instructionsType = {
    parameterPath: ["options", "instructionsType"],
    mapper: {
      serializedName: "instructionsType",
      type: {
        name: "Enum",
        allowedValues: ["coded", "text", "tagged"]
      }
    }
  },
  isAsync = {
    parameterPath: ["options", "isAsync"],
    mapper: {
      serializedName: "isAsync",
      type: {
        name: "Boolean"
      }
    }
  },
  language = {
    parameterPath: ["options", "language"],
    mapper: {
      serializedName: "language",
      type: {
        name: "String"
      }
    }
  },
  lat0 = {
    parameterPath: ["options", "lat"],
    mapper: {
      serializedName: "lat",
      type: {
        name: "Number"
      }
    }
  },
  lat1 = {
    parameterPath: "lat",
    mapper: {
      required: !0,
      serializedName: "lat",
      type: {
        name: "Number"
      }
    }
  },
  layer0 = {
    parameterPath: ["options", "layer"],
    mapper: {
      serializedName: "layer",
      type: {
        name: "Enum",
        allowedValues: ["basic", "hybrid", "labels"]
      }
    }
  },
  layer1 = {
    parameterPath: "layer",
    mapper: {
      required: !0,
      serializedName: "layer",
      type: {
        name: "Enum",
        allowedValues: ["basic", "hybrid", "labels", "terra"]
      }
    }
  },
  limit0 = {
    parameterPath: ["options", "limit"],
    mapper: {
      serializedName: "limit",
      constraints: {
        InclusiveMaximum: 100,
        InclusiveMinimum: 1
      },
      type: {
        name: "Number"
      }
    }
  },
  limit1 = {
    parameterPath: ["options", "limit"],
    mapper: {
      serializedName: "limit",
      type: {
        name: "Number"
      }
    }
  },
  limit2 = {
    parameterPath: ["options", "limit"],
    mapper: {
      serializedName: "limit",
      constraints: {
        InclusiveMaximum: 20
      },
      type: {
        name: "Number"
      }
    }
  },
  lon0 = {
    parameterPath: ["options", "lon"],
    mapper: {
      serializedName: "lon",
      type: {
        name: "Number"
      }
    }
  },
  lon1 = {
    parameterPath: "lon",
    mapper: {
      required: !0,
      serializedName: "lon",
      type: {
        name: "Number"
      }
    }
  },
  maxAlternatives = {
    parameterPath: ["options", "maxAlternatives"],
    mapper: {
      serializedName: "maxAlternatives",
      constraints: {
        InclusiveMaximum: 5,
        InclusiveMinimum: 0
      },
      type: {
        name: "Number"
      }
    }
  },
  maxChargeInkWh = {
    parameterPath: ["options", "maxChargeInkWh"],
    mapper: {
      serializedName: "maxChargeInkWh",
      type: {
        name: "String"
      }
    }
  },
  maxcoordinates = {
    parameterPath: "maxcoordinates",
    mapper: {
      required: !0,
      serializedName: "maxcoordinates",
      type: {
        name: "String"
      }
    }
  },
  maxDetourTime = {
    parameterPath: "maxDetourTime",
    mapper: {
      required: !0,
      serializedName: "maxDetourTime",
      constraints: {
        InclusiveMaximum: 3600
      },
      type: {
        name: "Number"
      }
    }
  },
  maxFuzzyLevel = {
    parameterPath: ["options", "maxFuzzyLevel"],
    mapper: {
      serializedName: "maxFuzzyLevel",
      constraints: {
        InclusiveMaximum: 4,
        InclusiveMinimum: 1
      },
      type: {
        name: "Number"
      }
    }
  },
  mincoordinates = {
    parameterPath: "mincoordinates",
    mapper: {
      required: !0,
      serializedName: "mincoordinates",
      type: {
        name: "String"
      }
    }
  },
  minDeviationDistance = {
    parameterPath: ["options", "minDeviationDistance"],
    mapper: {
      serializedName: "minDeviationDistance",
      type: {
        name: "Number"
      }
    }
  },
  minDeviationTime = {
    parameterPath: ["options", "minDeviationTime"],
    mapper: {
      serializedName: "minDeviationTime",
      type: {
        name: "Number"
      }
    }
  },
  minFuzzyLevel = {
    parameterPath: ["options", "minFuzzyLevel"],
    mapper: {
      serializedName: "minFuzzyLevel",
      constraints: {
        InclusiveMaximum: 4,
        InclusiveMinimum: 1
      },
      type: {
        name: "Number"
      }
    }
  },
  mode = {
    parameterPath: ["options", "mode"],
    mapper: {
      serializedName: "mode",
      type: {
        name: "Enum",
        allowedValues: ["All", "EnterAndExit"]
      }
    }
  },
  modeType = {
    parameterPath: ["options", "modeType"],
    mapper: {
      serializedName: "modeType",
      type: {
        name: "Sequence",
        element: {
          type: {
            name: "Enum",
            allowedValues: ["walk", "bike", "publicTransit"]
          }
        }
      }
    },
    collectionFormat: msRest.QueryCollectionFormat.Csv
  },
  municipality = {
    parameterPath: ["options", "municipality"],
    mapper: {
      serializedName: "municipality",
      type: {
        name: "String"
      }
    }
  },
  municipalitySubdivision = {
    parameterPath: ["options", "municipalitySubdivision"],
    mapper: {
      serializedName: "municipalitySubdivision",
      type: {
        name: "String"
      }
    }
  },
  number = {
    parameterPath: ["options", "number"],
    mapper: {
      serializedName: "number",
      type: {
        name: "String"
      }
    }
  },
  numberOfClosestPoints = {
    parameterPath: ["options", "numberOfClosestPoints"],
    mapper: {
      serializedName: "numberOfClosestPoints",
      type: {
        name: "Number"
      }
    }
  },
  objectType = {
    parameterPath: ["options", "objectType"],
    mapper: {
      serializedName: "objectType",
      type: {
        name: "Sequence",
        element: {
          type: {
            name: "Enum",
            allowedValues: ["stop", "docklessBike", "docklessElectricBike", "docklessElectricScooter", "docklessScooter", "docklessMoped", "carShare", "docklessVehicle", "bikeDock"]
          }
        }
      }
    },
    collectionFormat: msRest.QueryCollectionFormat.Csv
  },
  ofs0 = {
    parameterPath: ["options", "ofs"],
    mapper: {
      serializedName: "ofs",
      constraints: {
        InclusiveMaximum: 1900,
        InclusiveMinimum: 0
      },
      type: {
        name: "Number"
      }
    }
  },
  ofs1 = {
    parameterPath: ["options", "ofs"],
    mapper: {
      serializedName: "ofs",
      constraints: {
        InclusiveMinimum: 0
      },
      type: {
        name: "Number"
      }
    }
  },
  ofs2 = {
    parameterPath: ["options", "ofs"],
    mapper: {
      serializedName: "ofs",
      type: {
        name: "Number"
      }
    }
  },
  openingHours = {
    parameterPath: ["options", "openingHours"],
    mapper: {
      serializedName: "openingHours",
      type: {
        name: "Enum",
        allowedValues: ["nextSevenDays"]
      }
    }
  },
  options = {
    parameterPath: ["options", "options"],
    mapper: {
      serializedName: "options",
      type: {
        name: "Enum",
        allowedValues: ["none", "zoneInfo", "transitions", "all"]
      }
    }
  },
  path = {
    parameterPath: ["options", "path"],
    mapper: {
      serializedName: "path",
      type: {
        name: "Sequence",
        element: {
          type: {
            name: "String"
          }
        }
      }
    },
    collectionFormat: msRest.QueryCollectionFormat.Multi
  },
  pins = {
    parameterPath: ["options", "pins"],
    mapper: {
      serializedName: "pins",
      type: {
        name: "Sequence",
        element: {
          type: {
            name: "String"
          }
        }
      }
    },
    collectionFormat: msRest.QueryCollectionFormat.Multi
  },
  postalCode = {
    parameterPath: ["options", "postalCode"],
    mapper: {
      serializedName: "postalCode",
      type: {
        name: "String"
      }
    }
  },
  query0 = {
    parameterPath: "query",
    mapper: {
      required: !0,
      serializedName: "query",
      type: {
        name: "String"
      }
    }
  },
  radius = {
    parameterPath: ["options", "radius"],
    mapper: {
      serializedName: "radius",
      type: {
        name: "Number"
      }
    }
  },
  report = {
    parameterPath: ["options", "report"],
    mapper: {
      serializedName: "report",
      type: {
        name: "String"
      }
    }
  },
  returnMatchType = {
    parameterPath: ["options", "returnMatchType"],
    mapper: {
      serializedName: "returnMatchType",
      type: {
        name: "Boolean"
      }
    }
  },
  returnRoadUse = {
    parameterPath: ["options", "returnRoadUse"],
    mapper: {
      serializedName: "returnRoadUse",
      type: {
        name: "Boolean"
      }
    }
  },
  returnSpeedLimit = {
    parameterPath: ["options", "returnSpeedLimit"],
    mapper: {
      serializedName: "returnSpeedLimit",
      type: {
        name: "Boolean"
      }
    }
  },
  roadUse = {
    parameterPath: ["options", "roadUse"],
    mapper: {
      serializedName: "roadUse",
      type: {
        name: "String"
      }
    }
  },
  routeRepresentation = {
    parameterPath: ["options", "routeRepresentation"],
    mapper: {
      serializedName: "routeRepresentation",
      type: {
        name: "Enum",
        allowedValues: ["polyline", "summaryOnly", "none"]
      }
    }
  },
  routeType0 = {
    parameterPath: ["options", "routeType"],
    mapper: {
      serializedName: "routeType",
      type: {
        name: "Enum",
        allowedValues: ["fastest", "shortest", "eco", "thrilling"]
      }
    }
  },
  searchBuffer = {
    parameterPath: ["options", "searchBuffer"],
    mapper: {
      serializedName: "searchBuffer",
      constraints: {
        InclusiveMaximum: 500,
        InclusiveMinimum: 0
      },
      type: {
        name: "Number"
      }
    }
  },
  sectionType = {
    parameterPath: ["options", "sectionType"],
    mapper: {
      serializedName: "sectionType",
      type: {
        name: "Enum",
        allowedValues: ["carTrain", "country", "ferry", "motorway", "pedestrian", "tollRoad", "tollVignette", "traffic", "travelMode", "tunnel"]
      }
    }
  },
  streetName = {
    parameterPath: ["options", "streetName"],
    mapper: {
      serializedName: "streetName",
      type: {
        name: "String"
      }
    }
  },
  streetNumber = {
    parameterPath: ["options", "streetNumber"],
    mapper: {
      serializedName: "streetNumber",
      type: {
        name: "String"
      }
    }
  },
  style0 = {
    parameterPath: ["options", "style"],
    mapper: {
      serializedName: "style",
      type: {
        name: "String"
      }
    }
  },
  style1 = {
    parameterPath: "style",
    mapper: {
      required: !0,
      serializedName: "style",
      type: {
        name: "Enum",
        allowedValues: ["main", "shaded_relief"]
      }
    }
  },
  style2 = {
    parameterPath: "style",
    mapper: {
      required: !0,
      isConstant: !0,
      serializedName: "style",
      defaultValue: "satellite",
      type: {
        name: "String"
      }
    }
  },
  territory = {
    parameterPath: ["options", "territory"],
    mapper: {
      serializedName: "territory",
      type: {
        name: "String"
      }
    }
  },
  text = {
    parameterPath: ["options", "text"],
    mapper: {
      serializedName: "text",
      type: {
        name: "String"
      }
    }
  },
  tileSize = {
    parameterPath: ["options", "tileSize"],
    mapper: {
      serializedName: "tileSize",
      type: {
        name: "Number"
      }
    }
  },
  timeBudgetInSec = {
    parameterPath: ["options", "timeBudgetInSec"],
    mapper: {
      serializedName: "timeBudgetInSec",
      type: {
        name: "Number"
      }
    }
  },
  timeStamp = {
    parameterPath: ["options", "timeStamp"],
    mapper: {
      serializedName: "timeStamp",
      type: {
        name: "DateTime"
      }
    }
  },
  topLeft = {
    parameterPath: ["options", "topLeft"],
    mapper: {
      serializedName: "topLeft",
      type: {
        name: "String"
      }
    }
  },
  traffic = {
    parameterPath: ["options", "traffic"],
    mapper: {
      serializedName: "traffic",
      type: {
        name: "Boolean"
      }
    }
  },
  transitionsFrom = {
    parameterPath: ["options", "transitionsFrom"],
    mapper: {
      serializedName: "transitionsFrom",
      type: {
        name: "DateTime"
      }
    }
  },
  transitionsYears = {
    parameterPath: ["options", "transitionsYears"],
    mapper: {
      serializedName: "transitionsYears",
      type: {
        name: "Number"
      }
    }
  },
  transitType = {
    parameterPath: ["options", "transitType"],
    mapper: {
      serializedName: "transitType",
      type: {
        name: "Sequence",
        element: {
          type: {
            name: "Enum",
            allowedValues: ["bus", "cableCar", "ferry", "funicular", "gondola", "rail", "tram", "subway"]
          }
        }
      }
    },
    collectionFormat: msRest.QueryCollectionFormat.Csv
  },
  travelMode = {
    parameterPath: ["options", "travelMode"],
    mapper: {
      serializedName: "travelMode",
      type: {
        name: "Enum",
        allowedValues: ["car", "truck", "taxi", "bus", "van", "motorcycle", "bicycle", "pedestrian"]
      }
    }
  },
  typeahead = {
    parameterPath: ["options", "typeahead"],
    mapper: {
      serializedName: "typeahead",
      type: {
        name: "Boolean"
      }
    }
  },
  udid = {
    parameterPath: "udid",
    mapper: {
      required: !0,
      serializedName: "udid",
      type: {
        name: "String"
      }
    }
  },
  udId = {
    parameterPath: "udId",
    mapper: {
      required: !0,
      serializedName: "udId",
      type: {
        name: "String"
      }
    }
  },
  uphillEfficiency = {
    parameterPath: ["options", "uphillEfficiency"],
    mapper: {
      serializedName: "uphillEfficiency",
      type: {
        name: "Number"
      }
    }
  },
  userTime = {
    parameterPath: ["options", "userTime"],
    mapper: {
      serializedName: "userTime",
      type: {
        name: "String"
      }
    }
  },
  vehicleAxleWeight = {
    parameterPath: ["options", "vehicleAxleWeight"],
    mapper: {
      serializedName: "vehicleAxleWeight",
      type: {
        name: "Number"
      }
    }
  },
  vehicleCommercial = {
    parameterPath: ["options", "vehicleCommercial"],
    mapper: {
      serializedName: "vehicleCommercial",
      type: {
        name: "Boolean"
      }
    }
  },
  vehicleEngineType = {
    parameterPath: ["options", "vehicleEngineType"],
    mapper: {
      serializedName: "vehicleEngineType",
      type: {
        name: "Enum",
        allowedValues: ["combustion", "electric"]
      }
    }
  },
  vehicleHeading = {
    parameterPath: ["options", "vehicleHeading"],
    mapper: {
      serializedName: "vehicleHeading",
      constraints: {
        InclusiveMaximum: 359,
        InclusiveMinimum: 0
      },
      type: {
        name: "Number"
      }
    }
  },
  vehicleHeight = {
    parameterPath: ["options", "vehicleHeight"],
    mapper: {
      serializedName: "vehicleHeight",
      type: {
        name: "Number"
      }
    }
  },
  vehicleLength = {
    parameterPath: ["options", "vehicleLength"],
    mapper: {
      serializedName: "vehicleLength",
      type: {
        name: "Number"
      }
    }
  },
  vehicleLoadType = {
    parameterPath: ["options", "vehicleLoadType"],
    mapper: {
      serializedName: "vehicleLoadType",
      type: {
        name: "Enum",
        allowedValues: ["USHazmatClass1", "USHazmatClass2", "USHazmatClass3", "USHazmatClass4", "USHazmatClass5", "USHazmatClass6", "USHazmatClass7", "USHazmatClass8", "USHazmatClass9", "otherHazmatExplosive", "otherHazmatGeneral", "otherHazmatHarmfulToWater"]
      }
    }
  },
  vehicleMaxSpeed = {
    parameterPath: ["options", "vehicleMaxSpeed"],
    mapper: {
      serializedName: "vehicleMaxSpeed",
      type: {
        name: "Number"
      }
    }
  },
  vehicleWeight = {
    parameterPath: ["options", "vehicleWeight"],
    mapper: {
      serializedName: "vehicleWeight",
      type: {
        name: "Number"
      }
    }
  },
  vehicleWidth = {
    parameterPath: ["options", "vehicleWidth"],
    mapper: {
      serializedName: "vehicleWidth",
      type: {
        name: "Number"
      }
    }
  },
  view = {
    parameterPath: ["options", "view"],
    mapper: {
      serializedName: "view",
      type: {
        name: "String"
      }
    }
  },
  waitForResults = {
    parameterPath: ["options", "waitForResults"],
    mapper: {
      serializedName: "waitForResults",
      type: {
        name: "Boolean"
      }
    }
  },
  width = {
    parameterPath: ["options", "width"],
    mapper: {
      serializedName: "width",
      constraints: {
        InclusiveMaximum: 8192,
        InclusiveMinimum: 1
      },
      type: {
        name: "Number"
      }
    }
  },
  windingness = {
    parameterPath: ["options", "windingness"],
    mapper: {
      serializedName: "windingness",
      type: {
        name: "Enum",
        allowedValues: ["low", "normal", "high"]
      }
    }
  },
  xTileIndex = {
    parameterPath: "xTileIndex",
    mapper: {
      required: !0,
      serializedName: "x",
      type: {
        name: "Number"
      }
    }
  },
  yTileIndex = {
    parameterPath: "yTileIndex",
    mapper: {
      required: !0,
      serializedName: "y",
      type: {
        name: "Number"
      }
    }
  },
  zoom0 = {
    parameterPath: ["options", "zoom"],
    mapper: {
      serializedName: "zoom",
      constraints: {
        InclusiveMaximum: 20,
        InclusiveMinimum: 0
      },
      type: {
        name: "Number"
      }
    }
  },
  zoom1 = {
    parameterPath: "zoom",
    mapper: {
      required: !0,
      serializedName: "zoom",
      type: {
        name: "Number"
      }
    }
  },
  Search = function () {
    function e(e) {
      this.client = e;
    }
    return e.prototype.getSearchPolygon = function (e, t, a) {
      return this.client.sendOperationRequest({
        geometries: e,
        options: t
      }, getSearchPolygonOperationSpec, a);
    }, e.prototype.getSearchFuzzy = function (e, t, a) {
      return this.client.sendOperationRequest({
        query: e,
        options: t
      }, getSearchFuzzyOperationSpec, a);
    }, e.prototype.getSearchPOI = function (e, t, a) {
      return this.client.sendOperationRequest({
        query: e,
        options: t
      }, getSearchPOIOperationSpec, a);
    }, e.prototype.getSearchNearby = function (e, t, a, r) {
      return this.client.sendOperationRequest({
        lat: e,
        lon: t,
        options: a
      }, getSearchNearbyOperationSpec, r);
    }, e.prototype.getSearchPOICategory = function (e, t, a) {
      return this.client.sendOperationRequest({
        query: e,
        options: t
      }, getSearchPOICategoryOperationSpec, a);
    }, e.prototype.getSearchAddress = function (e, t, a) {
      return this.client.sendOperationRequest({
        query: e,
        options: t
      }, getSearchAddressOperationSpec, a);
    }, e.prototype.getSearchAddressReverse = function (e, t, a) {
      return this.client.sendOperationRequest({
        query: e,
        options: t
      }, getSearchAddressReverseOperationSpec, a);
    }, e.prototype.getSearchAddressReverseCrossStreet = function (e, t, a) {
      return this.client.sendOperationRequest({
        query: e,
        options: t
      }, getSearchAddressReverseCrossStreetOperationSpec, a);
    }, e.prototype.getSearchAddressStructured = function (e, t, a) {
      return this.client.sendOperationRequest({
        countryCode: e,
        options: t
      }, getSearchAddressStructuredOperationSpec, a);
    }, e.prototype.postSearchInsideGeometry = function (e, t, a, r) {
      return this.client.sendOperationRequest({
        query: e,
        searchInsideGeometryRequestBody: t,
        options: a
      }, postSearchInsideGeometryOperationSpec, r);
    }, e.prototype.postSearchAlongRoute = function (e, t, a, r, i) {
      return this.client.sendOperationRequest({
        query: e,
        maxDetourTime: t,
        searchAlongRouteRequestBody: a,
        options: r
      }, postSearchAlongRouteOperationSpec, i);
    }, e.prototype.postSearchFuzzyBatchPreview = function (e, t) {
      return this.beginPostSearchFuzzyBatchPreview(e, t).then(function (e) {
        return e.pollUntilFinished();
      });
    }, e.prototype.postSearchAddressBatchPreview = function (e, t) {
      return this.beginPostSearchAddressBatchPreview(e, t).then(function (e) {
        return e.pollUntilFinished();
      });
    }, e.prototype.postSearchAddressReverseBatchPreview = function (e, t) {
      return this.beginPostSearchAddressReverseBatchPreview(e, t).then(function (e) {
        return e.pollUntilFinished();
      });
    }, e.prototype.beginPostSearchFuzzyBatchPreview = function (e, t) {
      return this.client.sendLRORequest({
        searchFuzzyBatchRequestBody: e,
        options: t
      }, beginPostSearchFuzzyBatchPreviewOperationSpec, t);
    }, e.prototype.beginPostSearchAddressBatchPreview = function (e, t) {
      return this.client.sendLRORequest({
        searchAddressBatchRequestBody: e,
        options: t
      }, beginPostSearchAddressBatchPreviewOperationSpec, t);
    }, e.prototype.beginPostSearchAddressReverseBatchPreview = function (e, t) {
      return this.client.sendLRORequest({
        searchAddressReverseBatchRequestBody: e,
        options: t
      }, beginPostSearchAddressReverseBatchPreviewOperationSpec, t);
    }, e;
  }(),
  serializer = new msRest.Serializer(Mappers),
  getSearchPolygonOperationSpec = {
    httpMethod: "GET",
    path: "search/polygon/{format}",
    urlParameters: [format0],
    queryParameters: [apiVersion, geometries],
    headerParameters: [acceptLanguage0],
    responses: {
      200: {
        bodyMapper: SearchPolygonResponse
      },
      default: {
        bodyMapper: CloudError
      }
    },
    serializer: serializer
  },
  getSearchFuzzyOperationSpec = {
    httpMethod: "GET",
    path: "search/fuzzy/{format}",
    urlParameters: [format0],
    queryParameters: [apiVersion, query0, typeahead, limit0, ofs0, countrySet, lat0, lon0, radius, topLeft, btmRight, language, extendedPostalCodesFor, minFuzzyLevel, maxFuzzyLevel, idxSet, brandSet, connectorSet, view, openingHours],
    headerParameters: [acceptLanguage0],
    responses: {
      200: {
        bodyMapper: SearchFuzzyResponse
      },
      default: {
        bodyMapper: CloudError
      }
    },
    serializer: serializer
  },
  getSearchPOIOperationSpec = {
    httpMethod: "GET",
    path: "search/poi/{format}",
    urlParameters: [format0],
    queryParameters: [apiVersion, query0, typeahead, limit0, ofs0, countrySet, lat0, lon0, radius, topLeft, btmRight, language, extendedPostalCodesFor, brandSet, connectorSet, view, openingHours],
    headerParameters: [acceptLanguage0],
    responses: {
      200: {
        bodyMapper: SearchPoiResponse
      },
      default: {
        bodyMapper: CloudError
      }
    },
    serializer: serializer
  },
  getSearchNearbyOperationSpec = {
    httpMethod: "GET",
    path: "search/nearby/{format}",
    urlParameters: [format0],
    queryParameters: [apiVersion, lat1, lon1, limit0, ofs0, countrySet, radius, language, extendedPostalCodesFor, brandSet, connectorSet, view],
    headerParameters: [acceptLanguage0],
    responses: {
      200: {
        bodyMapper: SearchNearbyResponse
      },
      default: {
        bodyMapper: CloudError
      }
    },
    serializer: serializer
  },
  getSearchPOICategoryOperationSpec = {
    httpMethod: "GET",
    path: "search/poi/category/{format}",
    urlParameters: [format0],
    queryParameters: [apiVersion, query0, typeahead, limit0, countrySet, lat0, lon0, radius, topLeft, btmRight, language, extendedPostalCodesFor, brandSet, connectorSet, view, openingHours],
    headerParameters: [acceptLanguage0],
    responses: {
      200: {
        bodyMapper: SearchPoiCategoryResponse
      },
      default: {
        bodyMapper: CloudError
      }
    },
    serializer: serializer
  },
  getSearchAddressOperationSpec = {
    httpMethod: "GET",
    path: "search/address/{format}",
    urlParameters: [format0],
    queryParameters: [apiVersion, query0, typeahead, limit0, ofs1, countrySet, lat0, lon0, radius, topLeft, btmRight, language, extendedPostalCodesFor, view],
    headerParameters: [acceptLanguage0],
    responses: {
      200: {
        bodyMapper: SearchAddressResponse
      },
      default: {
        bodyMapper: CloudError
      }
    },
    serializer: serializer
  },
  getSearchAddressReverseOperationSpec = {
    httpMethod: "GET",
    path: "search/address/reverse/{format}",
    urlParameters: [format0],
    queryParameters: [apiVersion, query0, language, returnSpeedLimit, heading, radius, number, returnRoadUse, roadUse, allowFreeformNewline, returnMatchType, entityType, view],
    headerParameters: [acceptLanguage0],
    responses: {
      200: {
        bodyMapper: SearchAddressReverseResponse
      },
      default: {
        bodyMapper: CloudError
      }
    },
    serializer: serializer
  },
  getSearchAddressReverseCrossStreetOperationSpec = {
    httpMethod: "GET",
    path: "search/address/reverse/crossStreet/{format}",
    urlParameters: [format0],
    queryParameters: [apiVersion, query0, heading, radius, language, view],
    headerParameters: [acceptLanguage0],
    responses: {
      200: {
        bodyMapper: SearchAddressReverseCrossStreetResponse
      },
      default: {
        bodyMapper: CloudError
      }
    },
    serializer: serializer
  },
  getSearchAddressStructuredOperationSpec = {
    httpMethod: "GET",
    path: "search/address/structured/{format}",
    urlParameters: [format0],
    queryParameters: [apiVersion, language, countryCode, limit1, ofs2, streetNumber, streetName, crossStreet, municipality, municipalitySubdivision, countryTertiarySubdivision, countrySecondarySubdivision, countrySubdivision, postalCode, extendedPostalCodesFor, view],
    headerParameters: [acceptLanguage0],
    responses: {
      200: {
        bodyMapper: SearchAddressStructuredResponse
      },
      default: {
        bodyMapper: CloudError
      }
    },
    serializer: serializer
  },
  postSearchInsideGeometryOperationSpec = {
    httpMethod: "POST",
    path: "search/geometry/{format}",
    urlParameters: [format0],
    queryParameters: [apiVersion, query0, limit0, language, extendedPostalCodesFor, idxSet, view, openingHours],
    headerParameters: [acceptLanguage0],
    requestBody: {
      parameterPath: "searchInsideGeometryRequestBody",
      mapper: __assign(__assign({}, SearchInsideGeometryRequestBody), {
        required: !0
      })
    },
    responses: {
      200: {
        bodyMapper: SearchGeometryResponse
      },
      default: {
        bodyMapper: CloudError
      }
    },
    serializer: serializer
  },
  postSearchAlongRouteOperationSpec = {
    httpMethod: "POST",
    path: "search/alongRoute/{format}",
    urlParameters: [format0],
    queryParameters: [apiVersion, query0, maxDetourTime, limit2, brandSet, connectorSet, view, openingHours],
    headerParameters: [acceptLanguage0],
    requestBody: {
      parameterPath: "searchAlongRouteRequestBody",
      mapper: __assign(__assign({}, SearchAlongRouteRequestBody), {
        required: !0
      })
    },
    responses: {
      200: {
        bodyMapper: SearchAlongRouteResponse
      },
      default: {
        bodyMapper: CloudError
      }
    },
    serializer: serializer
  },
  beginPostSearchFuzzyBatchPreviewOperationSpec = {
    httpMethod: "POST",
    path: "search/fuzzy/batch/{format}",
    urlParameters: [format0],
    queryParameters: [apiVersion],
    headerParameters: [acceptLanguage0],
    requestBody: {
      parameterPath: "searchFuzzyBatchRequestBody",
      mapper: __assign(__assign({}, BatchRequestBody), {
        required: !0
      })
    },
    responses: {
      200: {
        bodyMapper: BatchResponse,
        headersMapper: SearchPostSearchFuzzyBatchPreviewHeaders
      },
      202: {
        headersMapper: SearchPostSearchFuzzyBatchPreviewHeaders
      },
      default: {
        bodyMapper: CloudError
      }
    },
    serializer: serializer
  },
  beginPostSearchAddressBatchPreviewOperationSpec = {
    httpMethod: "POST",
    path: "search/address/batch/{format}",
    urlParameters: [format0],
    queryParameters: [apiVersion],
    headerParameters: [acceptLanguage0],
    requestBody: {
      parameterPath: "searchAddressBatchRequestBody",
      mapper: __assign(__assign({}, BatchRequestBody), {
        required: !0
      })
    },
    responses: {
      200: {
        bodyMapper: BatchResponse,
        headersMapper: SearchPostSearchAddressBatchPreviewHeaders
      },
      202: {
        headersMapper: SearchPostSearchAddressBatchPreviewHeaders
      },
      default: {
        bodyMapper: CloudError
      }
    },
    serializer: serializer
  },
  beginPostSearchAddressReverseBatchPreviewOperationSpec = {
    httpMethod: "POST",
    path: "search/address/reverse/batch/{format}",
    urlParameters: [format0],
    queryParameters: [apiVersion],
    headerParameters: [acceptLanguage0],
    requestBody: {
      parameterPath: "searchAddressReverseBatchRequestBody",
      mapper: __assign(__assign({}, BatchRequestBody), {
        required: !0
      })
    },
    responses: {
      200: {
        bodyMapper: BatchResponse,
        headersMapper: SearchPostSearchAddressReverseBatchPreviewHeaders
      },
      202: {
        headersMapper: SearchPostSearchAddressReverseBatchPreviewHeaders
      },
      default: {
        bodyMapper: CloudError
      }
    },
    serializer: serializer
  },
  Mappers$1 = Object.freeze({
    __proto__: null,
    discriminators: discriminators,
    BatchRequestBody: BatchRequestBody,
    BatchRequestBodyBatchItemsItem: BatchRequestBodyBatchItemsItem,
    BatchResponse: BatchResponse,
    BatchResponseSummary: BatchResponseSummary,
    CloudError: CloudError,
    Coordinate: Coordinate,
    GeoJSONGeometry: GeoJSONGeometry,
    GeoJSONGeometryCollection: GeoJSONGeometryCollection,
    LineString: LineString,
    MultiLineString: MultiLineString,
    MultiPoint: MultiPoint,
    MultiPolygon: MultiPolygon,
    Point: Point,
    Polygon: Polygon,
    RouteDirectionsRequestBody: RouteDirectionsRequestBody,
    RouteDirectionsRequestBodySupportingPoints: RouteDirectionsRequestBodySupportingPoints,
    RouteDirectionsResponse: RouteDirectionsResponse,
    RouteDirectionsResult: RouteDirectionsResult,
    RouteDirectionsSummary: RouteDirectionsSummary,
    RouteMatrixRequestBody: RouteMatrixRequestBody,
    RouteMatrixResponse: RouteMatrixResponse,
    RouteMatrixResult: RouteMatrixResult,
    RouteMatrixResultResponse: RouteMatrixResultResponse,
    RouteMatrixSummary: RouteMatrixSummary,
    RouteOptimizedWaypoint: RouteOptimizedWaypoint,
    RoutePostRouteDirectionsBatchPreviewHeaders: RoutePostRouteDirectionsBatchPreviewHeaders,
    RoutePostRouteMatrixPreviewHeaders: RoutePostRouteMatrixPreviewHeaders,
    RouteRange: RouteRange,
    RouteRangeResponse: RouteRangeResponse,
    RouteResponseReport: RouteResponseReport,
    RouteResponseReportEffectiveSetting: RouteResponseReportEffectiveSetting,
    RouteResultGuidance: RouteResultGuidance,
    RouteResultInstruction: RouteResultInstruction,
    RouteResultInstructionGroup: RouteResultInstructionGroup,
    RouteResultLeg: RouteResultLeg,
    RouteResultLegSummary: RouteResultLegSummary,
    RouteResultSection: RouteResultSection,
    RouteResultSectionTec: RouteResultSectionTec,
    RouteResultSectionTecCause: RouteResultSectionTecCause
  }),
  Route = function () {
    function e(e) {
      this.client = e;
    }
    return e.prototype.postRouteMatrixPreview = function (e, t) {
      return this.beginPostRouteMatrixPreview(e, t).then(function (e) {
        return e.pollUntilFinished();
      });
    }, e.prototype.getRouteDirections = function (e, t, a) {
      return this.client.sendOperationRequest({
        query: e,
        options: t
      }, getRouteDirectionsOperationSpec, a);
    }, e.prototype.postRouteDirections = function (e, t, a, r) {
      return this.client.sendOperationRequest({
        query: e,
        routeDirectionsRequestBody: t,
        options: a
      }, postRouteDirectionsOperationSpec, r);
    }, e.prototype.getRouteRange = function (e, t, a) {
      return this.client.sendOperationRequest({
        query: e,
        options: t
      }, getRouteRangeOperationSpec, a);
    }, e.prototype.postRouteDirectionsBatchPreview = function (e, t) {
      return this.beginPostRouteDirectionsBatchPreview(e, t).then(function (e) {
        return e.pollUntilFinished();
      });
    }, e.prototype.beginPostRouteMatrixPreview = function (e, t) {
      return this.client.sendLRORequest({
        routeMatrixBody: e,
        options: t
      }, beginPostRouteMatrixPreviewOperationSpec, t);
    }, e.prototype.beginPostRouteDirectionsBatchPreview = function (e, t) {
      return this.client.sendLRORequest({
        routeDirectionsBatchRequestBody: e,
        options: t
      }, beginPostRouteDirectionsBatchPreviewOperationSpec, t);
    }, e;
  }(),
  serializer$1 = new msRest.Serializer(Mappers$1),
  getRouteDirectionsOperationSpec = {
    httpMethod: "GET",
    path: "route/directions/{format}",
    urlParameters: [format0],
    queryParameters: [apiVersion, query0, maxAlternatives, alternativeType, minDeviationDistance, arriveAt, departAt, minDeviationTime, instructionsType, language, computeBestOrder, routeRepresentation, computeTravelTimeFor, vehicleHeading, report, sectionType, vehicleAxleWeight, vehicleWidth, vehicleHeight, vehicleLength, vehicleMaxSpeed, vehicleWeight, vehicleCommercial, windingness, hilliness, travelMode, avoid, traffic, routeType0, vehicleLoadType, vehicleEngineType, constantSpeedConsumptionInLitersPerHundredkm, currentFuelInLiters, auxiliaryPowerInLitersPerHour, fuelEnergyDensityInMJoulesPerLiter, accelerationEfficiency, decelerationEfficiency, uphillEfficiency, downhillEfficiency, constantSpeedConsumptionInkWhPerHundredkm, currentChargeInkWh, maxChargeInkWh, auxiliaryPowerInkW],
    headerParameters: [acceptLanguage0],
    responses: {
      200: {
        bodyMapper: RouteDirectionsResponse
      },
      default: {
        bodyMapper: CloudError
      }
    },
    serializer: serializer$1
  },
  postRouteDirectionsOperationSpec = {
    httpMethod: "POST",
    path: "route/directions/{format}",
    urlParameters: [format0],
    queryParameters: [apiVersion, query0, maxAlternatives, alternativeType, minDeviationDistance, minDeviationTime, instructionsType, language, computeBestOrder, routeRepresentation, computeTravelTimeFor, vehicleHeading, report, sectionType, arriveAt, departAt, vehicleAxleWeight, vehicleLength, vehicleHeight, vehicleWidth, vehicleMaxSpeed, vehicleWeight, vehicleCommercial, windingness, hilliness, travelMode, avoid, traffic, routeType0, vehicleLoadType, vehicleEngineType, constantSpeedConsumptionInLitersPerHundredkm, currentFuelInLiters, auxiliaryPowerInLitersPerHour, fuelEnergyDensityInMJoulesPerLiter, accelerationEfficiency, decelerationEfficiency, uphillEfficiency, downhillEfficiency, constantSpeedConsumptionInkWhPerHundredkm, currentChargeInkWh, maxChargeInkWh, auxiliaryPowerInkW],
    headerParameters: [acceptLanguage0],
    requestBody: {
      parameterPath: "routeDirectionsRequestBody",
      mapper: __assign(__assign({}, RouteDirectionsRequestBody), {
        required: !0
      })
    },
    responses: {
      200: {
        bodyMapper: RouteDirectionsResponse
      },
      default: {
        bodyMapper: CloudError
      }
    },
    serializer: serializer$1
  },
  getRouteRangeOperationSpec = {
    httpMethod: "GET",
    path: "route/range/{format}",
    urlParameters: [format0],
    queryParameters: [apiVersion, query0, fuelBudgetInLiters, energyBudgetInkWh, timeBudgetInSec, departAt, routeType0, traffic, avoid, travelMode, hilliness, windingness, vehicleAxleWeight, vehicleWidth, vehicleHeight, vehicleLength, vehicleMaxSpeed, vehicleWeight, vehicleCommercial, vehicleLoadType, vehicleEngineType, constantSpeedConsumptionInLitersPerHundredkm, currentFuelInLiters, auxiliaryPowerInLitersPerHour, fuelEnergyDensityInMJoulesPerLiter, accelerationEfficiency, decelerationEfficiency, uphillEfficiency, downhillEfficiency, constantSpeedConsumptionInkWhPerHundredkm, currentChargeInkWh, maxChargeInkWh, auxiliaryPowerInkW],
    headerParameters: [acceptLanguage0],
    responses: {
      200: {
        bodyMapper: RouteRangeResponse
      },
      default: {
        bodyMapper: CloudError
      }
    },
    serializer: serializer$1
  },
  beginPostRouteMatrixPreviewOperationSpec = {
    httpMethod: "POST",
    path: "route/matrix/{format}",
    urlParameters: [format0],
    queryParameters: [apiVersion, waitForResults, computeTravelTimeFor, sectionType, arriveAt, departAt, vehicleAxleWeight, vehicleLength, vehicleHeight, vehicleWidth, vehicleMaxSpeed, vehicleWeight, windingness, hilliness, travelMode, avoid, traffic, routeType0, vehicleLoadType],
    headerParameters: [acceptLanguage0],
    requestBody: {
      parameterPath: "routeMatrixBody",
      mapper: __assign(__assign({}, RouteMatrixRequestBody), {
        required: !0
      })
    },
    responses: {
      200: {
        bodyMapper: RouteMatrixResponse,
        headersMapper: RoutePostRouteMatrixPreviewHeaders
      },
      202: {
        headersMapper: RoutePostRouteMatrixPreviewHeaders
      },
      default: {
        bodyMapper: CloudError
      }
    },
    serializer: serializer$1
  },
  beginPostRouteDirectionsBatchPreviewOperationSpec = {
    httpMethod: "POST",
    path: "route/directions/batch/{format}",
    urlParameters: [format0],
    queryParameters: [apiVersion],
    headerParameters: [acceptLanguage0],
    requestBody: {
      parameterPath: "routeDirectionsBatchRequestBody",
      mapper: __assign(__assign({}, BatchRequestBody), {
        required: !0
      })
    },
    responses: {
      200: {
        bodyMapper: BatchResponse,
        headersMapper: RoutePostRouteDirectionsBatchPreviewHeaders
      },
      202: {
        headersMapper: RoutePostRouteDirectionsBatchPreviewHeaders
      },
      default: {
        bodyMapper: CloudError
      }
    },
    serializer: serializer$1
  },
  Mappers$2 = Object.freeze({
    __proto__: null,
    discriminators: discriminators,
    CloudError: CloudError,
    Country: Country,
    IanaId: IanaId,
    Names: Names,
    ReferenceTimeByCoordinates: ReferenceTimeByCoordinates,
    ReferenceTimeById: ReferenceTimeById,
    RepresentativePoint: RepresentativePoint,
    TimeTransition: TimeTransition,
    TimeZoneByCoordinates: TimeZoneByCoordinates,
    TimezoneByCoordinatesResult: TimezoneByCoordinatesResult,
    TimezoneById: TimezoneById,
    TimezoneByIdResult: TimezoneByIdResult,
    TimezoneEnumWindow: TimezoneEnumWindow,
    TimezoneIanaVersionResult: TimezoneIanaVersionResult
  }),
  Timezone = function () {
    function e(e) {
      this.client = e;
    }
    return e.prototype.getTimezoneByID = function (e, t, a) {
      return this.client.sendOperationRequest({
        query: e,
        options: t
      }, getTimezoneByIDOperationSpec, a);
    }, e.prototype.getTimezoneByCoordinates = function (e, t, a) {
      return this.client.sendOperationRequest({
        query: e,
        options: t
      }, getTimezoneByCoordinatesOperationSpec, a);
    }, e.prototype.getTimezoneEnumWindows = function (e, t) {
      return this.client.sendOperationRequest({
        options: e
      }, getTimezoneEnumWindowsOperationSpec, t);
    }, e.prototype.getTimezoneEnumIANA = function (e, t) {
      return this.client.sendOperationRequest({
        options: e
      }, getTimezoneEnumIANAOperationSpec, t);
    }, e.prototype.getTimezoneIANAVersion = function (e, t) {
      return this.client.sendOperationRequest({
        options: e
      }, getTimezoneIANAVersionOperationSpec, t);
    }, e.prototype.getTimezoneWindowsToIANA = function (e, t, a) {
      return this.client.sendOperationRequest({
        query: e,
        options: t
      }, getTimezoneWindowsToIANAOperationSpec, a);
    }, e;
  }(),
  serializer$2 = new msRest.Serializer(Mappers$2),
  getTimezoneByIDOperationSpec = {
    httpMethod: "GET",
    path: "timezone/byId/{format}",
    urlParameters: [format0],
    queryParameters: [apiVersion, options, timeStamp, transitionsFrom, transitionsYears, query0],
    headerParameters: [acceptLanguage1],
    responses: {
      200: {
        bodyMapper: TimezoneByIdResult
      },
      default: {
        bodyMapper: CloudError
      }
    },
    serializer: serializer$2
  },
  getTimezoneByCoordinatesOperationSpec = {
    httpMethod: "GET",
    path: "timezone/byCoordinates/{format}",
    urlParameters: [format0],
    queryParameters: [apiVersion, options, timeStamp, transitionsFrom, transitionsYears, query0],
    headerParameters: [acceptLanguage1],
    responses: {
      200: {
        bodyMapper: TimezoneByCoordinatesResult
      },
      default: {
        bodyMapper: CloudError
      }
    },
    serializer: serializer$2
  },
  getTimezoneEnumWindowsOperationSpec = {
    httpMethod: "GET",
    path: "timezone/enumWindows/{format}",
    urlParameters: [format0],
    queryParameters: [apiVersion],
    headerParameters: [acceptLanguage0],
    responses: {
      200: {
        bodyMapper: {
          serializedName: "parsedResponse",
          type: {
            name: "Sequence",
            element: {
              type: {
                name: "Composite",
                className: "TimezoneEnumWindow"
              }
            }
          }
        }
      },
      default: {
        bodyMapper: CloudError
      }
    },
    serializer: serializer$2
  },
  getTimezoneEnumIANAOperationSpec = {
    httpMethod: "GET",
    path: "timezone/enumIana/{format}",
    urlParameters: [format0],
    queryParameters: [apiVersion],
    headerParameters: [acceptLanguage0],
    responses: {
      200: {
        bodyMapper: {
          serializedName: "parsedResponse",
          type: {
            name: "Sequence",
            element: {
              type: {
                name: "Composite",
                className: "IanaId"
              }
            }
          }
        }
      },
      default: {
        bodyMapper: CloudError
      }
    },
    serializer: serializer$2
  },
  getTimezoneIANAVersionOperationSpec = {
    httpMethod: "GET",
    path: "timezone/ianaVersion/{format}",
    urlParameters: [format0],
    queryParameters: [apiVersion],
    headerParameters: [acceptLanguage0],
    responses: {
      200: {
        bodyMapper: TimezoneIanaVersionResult
      },
      default: {
        bodyMapper: CloudError
      }
    },
    serializer: serializer$2
  },
  getTimezoneWindowsToIANAOperationSpec = {
    httpMethod: "GET",
    path: "timezone/windowsToIana/{format}",
    urlParameters: [format0],
    queryParameters: [apiVersion, query0, territory],
    headerParameters: [acceptLanguage0],
    responses: {
      200: {
        bodyMapper: {
          serializedName: "parsedResponse",
          type: {
            name: "Sequence",
            element: {
              type: {
                name: "Composite",
                className: "IanaId"
              }
            }
          }
        }
      },
      default: {
        bodyMapper: CloudError
      }
    },
    serializer: serializer$2
  },
  Mappers$3 = Object.freeze({
    __proto__: null,
    discriminators: discriminators,
    CloudError: CloudError,
    CopyrightBoundingResult: CopyrightBoundingResult,
    CopyrightCaptionResult: CopyrightCaptionResult,
    CopyrightTileResult: CopyrightTileResult,
    CopyrightWorldResult: CopyrightWorldResult,
    Region: Region,
    RegionCountry: RegionCountry
  }),
  Render = function () {
    function e(e) {
      this.client = e;
    }
    return e.prototype.getMapImage = function (e, t) {
      return this.client.sendOperationRequest({
        options: e
      }, getMapImageOperationSpec, t);
    }, e.prototype.getMapTile = function (e, t, a, r, i, s, n, o) {
      return this.client.sendOperationRequest({
        format: e,
        layer: t,
        style: a,
        zoom: r,
        xTileIndex: i,
        yTileIndex: s,
        options: n
      }, getMapTileOperationSpec, o);
    }, e.prototype.getCopyrightCaption = function (e, t) {
      return this.client.sendOperationRequest({
        options: e
      }, getCopyrightCaptionOperationSpec, t);
    }, e.prototype.getMapImageryTile = function (e, t, a, r, i) {
      return this.client.sendOperationRequest({
        zoom: e,
        xTileIndex: t,
        yTileIndex: a,
        options: r
      }, getMapImageryTileOperationSpec, i);
    }, e.prototype.getCopyrightFromBoundingBox = function (e, t, a, r) {
      return this.client.sendOperationRequest({
        mincoordinates: e,
        maxcoordinates: t,
        options: a
      }, getCopyrightFromBoundingBoxOperationSpec, r);
    }, e.prototype.getCopyrightForTile = function (e, t, a, r, i) {
      return this.client.sendOperationRequest({
        zoom: e,
        xTileIndex: t,
        yTileIndex: a,
        options: r
      }, getCopyrightForTileOperationSpec, i);
    }, e.prototype.getCopyrightForWorld = function (e, t) {
      return this.client.sendOperationRequest({
        options: e
      }, getCopyrightForWorldOperationSpec, t);
    }, e;
  }(),
  serializer$3 = new msRest.Serializer(Mappers$3),
  getMapImageOperationSpec = {
    httpMethod: "GET",
    path: "map/static/{format}",
    urlParameters: [format1],
    queryParameters: [apiVersion, pins, path, layer0, style0, zoom0, center, bbox, height, width, language, view],
    headerParameters: [acceptLanguage0],
    responses: {
      200: {
        bodyMapper: {
          serializedName: "parsedResponse",
          type: {
            name: "Stream"
          }
        }
      },
      default: {
        bodyMapper: CloudError
      }
    },
    serializer: serializer$3
  },
  getMapTileOperationSpec = {
    httpMethod: "GET",
    path: "map/tile/{format}",
    urlParameters: [format2],
    queryParameters: [apiVersion, layer1, style1, zoom1, xTileIndex, yTileIndex, tileSize, language, view],
    headerParameters: [acceptLanguage0],
    responses: {
      200: {
        bodyMapper: {
          serializedName: "parsedResponse",
          type: {
            name: "Stream"
          }
        }
      },
      default: {
        bodyMapper: CloudError
      }
    },
    serializer: serializer$3
  },
  getCopyrightCaptionOperationSpec = {
    httpMethod: "GET",
    path: "map/copyright/caption/{format}",
    urlParameters: [format0],
    queryParameters: [apiVersion],
    headerParameters: [acceptLanguage0],
    responses: {
      200: {
        bodyMapper: CopyrightCaptionResult
      },
      default: {
        bodyMapper: CloudError
      }
    },
    serializer: serializer$3
  },
  getMapImageryTileOperationSpec = {
    httpMethod: "GET",
    path: "map/imagery/{format}",
    urlParameters: [format1],
    queryParameters: [apiVersion, style2, zoom1, xTileIndex, yTileIndex],
    headerParameters: [acceptLanguage0],
    responses: {
      200: {
        bodyMapper: {
          serializedName: "parsedResponse",
          type: {
            name: "Stream"
          }
        }
      },
      default: {
        bodyMapper: CloudError
      }
    },
    serializer: serializer$3
  },
  getCopyrightFromBoundingBoxOperationSpec = {
    httpMethod: "GET",
    path: "map/copyright/bounding/{format}",
    urlParameters: [format0],
    queryParameters: [apiVersion, mincoordinates, maxcoordinates, text],
    headerParameters: [acceptLanguage0],
    responses: {
      200: {
        bodyMapper: CopyrightBoundingResult
      },
      default: {
        bodyMapper: CloudError
      }
    },
    serializer: serializer$3
  },
  getCopyrightForTileOperationSpec = {
    httpMethod: "GET",
    path: "map/copyright/tile/{format}",
    urlParameters: [format0],
    queryParameters: [apiVersion, zoom1, xTileIndex, yTileIndex, text],
    headerParameters: [acceptLanguage0],
    responses: {
      200: {
        bodyMapper: CopyrightTileResult
      },
      default: {
        bodyMapper: CloudError
      }
    },
    serializer: serializer$3
  },
  getCopyrightForWorldOperationSpec = {
    httpMethod: "GET",
    path: "map/copyright/world/{format}",
    urlParameters: [format0],
    queryParameters: [apiVersion, text],
    headerParameters: [acceptLanguage0],
    responses: {
      200: {
        bodyMapper: CopyrightWorldResult
      },
      default: {
        bodyMapper: CloudError
      }
    },
    serializer: serializer$3
  },
  Mappers$4 = Object.freeze({
    __proto__: null,
    discriminators: discriminators,
    BufferRequestBody: BufferRequestBody,
    BufferResponse: BufferResponse,
    BufferResponseSummary: BufferResponseSummary,
    ClosestPointResultEntry: ClosestPointResultEntry,
    CloudError: CloudError,
    GeofenceGeometry: GeofenceGeometry,
    GeofenceResponse: GeofenceResponse,
    GeoJSONFeatureCollection: GeoJSONFeatureCollection,
    GeoJSONFeatureCollectionFeaturesItem: GeoJSONFeatureCollectionFeaturesItem,
    GeoJSONGeometry: GeoJSONGeometry,
    GetClosestPointResponse: GetClosestPointResponse,
    GetClosestPointSummary: GetClosestPointSummary,
    GetPointInPolygonResponse: GetPointInPolygonResponse,
    GetPointInPolygonSummary: GetPointInPolygonSummary,
    GreatCircleDistanceResponse: GreatCircleDistanceResponse,
    GreatCircleDistanceResponseResult: GreatCircleDistanceResponseResult,
    GreatCircleDistanceResponseSummary: GreatCircleDistanceResponseSummary,
    LineString: LineString,
    MultiLineString: MultiLineString,
    MultiPoint: MultiPoint,
    MultiPolygon: MultiPolygon,
    Point: Point,
    PointInPolygonResult: PointInPolygonResult,
    Polygon: Polygon,
    PostClosestPointResponse: PostClosestPointResponse,
    PostClosestPointSummary: PostClosestPointSummary,
    PostPointInPolygonResponse: PostPointInPolygonResponse,
    PostPointInPolygonSummary: PostPointInPolygonSummary,
    SpatialCoordinate: SpatialCoordinate,
    SpatialGetGeofenceHeaders: SpatialGetGeofenceHeaders,
    SpatialPostGeofenceHeaders: SpatialPostGeofenceHeaders
  }),
  Spatial = function () {
    function e(e) {
      this.client = e;
    }
    return e.prototype.getGeofence = function (e, t, a, r, i, s) {
      return this.client.sendOperationRequest({
        deviceId: e,
        udId: t,
        lat: a,
        lon: r,
        options: i
      }, getGeofenceOperationSpec, s);
    }, e.prototype.postGeofence = function (e, t, a, r, i, s) {
      return this.client.sendOperationRequest({
        deviceId: e,
        lat: t,
        lon: a,
        searchGeofenceRequestBody: r,
        options: i
      }, postGeofenceOperationSpec, s);
    }, e.prototype.postBuffer = function (e, t, a) {
      return this.client.sendOperationRequest({
        bufferRequestBody: e,
        options: t
      }, postBufferOperationSpec, a);
    }, e.prototype.getBuffer = function (e, t, a, r) {
      return this.client.sendOperationRequest({
        udid: e,
        distances: t,
        options: a
      }, getBufferOperationSpec, r);
    }, e.prototype.postClosestPoint = function (e, t, a, r, i) {
      return this.client.sendOperationRequest({
        lat: e,
        lon: t,
        closestPointRequestBody: a,
        options: r
      }, postClosestPointOperationSpec, i);
    }, e.prototype.getClosestPoint = function (e, t, a, r, i) {
      return this.client.sendOperationRequest({
        udid: e,
        lat: t,
        lon: a,
        options: r
      }, getClosestPointOperationSpec, i);
    }, e.prototype.postPointInPolygon = function (e, t, a, r, i) {
      return this.client.sendOperationRequest({
        lat: e,
        lon: t,
        pointInPolygonRequestBody: a,
        options: r
      }, postPointInPolygonOperationSpec, i);
    }, e.prototype.getPointInPolygon = function (e, t, a, r, i) {
      return this.client.sendOperationRequest({
        udid: e,
        lat: t,
        lon: a,
        options: r
      }, getPointInPolygonOperationSpec, i);
    }, e.prototype.getGreatCircleDistance = function (e, t, a) {
      return this.client.sendOperationRequest({
        query: e,
        options: t
      }, getGreatCircleDistanceOperationSpec, a);
    }, e;
  }(),
  serializer$4 = new msRest.Serializer(Mappers$4),
  getGeofenceOperationSpec = {
    httpMethod: "GET",
    path: "spatial/geofence/{format}",
    urlParameters: [format0],
    queryParameters: [apiVersion, deviceId, udId, lat1, lon1, userTime, searchBuffer, isAsync, mode],
    headerParameters: [acceptLanguage0],
    responses: {
      200: {
        bodyMapper: GeofenceResponse,
        headersMapper: SpatialGetGeofenceHeaders
      },
      default: {
        bodyMapper: CloudError
      }
    },
    serializer: serializer$4
  },
  postGeofenceOperationSpec = {
    httpMethod: "POST",
    path: "spatial/geofence/{format}",
    urlParameters: [format0],
    queryParameters: [apiVersion, deviceId, lat1, lon1, userTime, searchBuffer, isAsync, mode],
    headerParameters: [acceptLanguage0],
    requestBody: {
      parameterPath: "searchGeofenceRequestBody",
      mapper: __assign(__assign({}, GeoJSONFeatureCollection), {
        required: !0
      })
    },
    responses: {
      200: {
        bodyMapper: GeofenceResponse,
        headersMapper: SpatialPostGeofenceHeaders
      },
      default: {
        bodyMapper: CloudError
      }
    },
    serializer: serializer$4
  },
  postBufferOperationSpec = {
    httpMethod: "POST",
    path: "spatial/buffer/{format}",
    urlParameters: [format0],
    queryParameters: [apiVersion],
    headerParameters: [acceptLanguage0],
    requestBody: {
      parameterPath: "bufferRequestBody",
      mapper: __assign(__assign({}, BufferRequestBody), {
        required: !0
      })
    },
    responses: {
      200: {
        bodyMapper: BufferResponse
      },
      default: {
        bodyMapper: CloudError
      }
    },
    serializer: serializer$4
  },
  getBufferOperationSpec = {
    httpMethod: "GET",
    path: "spatial/buffer/{format}",
    urlParameters: [format0],
    queryParameters: [apiVersion, udid, distances],
    headerParameters: [acceptLanguage0],
    responses: {
      200: {
        bodyMapper: BufferResponse
      },
      default: {
        bodyMapper: CloudError
      }
    },
    serializer: serializer$4
  },
  postClosestPointOperationSpec = {
    httpMethod: "POST",
    path: "spatial/closestPoint/{format}",
    urlParameters: [format0],
    queryParameters: [apiVersion, lat1, lon1, numberOfClosestPoints],
    headerParameters: [acceptLanguage0],
    requestBody: {
      parameterPath: "closestPointRequestBody",
      mapper: __assign(__assign({}, GeoJSONFeatureCollection), {
        required: !0
      })
    },
    responses: {
      200: {
        bodyMapper: PostClosestPointResponse
      },
      default: {
        bodyMapper: CloudError
      }
    },
    serializer: serializer$4
  },
  getClosestPointOperationSpec = {
    httpMethod: "GET",
    path: "spatial/closestPoint/{format}",
    urlParameters: [format0],
    queryParameters: [apiVersion, udid, lat1, lon1, numberOfClosestPoints],
    headerParameters: [acceptLanguage0],
    responses: {
      200: {
        bodyMapper: GetClosestPointResponse
      },
      default: {
        bodyMapper: CloudError
      }
    },
    serializer: serializer$4
  },
  postPointInPolygonOperationSpec = {
    httpMethod: "POST",
    path: "spatial/pointInPolygon/{format}",
    urlParameters: [format0],
    queryParameters: [apiVersion, lat1, lon1],
    headerParameters: [acceptLanguage0],
    requestBody: {
      parameterPath: "pointInPolygonRequestBody",
      mapper: __assign(__assign({}, GeoJSONFeatureCollection), {
        required: !0
      })
    },
    responses: {
      200: {
        bodyMapper: PostPointInPolygonResponse
      },
      default: {
        bodyMapper: CloudError
      }
    },
    serializer: serializer$4
  },
  getPointInPolygonOperationSpec = {
    httpMethod: "GET",
    path: "spatial/pointInPolygon/{format}",
    urlParameters: [format0],
    queryParameters: [apiVersion, udid, lat1, lon1],
    headerParameters: [acceptLanguage0],
    responses: {
      200: {
        bodyMapper: GetPointInPolygonResponse
      },
      default: {
        bodyMapper: CloudError
      }
    },
    serializer: serializer$4
  },
  getGreatCircleDistanceOperationSpec = {
    httpMethod: "GET",
    path: "spatial/greatCircleDistance/{format}",
    urlParameters: [format0],
    queryParameters: [apiVersion, query0],
    headerParameters: [acceptLanguage0],
    responses: {
      200: {
        bodyMapper: GreatCircleDistanceResponse
      },
      default: {
        bodyMapper: CloudError
      }
    },
    serializer: serializer$4
  },
  version = "2.1.1",
  AgentPolicy = function (a) {
    function e(e, t) {
      return a.call(this, e, t) || this;
    }
    return __extends(e, a), e.prototype.sendRequest = function (t) {
      return __awaiter(this, void 0, void 0, function () {
        return __generator(this, function (e) {
          return t.headers || (t.headers = new msRest.HttpHeaders()), t.headers.set(HeaderConstants.MS_AM_REQUEST_ORIGIN, HeaderConstants.SERVICE_MODULE), t.headers.set(HeaderConstants.MAP_AGENT, HeaderConstants.SERVICE_MODULE + "/" + version + " (Web)"), [2, this._nextPolicy.sendRequest(t)];
        });
      });
    }, e;
  }(msRest.BaseRequestPolicy),
  AgentPolicyFactory = function () {
    function e() {}
    return e.prototype.create = function (e, t) {
      return new AgentPolicy(e, t);
    }, e;
  }(),
  packageName = "",
  packageVersion = "",
  MapsClientContext = function (i) {
    function e(e, t) {
      var a = this;
      if (null == e) throw new Error("'credentials' cannot be null.");
      if (!(t = t || {}).userAgent) {
        var r = msRestAzure.getDefaultUserAgentValue();
        t.userAgent = packageName + "/" + packageVersion + " " + r;
      }
      return (a = i.call(this, e, t) || this).apiVersion = "1.0", a.acceptLanguage = "en-US", a.longRunningOperationRetryTimeout = 30, a.baseUri = t.baseUri || a.baseUri || "https://atlas.microsoft.com", a.requestContentType = "application/json; charset=utf-8", a.credentials = e, null !== t.acceptLanguage && void 0 !== t.acceptLanguage && (a.acceptLanguage = t.acceptLanguage), null !== t.longRunningOperationRetryTimeout && void 0 !== t.longRunningOperationRetryTimeout && (a.longRunningOperationRetryTimeout = t.longRunningOperationRetryTimeout), a;
    }
    return __extends(e, i), e;
  }(msRestAzure.AzureServiceClient),
  MapsURL = function () {
    function e(e, t) {
      this.pipeline = e, /^\w+:\/\//.test(t) ? this.mapsUrl = t : this.mapsUrl = "https://" + t;
      var a = this.pipeline.toServiceClientOptions();
      a.requestPolicyFactories.push(new AgentPolicyFactory()), this.mapsClientContext = new MapsClientContext({
        signRequest: Promise.resolve
      }, __assign(__assign({}, a), {
        baseUri: this.mapsUrl
      })), this.mapsClientContext.apiVersion = SERVICE_VERSION, this.mapsClientContext.longRunningOperationRetryTimeout = 5;
    }
    return e.newPipeline = function (e, t) {
      void 0 === t && (t = {});
      var a = [msRest.deserializationPolicy(), new RetryPolicyFactory(t.retryOptions), new LoggingPolicyFactory(), e];
      return new Pipeline(a, {
        HTTPClient: t.httpClient,
        logger: t.logger
      });
    }, e;
  }(),
  RenderURL = function (r) {
    function e(e, t) {
      void 0 === t && (t = "https://atlas.microsoft.com");
      var a = r.call(this, e, t) || this;
      return a.renderContext = new Render(a.mapsClientContext), a;
    }
    return __extends(e, r), e.prototype.getMapImageryTile = function (o, m, l, p) {
      return __awaiter(this, void 0, void 0, function () {
        var t, a, r, i, s, n;
        return __generator(this, function (e) {
          switch (e.label) {
            case 0:
              return [4, this.renderContext.getMapImageryTile(m, l, p, {
                abortSignal: o
              })];
            case 1:
              return (t = e.sent()).readableStreamBody ? (r = (a = Object).assign, [4, streamToByteArray(t.readableStreamBody)]) : [3, 3];
            case 2:
              return [2, r.apply(a, [e.sent(), {
                rawResponse: t._response
              }])];
            case 3:
              return t.blobBody ? (s = (i = Object).assign, n = blobToByteArray, [4, t.blobBody]) : [3, 6];
            case 4:
              return [4, n.apply(void 0, [e.sent()])];
            case 5:
              return [2, s.apply(i, [e.sent(), {
                rawResponse: t._response
              }])];
            case 6:
              return [2];
          }
        });
      });
    }, e.prototype.getMapTile = function (o, m, l, p, u, d, y, c) {
      return void 0 === c && (c = {}), __awaiter(this, void 0, void 0, function () {
        var t, a, r, i, s, n;
        return __generator(this, function (e) {
          switch (e.label) {
            case 0:
              return [4, this.renderContext.getMapTile(m, l, p, u, d, y, __assign(__assign({}, c), {
                abortSignal: o
              }))];
            case 1:
              return (t = e.sent()).readableStreamBody ? (r = (a = Object).assign, [4, streamToByteArray(t.readableStreamBody)]) : [3, 3];
            case 2:
              return [2, r.apply(a, [e.sent(), {
                rawResponse: t._response
              }])];
            case 3:
              return t.blobBody ? (s = (i = Object).assign, n = blobToByteArray, [4, t.blobBody]) : [3, 6];
            case 4:
              return [4, n.apply(void 0, [e.sent()])];
            case 5:
              return [2, s.apply(i, [e.sent(), {
                rawResponse: t._response
              }])];
            case 6:
              return [2];
          }
        });
      });
    }, e.prototype.getMapImage = function (o, m) {
      return __awaiter(this, void 0, void 0, function () {
        var t, a, r, i, s, n;
        return __generator(this, function (e) {
          switch (e.label) {
            case 0:
              return [4, this.renderContext.getMapImage(__assign(__assign(__assign(__assign({}, m), m.center && {
                center: this.centerToString(m.center)
              }), m.bbox && {
                bbox: this.bboxToString(m.bbox)
              }), {
                abortSignal: o
              }))];
            case 1:
              return (t = e.sent()).readableStreamBody ? (r = (a = Object).assign, [4, streamToByteArray(t.readableStreamBody)]) : [3, 3];
            case 2:
              return [2, r.apply(a, [e.sent(), {
                rawResponse: t._response
              }])];
            case 3:
              return t.blobBody ? (s = (i = Object).assign, n = blobToByteArray, [4, t.blobBody]) : [3, 6];
            case 4:
              return [4, n.apply(void 0, [e.sent()])];
            case 5:
              return [2, s.apply(i, [e.sent(), {
                rawResponse: t._response
              }])];
            case 6:
              return [2];
          }
        });
      });
    }, e.prototype.centerToString = function (e) {
      if (e.length < 2) throw new Error("The center must contain both longitude and latitude, e.g. [longitude, latitude]");
      return e[0] + "," + e[1];
    }, e.prototype.bboxToString = function (e) {
      if (e.length < 4) throw new Error("The bbox must contain at least four values, e.g. [south lon, west lat, north lon, east lat]");
      return e.length < 6 ? e[0] + "," + e[1] + "," + e[2] + "," + e[3] : e[0] + "," + e[1] + "," + e[3] + "," + e[4];
    }, e;
  }(MapsURL),
  RouteGeojson = function () {
    function e(e) {
      this.response = e;
    }
    return e.prototype.getFeatures = function () {
      var e = this.response.routes.map(function (e, t) {
        var a = e.legs.map(function (e) {
            return e.points.map(function (e) {
              return [e.longitude, e.latitude];
            });
          }),
          r = __assign(__assign({}, e), {
            legSummaries: e.legs.map(function (e) {
              return e.summary;
            }),
            resultIndex: t
          });
        return delete r.legs, {
          type: "Feature",
          geometry: {
            type: "MultiLineString",
            coordinates: a
          },
          properties: r
        };
      });
      return {
        type: "FeatureCollection",
        features: e,
        bbox: calculateBoundingBox(e)
      };
    }, e;
  }(),
  RouteRangeGeojson = function () {
    function e(e) {
      this.response = e;
    }
    return e.prototype.getFeatures = function () {
      var e = {
        type: "Feature",
        geometry: {
          type: "Polygon",
          coordinates: [this.response.reachableRange.boundary.map(function (e) {
            return [e.longitude, e.latitude];
          })]
        },
        properties: {}
      };
      return {
        type: "FeatureCollection",
        features: [e, {
          type: "Feature",
          geometry: {
            type: "Point",
            coordinates: [this.response.reachableRange.center.longitude, this.response.reachableRange.center.latitude]
          },
          properties: {}
        }],
        bbox: calculateBoundingBox([e])
      };
    }, e;
  }(),
  RouteURL = function (r) {
    function e(e, t) {
      void 0 === t && (t = "https://atlas.microsoft.com");
      var a = r.call(this, e, t) || this;
      return a.routeContext = new Route(a.mapsClientContext), a;
    }
    return __extends(e, r), e.prototype.calculateRouteDirections = function (i, s, n) {
      return void 0 === n && (n = {}), __awaiter(this, void 0, void 0, function () {
        var t, a, r;
        return __generator(this, function (e) {
          switch (e.label) {
            case 0:
              if (s.length < 2) throw new Error("The coordinates must contain at least two positions");
              return t = s.map(function (e) {
                if (e.length < 2) throw new Error("The coordinate must contain both longitude and latitude, e.g. [longitude, latitude]");
                return e[1] + "," + e[0];
              }).join(":"), n.postBody ? [4, this.routeContext.postRouteDirections(t, n.postBody, __assign(__assign({}, n), {
                abortSignal: i
              }))] : [3, 2];
            case 1:
              return r = e.sent(), [3, 4];
            case 2:
              return [4, this.routeContext.getRouteDirections(t, __assign(__assign({}, n), {
                abortSignal: i
              }))];
            case 3:
              r = e.sent(), e.label = 4;
            case 4:
              return [2, __assign(__assign({}, a = r), {
                rawResponse: a._response,
                geojson: new RouteGeojson(a)
              })];
          }
        });
      });
    }, e.prototype.calculateRouteRange = function (r, i, s) {
      return void 0 === s && (s = {}), __awaiter(this, void 0, void 0, function () {
        var t, a;
        return __generator(this, function (e) {
          switch (e.label) {
            case 0:
              if (i.length < 2) throw new Error("The center must contain both longitude and latitude, e.g. [longitude, latitude]");
              return t = i[1] + "," + i[0], [4, this.routeContext.getRouteRange(t, __assign(__assign({}, s), {
                abortSignal: r
              }))];
            case 1:
              return a = e.sent(), [2, __assign(__assign({}, a), {
                rawResponse: a._response,
                geojson: new RouteRangeGeojson(a)
              })];
          }
        });
      });
    }, e.prototype.calculateRouteMatrix = function (a, r, i) {
      return void 0 === i && (i = {}), __awaiter(this, void 0, void 0, function () {
        var t;
        return __generator(this, function (e) {
          switch (e.label) {
            case 0:
              return [4, this.routeContext.postRouteMatrixPreview(r, __assign(__assign({}, i), {
                abortSignal: a
              }))];
            case 1:
              return t = e.sent(), [2, __assign(__assign({}, t), {
                rawResponse: t._response
              })];
          }
        });
      });
    }, e;
  }(MapsURL),
  SearchGeojson = function () {
    function e(e) {
      this.response = e;
    }
    return e.prototype.getFeatures = function () {
      for (var e = [], t = 0; t < this.response.results.length; t++) {
        var a = this.response.results[t],
          r = __assign(__assign({}, a), {
            resultIndex: t
          });
        delete r.position;
        var i = {
          type: "Feature",
          geometry: {
            type: "Point",
            coordinates: [a.position.lon, a.position.lat]
          },
          properties: r
        };
        a.id && (i.id = a.id), a.viewport && (i.bbox = [a.viewport.topLeftPoint.lon, a.viewport.btmRightPoint.lat, a.viewport.btmRightPoint.lon, a.viewport.topLeftPoint.lat]), e.push(i);
      }
      return {
        type: "FeatureCollection",
        features: e,
        bbox: calculateBoundingBox(e)
      };
    }, e;
  }(),
  SearchPolygonGeojson = function () {
    function e(e) {
      this.response = e;
    }
    return e.prototype.getFeatures = function () {
      for (var e = [], t = 0, a = this.response.additionalData; t < a.length; t++) for (var r = a[t], i = 0, s = r.geometryData.features; i < s.length; i++) {
        var n = s[i],
          o = __assign(__assign({}, n.properties), r);
        delete o.geometryData, e.push({
          type: "Feature",
          geometry: n.geometry,
          properties: o
        });
      }
      return {
        type: "FeatureCollection",
        features: e,
        bbox: calculateBoundingBox(e)
      };
    }, e;
  }(),
  SearchReverseGeojson = function () {
    function e(e) {
      this.response = e;
    }
    return e.prototype.getFeatures = function () {
      for (var e = [], t = 0; t < this.response.addresses.length; t++) {
        var a = this.response.addresses[t],
          r = __assign(__assign({}, a), {
            resultIndex: t
          });
        delete r.position;
        var i = a.position.split(",").map(Number.parseFloat),
          s = i[0],
          n = {
            type: "Feature",
            geometry: {
              type: "Point",
              coordinates: [i[1], s]
            },
            properties: r
          };
        e.push(n);
      }
      return {
        type: "FeatureCollection",
        features: e,
        bbox: calculateBoundingBox(e)
      };
    }, e;
  }(),
  SearchURL = function (r) {
    function e(e, t) {
      void 0 === t && (t = "https://atlas.microsoft.com");
      var a = r.call(this, e, t) || this;
      return a.searchContext = new Search(a.mapsClientContext), a;
    }
    return __extends(e, r), e.prototype.searchFuzzy = function (a, r, i) {
      return void 0 === i && (i = {}), __awaiter(this, void 0, void 0, function () {
        var t;
        return __generator(this, function (e) {
          switch (e.label) {
            case 0:
              if (Array.isArray(r)) {
                if (!(1 < r.length)) throw new Error("The query must contain both longitude and latitude, e.g. [longitude, latitude]");
                r = r[1] + "," + r[0];
              }
              return [4, this.searchContext.getSearchFuzzy(r, __assign(__assign({}, i), {
                abortSignal: a
              }))];
            case 1:
              return t = e.sent(), [2, __assign(__assign({}, t), {
                rawResponse: t._response,
                geojson: new SearchGeojson(t)
              })];
          }
        });
      });
    }, e.prototype.searchPOI = function (a, r, i) {
      return void 0 === i && (i = {}), __awaiter(this, void 0, void 0, function () {
        var t;
        return __generator(this, function (e) {
          switch (e.label) {
            case 0:
              return [4, this.searchContext.getSearchPOI(r, __assign(__assign({}, i), {
                abortSignal: a
              }))];
            case 1:
              return t = e.sent(), [2, __assign(__assign({}, t), {
                rawResponse: t._response,
                geojson: new SearchGeojson(t)
              })];
          }
        });
      });
    }, e.prototype.searchNearby = function (i, s, n) {
      return void 0 === n && (n = {}), __awaiter(this, void 0, void 0, function () {
        var t, a, r;
        return __generator(this, function (e) {
          switch (e.label) {
            case 0:
              if (s.length < 2) throw new Error("The query must contain both longitude and latitude, e.g. [longitude, latitude]");
              return t = s[0], a = s[1], [4, this.searchContext.getSearchNearby(a, t, __assign(__assign({}, n), {
                abortSignal: i
              }))];
            case 1:
              return r = e.sent(), [2, __assign(__assign({}, r), {
                rawResponse: r._response,
                geojson: new SearchGeojson(r)
              })];
          }
        });
      });
    }, e.prototype.searchPOICategory = function (a, r, i) {
      return void 0 === i && (i = {}), __awaiter(this, void 0, void 0, function () {
        var t;
        return __generator(this, function (e) {
          switch (e.label) {
            case 0:
              return [4, this.searchContext.getSearchPOICategory(r, __assign(__assign({}, i), {
                abortSignal: a
              }))];
            case 1:
              return t = e.sent(), [2, __assign(__assign({}, t), {
                rawResponse: t._response,
                geojson: new SearchGeojson(t)
              })];
          }
        });
      });
    }, e.prototype.searchAddress = function (a, r, i) {
      return void 0 === i && (i = {}), __awaiter(this, void 0, void 0, function () {
        var t;
        return __generator(this, function (e) {
          switch (e.label) {
            case 0:
              return [4, this.searchContext.getSearchAddress(r, __assign(__assign({}, i), {
                abortSignal: a
              }))];
            case 1:
              return t = e.sent(), [2, __assign(__assign({}, t), {
                rawResponse: t._response,
                geojson: new SearchGeojson(t)
              })];
          }
        });
      });
    }, e.prototype.searchAddressReverse = function (r, i, s) {
      return void 0 === s && (s = {}), __awaiter(this, void 0, void 0, function () {
        var t, a;
        return __generator(this, function (e) {
          switch (e.label) {
            case 0:
              if (i.length < 2) throw new Error("The position must contain both longitude and latitude, e.g. [longitude, latitude]");
              return t = i[1] + "," + i[0], [4, this.searchContext.getSearchAddressReverse(t, __assign(__assign({}, s), {
                abortSignal: r
              }))];
            case 1:
              return a = e.sent(), [2, __assign(__assign({}, a), {
                rawResponse: a._response,
                geojson: new SearchReverseGeojson(a)
              })];
          }
        });
      });
    }, e.prototype.searchAddressReverseCrossStreet = function (r, i, s) {
      return void 0 === s && (s = {}), __awaiter(this, void 0, void 0, function () {
        var t, a;
        return __generator(this, function (e) {
          switch (e.label) {
            case 0:
              if (i.length < 2) throw new Error("The position must contain both longitude and latitude, e.g. [longitude, latitude]");
              return t = i[1] + "," + i[0], [4, this.searchContext.getSearchAddressReverseCrossStreet(t, __assign(__assign({}, s), {
                abortSignal: r
              }))];
            case 1:
              return a = e.sent(), [2, __assign(__assign({}, a), {
                rawResponse: a._response,
                geojson: new SearchReverseGeojson(a)
              })];
          }
        });
      });
    }, e.prototype.searchAddressStructured = function (a, r, i) {
      return void 0 === i && (i = {}), __awaiter(this, void 0, void 0, function () {
        var t;
        return __generator(this, function (e) {
          switch (e.label) {
            case 0:
              return [4, this.searchContext.getSearchAddressStructured(r, __assign(__assign({}, i), {
                abortSignal: a
              }))];
            case 1:
              return t = e.sent(), [2, __assign(__assign({}, t), {
                rawResponse: t._response,
                geojson: new SearchGeojson(t)
              })];
          }
        });
      });
    }, e.prototype.searchInsideGeometry = function (a, r, i, s) {
      return void 0 === s && (s = {}), __awaiter(this, void 0, void 0, function () {
        var t;
        return __generator(this, function (e) {
          switch (e.label) {
            case 0:
              return [4, this.searchContext.postSearchInsideGeometry(r, i, __assign(__assign({}, s), {
                abortSignal: a
              }))];
            case 1:
              return t = e.sent(), [2, __assign(__assign({}, t), {
                rawResponse: t._response,
                geojson: new SearchGeojson(t)
              })];
          }
        });
      });
    }, e.prototype.searchAlongRoute = function (a, r, i, s, n) {
      return void 0 === n && (n = {}), __awaiter(this, void 0, void 0, function () {
        var t;
        return __generator(this, function (e) {
          switch (e.label) {
            case 0:
              return [4, this.searchContext.postSearchAlongRoute(r, i, s, __assign(__assign({}, n), {
                abortSignal: a
              }))];
            case 1:
              return t = e.sent(), [2, __assign(__assign({}, t), {
                rawResponse: t._response,
                geojson: new SearchGeojson(t)
              })];
          }
        });
      });
    }, e.prototype.searchPolygon = function (r, i) {
      return __awaiter(this, void 0, void 0, function () {
        var t, a;
        return __generator(this, function (e) {
          switch (e.label) {
            case 0:
              if (i.length < 1) throw new Error("At least one geometry UUID must be specified");
              return t = i.join(","), [4, this.searchContext.getSearchPolygon(t, {
                abortSignal: r
              })];
            case 1:
              return a = e.sent(), [2, __assign(__assign({}, a), {
                rawResponse: a._response,
                geojson: new SearchPolygonGeojson(a)
              })];
          }
        });
      });
    }, e;
  }(MapsURL),
  ClosestPointGeojson = function () {
    function e(e) {
      this.response = e;
    }
    return e.prototype.getFeatures = function () {
      var e = this.response.result.map(function (e) {
        var t = __assign({}, e);
        return delete t.position, {
          type: "Feature",
          geometry: {
            type: "Point",
            coordinates: [e.position.lon, e.position.lat]
          },
          properties: t
        };
      });
      return {
        type: "FeatureCollection",
        bbox: calculateBoundingBox(e),
        features: e
      };
    }, e;
  }(),
  GeofenceGeojson = function () {
    function e(e) {
      this.response = e;
    }
    return e.prototype.getFeatures = function () {
      var e = this.response.geometries.map(function (e) {
        var t = __assign({}, e);
        return delete t.nearestLat, delete t.nearestLon, {
          type: "Feature",
          geometry: {
            type: "Point",
            coordinates: [e.nearestLon, e.nearestLat]
          },
          properties: t
        };
      });
      return {
        type: "FeatureCollection",
        bbox: calculateBoundingBox(e),
        features: e
      };
    }, e;
  }(),
  PointInPolygonGeojson = function () {
    function e(e) {
      this.response = e;
    }
    return e.prototype.getFeatures = function () {
      var e = __assign(__assign({}, this.response.result), this.response.summary);
      delete e.sourcePoint;
      var t = [{
        type: "Feature",
        geometry: {
          type: "Point",
          coordinates: [this.response.summary.sourcePoint.lon, this.response.summary.sourcePoint.lat]
        },
        properties: e
      }];
      return {
        type: "FeatureCollection",
        bbox: calculateBoundingBox(t),
        features: t
      };
    }, e;
  }(),
  SpatialURL = function (r) {
    function e(e, t) {
      void 0 === t && (t = "https://atlas.microsoft.com");
      var a = r.call(this, e, t) || this;
      return a.spatialContext = new Spatial(a.mapsClientContext), a;
    }
    return __extends(e, r), e.prototype.getBuffer = function (i, s, n) {
      return __awaiter(this, void 0, void 0, function () {
        var t, a, r;
        return __generator(this, function (e) {
          switch (e.label) {
            case 0:
              if (n.length < 1) throw new Error("At least one distance must be specified");
              return "string" != typeof s ? [3, 2] : [4, this.spatialContext.getBuffer(s, n.join(";"), {
                abortSignal: i
              })];
            case 1:
              return a = e.sent(), [3, 4];
            case 2:
              return [4, this.spatialContext.postBuffer({
                geometries: s,
                distances: n
              }, {
                abortSignal: i
              })];
            case 3:
              a = e.sent(), e.label = 4;
            case 4:
              return (t = a).result && t.result.features && ((r = t.result).bbox = calculateBoundingBox(r.features)), [2, __assign(__assign({}, t), {
                rawResponse: t._response
              })];
          }
        });
      });
    }, e.prototype.getClosestPoint = function (s, n, o, m) {
      return void 0 === m && (m = {}), __awaiter(this, void 0, void 0, function () {
        var t, a, r, i;
        return __generator(this, function (e) {
          switch (e.label) {
            case 0:
              if (n.length < 2) throw new Error("The position must contain both longitude and latitude, e.g. [longitude, latitude]");
              return t = n[0], a = n[1], "string" != typeof o ? [3, 2] : [4, this.spatialContext.getClosestPoint(o, a, t, __assign(__assign({}, m), {
                abortSignal: s
              }))];
            case 1:
              return i = e.sent(), [3, 4];
            case 2:
              return [4, this.spatialContext.postClosestPoint(a, t, o, __assign(__assign({}, m), {
                abortSignal: s
              }))];
            case 3:
              i = e.sent(), e.label = 4;
            case 4:
              return [2, __assign(__assign({}, r = i), {
                rawResponse: r._response,
                geojson: new ClosestPointGeojson(r)
              })];
          }
        });
      });
    }, e.prototype.getGeofence = function (s, n, o, m, l) {
      return void 0 === l && (l = {}), __awaiter(this, void 0, void 0, function () {
        var t, a, r, i;
        return __generator(this, function (e) {
          switch (e.label) {
            case 0:
              if (m.length < 2) throw new Error("The position must contain both longitude and latitude, e.g. [longitude, latitude]");
              return t = m[0], a = m[1], "string" != typeof o ? [3, 2] : [4, this.spatialContext.getGeofence(n, o, a, t, __assign(__assign({}, l), {
                abortSignal: s
              }))];
            case 1:
              return i = e.sent(), [3, 4];
            case 2:
              return [4, this.spatialContext.postGeofence(n, a, t, o, __assign(__assign({}, l), {
                abortSignal: s
              }))];
            case 3:
              i = e.sent(), e.label = 4;
            case 4:
              return [2, __assign(__assign({}, r = i), {
                rawResponse: r._response,
                geojson: new GeofenceGeojson(r)
              })];
          }
        });
      });
    }, e.prototype.getGreatCircleDistance = function (r, i) {
      return __awaiter(this, void 0, void 0, function () {
        var t, a;
        return __generator(this, function (e) {
          switch (e.label) {
            case 0:
              if (i.length < 2) throw new Error("The coordinates must contain at least two positions");
              return t = i.map(function (e) {
                if (e.length < 2) throw new Error("The coordinate must contain both longitude and latitude, e.g. [longitude, latitude]");
                return e[1] + "," + e[0];
              }).join(":"), [4, this.spatialContext.getGreatCircleDistance(t, {
                abortSignal: r
              })];
            case 1:
              return a = e.sent(), [2, __assign(__assign({}, a), {
                rawResponse: a._response
              })];
          }
        });
      });
    }, e.prototype.getPointInPolygon = function (s, n, o) {
      return __awaiter(this, void 0, void 0, function () {
        var t, a, r, i;
        return __generator(this, function (e) {
          switch (e.label) {
            case 0:
              if (o.length < 2) throw new Error("The position must contain both longitude and latitude, e.g. [longitude, latitude]");
              return t = o[0], a = o[1], "string" != typeof n ? [3, 2] : [4, this.spatialContext.getPointInPolygon(n, a, t, {
                abortSignal: s
              })];
            case 1:
              return i = e.sent(), [3, 4];
            case 2:
              return [4, this.spatialContext.postPointInPolygon(a, t, n, {
                abortSignal: s
              })];
            case 3:
              i = e.sent(), e.label = 4;
            case 4:
              return [2, __assign(__assign({}, r = i), {
                rawResponse: r._response,
                geojson: new PointInPolygonGeojson(r)
              })];
          }
        });
      });
    }, e;
  }(MapsURL),
  TimezoneURL = function (r) {
    function e(e, t) {
      void 0 === t && (t = "https://atlas.microsoft.com");
      var a = r.call(this, e, t) || this;
      return a.timezoneContext = new Timezone(a.mapsClientContext), a;
    }
    return __extends(e, r), e.prototype.getTimezoneByCoordinates = function (r, i, s) {
      return void 0 === s && (s = {}), __awaiter(this, void 0, void 0, function () {
        var t, a;
        return __generator(this, function (e) {
          switch (e.label) {
            case 0:
              if (i.length < 2) throw new Error("The coordinate must contain both longitude and latitude, e.g. [longitude, latitude]");
              return t = i[1] + "," + i[0], [4, this.timezoneContext.getTimezoneByCoordinates(t, __assign(__assign({}, s), {
                abortSignal: r
              }))];
            case 1:
              return a = e.sent(), [2, __assign(__assign({}, a), {
                rawResponse: a._response
              })];
          }
        });
      });
    }, e.prototype.getTimezoneById = function (a, r, i) {
      return void 0 === i && (i = {}), __awaiter(this, void 0, void 0, function () {
        var t;
        return __generator(this, function (e) {
          switch (e.label) {
            case 0:
              return [4, this.timezoneContext.getTimezoneByID(r, __assign(__assign({}, i), {
                abortSignal: a
              }))];
            case 1:
              return t = e.sent(), [2, __assign(__assign({}, t), {
                rawResponse: t._response
              })];
          }
        });
      });
    }, e;
  }(MapsURL);
String.prototype.startsWith || Object.defineProperty(String.prototype, "startsWith", {
  value: function (e, t) {
    return this.substring(!t || t < 0 ? 0 : +t, e.length) === e;
  }
}), Object.defineProperty(exports, "BaseRequestPolicy", {
  enumerable: !0,
  get: function () {
    return msRest.BaseRequestPolicy;
  }
}), Object.defineProperty(exports, "HttpHeaders", {
  enumerable: !0,
  get: function () {
    return msRest.HttpHeaders;
  }
}), Object.defineProperty(exports, "HttpPipelineLogLevel", {
  enumerable: !0,
  get: function () {
    return msRest.HttpPipelineLogLevel;
  }
}), Object.defineProperty(exports, "RequestPolicyOptions", {
  enumerable: !0,
  get: function () {
    return msRest.RequestPolicyOptions;
  }
}), Object.defineProperty(exports, "RestError", {
  enumerable: !0,
  get: function () {
    return msRest.RestError;
  }
}), Object.defineProperty(exports, "WebResource", {
  enumerable: !0,
  get: function () {
    return msRest.WebResource;
  }
}), Object.defineProperty(exports, "deserializationPolicy", {
  enumerable: !0,
  get: function () {
    return msRest.deserializationPolicy;
  }
}), exports.Aborter = Aborter, exports.Credential = Credential, exports.CredentialPolicy = CredentialPolicy, exports.LoggingPolicyFactory = LoggingPolicyFactory, exports.MapControlCredential = MapControlCredential, exports.MapControlCredentialPolicy = MapControlCredentialPolicy, exports.MapsURL = MapsURL, exports.Models = index, exports.Pipeline = Pipeline, exports.RenderURL = RenderURL, exports.RetryPolicyFactory = RetryPolicyFactory, exports.RouteURL = RouteURL, exports.SasCredential = SasCredential, exports.SasCredentialPolicy = SasCredentialPolicy, exports.SearchURL = SearchURL, exports.SpatialURL = SpatialURL, exports.SubscriptionKeyCredential = SubscriptionKeyCredential, exports.SubscriptionKeyCredentialPolicy = SubscriptionKeyCredentialPolicy, exports.TimezoneURL = TimezoneURL, exports.TokenCredential = TokenCredential, exports.TokenCredentialPolicy = TokenCredentialPolicy;