<p-toast />
<div class="page-container project-container">
  <div class="header-container">
    <div style="display: flex; align-items: center">
      <p-multiSelect
        [options]="StatusList"
        optionLabel="label"
        placeholder="Search Status"
        appendTo="body"
        optionValue="label"
        [(ngModel)]="searchQuery"
        (ngModelChange)="handleStatusSearch($event)"
      />
    </div>

    <app-search-text
      (searchEvent)="searchSubject.next($event)"
      class="header"
    />
    <app-button label="Add" (clickEvent)="handleAddNew()" class="header" />
  </div>

  <p-table
    [columns]="cols"
    [value]="projectList"
    [rows]="pageSize"
    [lazy]="true"
    (onPage)="pageChange($event)"
    [first]="first"
    [paginator]="true"
    [totalRecords]="projectListCount"
    [showCurrentPageReport]="true"
  >
    <ng-template pTemplate="header" let-columns>
      <tr>
        <th *ngFor="let col of columns">{{ col.header }}</th>
      </tr>

      <ng-container *ngIf="isLoading">
        <tr *ngFor="let _ of skeletonRows">
          <th class="skeleton" *ngFor="let col of columns">
            <p-skeleton></p-skeleton>
          </th>
        </tr>
      </ng-container>
    </ng-template>

    <ng-template pTemplate="paginatorright">
      <p-select
        [options]="[
          { label: '10', value: 10 },
          { label: '20', value: 20 },
          { label: '50', value: 50 }
        ]"
        [(ngModel)]="pageSize"
        (onChange)="onPageSizeChange($event)"
      >
      </p-select>
    </ng-template>

    <ng-template pTemplate="body" let-rowData>
      <tr>
        <td class="project-name" (click)="projectDetails(rowData)">
          {{ rowData.name }}
        </td>
        <td>{{ rowData.companyName || rowData.customerName }}</td>
        <td>{{ rowData.billingAddress.freeformAddress }}</td>
        <td style="width: 15%">
          <div [ngClass]="getStatusClass(rowData.status)">
            {{ rowData.status }}
          </div>
        </td>
        <td (click)="op.toggle($event)">
          <img src="assets/ActionMenu.svg" style="cursor: pointer" />
          <p-popover #op>
            <ng-template pTemplate="content">
              <div
                (mouseover)="handleHover(1)"
                (click)="handleEdit(rowData)"
                class="list-item"
                [class.hover-bg-light-blue]="onHover === 1"
                (mouseleave)="onHover = 0"
              >
                <i class="pi pi-user-edit" style="font-size: 1.3rem"></i>
                <span style="font-size: 14px">Edit</span>
              </div>
              <div
                *ngIf="rowData.status === 'QUOTE'"
                (mouseover)="handleHover(2)"
                class="list-item"
                [class.hover-bg-light-green]="onHover === 2"
                (mouseleave)="onHover = 0"
              >
                <i class="pi pi-file" style="font-size: 1.3rem"></i>
                <span style="font-size: 14px">Send Quote</span>
              </div>
              <div
                *ngIf="rowData.status === 'INVOICE'"
                (mouseover)="handleHover(3)"
                class="list-item"
                [class.hover-bg-light-green]="onHover === 3"
                (mouseleave)="onHover = 0"
              >
                <i class="pi pi-receipt" style="font-size: 1.3rem"></i>
                <span style="font-size: 14px">Send Invoice</span>
              </div>
              <div
                (mouseover)="handleHover(4)"
                class="list-item"
                (click)="handleDelete(rowData)"
                [class.hover-bg-light-red]="onHover === 4"
                (mouseleave)="onHover = 0"
              >
                <i class="pi pi-trash" style="font-size: 1.3rem"></i>
                <span style="font-size: 14px">Delete</span>
              </div>
            </ng-template>
          </p-popover>
        </td>
      </tr>
    </ng-template>

    <ng-template pTemplate="emptymessage" *ngIf="projectList.length === 0">
      <tr *ngIf="!isLoading">
        <td colspan="6">No Data found.</td>
      </tr>
    </ng-template>
  </p-table>

  <p-dialog
    header="Header"
    [(visible)]="display"
    [modal]="true"
    [closable]="false"
    [style]="{ width: '40vw' }"
  >
    <ng-template pTemplate="header">
      <h3>New Project</h3>
    </ng-template>
    <ng-template pTemplate="content">
      <form class="dialog-form" [formGroup]="formData">
        <div class="project-type">
          <div *ngFor="let type of project_type">
            <p-radioButton
              [inputId]="type.label"
              [value]="type"
              formControlName="type"
              (onClick)="onProjectTypeChange(type)"
            />
            <label class="input-label" [for]="type.value" class="ml-2">
              {{ type.label }}
            </label>
          </div>
        </div>

        <app-input
          label="Project Name"
          [control]="formData.get('name')"
          [required]="true"
        />
        <div style="margin-bottom: 1rem">
          <label class="input-label">
            Notes
            <span class="required-label">*</span>
          </label>
          <textarea
            rows="2"
            cols="30"
            pInputTextarea
            formControlName="notes"
            class="textarea"
          ></textarea>
          <div
            class="error-message"
            *ngIf="
              formData.get('notes')?.invalid && formData.get('notes')?.touched
            "
          >
            Notes is required.
          </div>
        </div>
        <app-dropdown
          [options]="installersList"
          [control]="formData.get('installerId')"
          label="Assign Installer"
          [required]="true"
          placeholder="Select"
          optionLabel="name"
          optionValue="id"
        />
      </form>
      <h3 style="margin: 1rem 0">Customer</h3>
      <form
        class="dialog-form"
        [formGroup]="commercialFormData"
        *ngIf="formData.get('type')?.value.value === 0"
      >
        <div class="grid-container">
          <app-number-input
            [control]="commercialFormData.get('abn')"
            inputId="withoutgrouping"
            mode="decimal"
            [useGrouping]="false"
            label="ABN"
            (keyup.enter)="onEnter($event)"
            [required]="true"
          />
          <div>
            <label class="input-label">
              Billing Address
              <span class="required-label">*</span>
            </label>
            <p-autoComplete
              formControlName="billingAddress"
              [suggestions]="filteredAddress"
              optionLabel="address.freeformAddress"
              (completeMethod)="onSearchAddress($event)"
              [appendTo]="'body'"
            />

            <div
              class="error-message"
              *ngIf="
                commercialFormData.get('billingAddress')?.invalid &&
                commercialFormData.get('billingAddress')?.touched
              "
            >
              Address is required.
            </div>
          </div>
          <app-disabled-input
            label="Company Name"
            [control]="commercialFormData.get('companyName')"
            [required]="true"
          />
          <app-input
            label="Project Contact"
            [control]="commercialFormData.get('projectContact')"
            [required]="true"
          />
          <app-input
            label="Email"
            [control]="commercialFormData.get('companyEmail')"
            [required]="true"
            errormsg="Enter a valid Email"
          />
          <app-input
            label="Phone No"
            [control]="commercialFormData.get('companyPhone')"
            [required]="true"
            errormsg="Phone number must be 10 digits"
          />
        </div>
      </form>
      <form
        class="dialog-form"
        [formGroup]="residentialFormData"
        *ngIf="formData.get('type')?.value.value === 1"
      >
        <div class="grid-container">
          <div>
            <label class="input-label">Billing Address</label>
            <p-autoComplete
              formControlName="billingAddress"
              [suggestions]="filteredAddress"
              optionLabel="address.freeformAddress"
              (completeMethod)="onSearchAddress($event)"
              [appendTo]="'body'"
            />

            <div
              class="error-message"
              *ngIf="
                residentialFormData.get('billingAddress')?.invalid &&
                residentialFormData.get('billingAddress')?.touched
              "
            >
              Address is required.
            </div>
          </div>
          <app-input
            label="Customer Name"
            [control]="residentialFormData.get('customerName')"
            [required]="true"
          />
          <app-input
            label="Project Contact"
            [control]="residentialFormData.get('projectContact')"
            [required]="true"
          />
          <app-input
            label="Email"
            [control]="residentialFormData.get('customerEmail')"
            [required]="true"
            errormsg="Entre a valid Email"
          />
          <app-input
            label="Phone No"
            [control]="residentialFormData.get('customerPhone')"
            [required]="true"
            errormsg="Phone number must be 10 digits"
          />
        </div>
      </form>
    </ng-template>
    <ng-template pTemplate="footer">
      <app-button
        label="Cancel"
        (clickEvent)="closeDialog()"
        className="cancel-dialog"
        style="margin-right: 1rem"
      />
      <app-button
        [label]="editProject ? 'Update' : 'Save'"
        (clickEvent)="editProject ? onUpdate() : onSave()"
        [disabled]="buttonDisabled"
        [icon]="onSaveLoad ? 'pi pi-spin pi-spinner' : ''"
      />
    </ng-template>
  </p-dialog>

  <p-dialog
    header="Header"
    [(visible)]="deleteDialog"
    [modal]="true"
    [closable]="false"
    [style]="{ width: '30vw' }"
  >
    <ng-template pTemplate="header"> </ng-template>
    <ng-template pTemplate="content">
      <h1>Are you sure you want to delete this Project ?</h1>
    </ng-template>
    <ng-template pTemplate="footer">
      <app-button
        label="Cancel"
        (clickEvent)="closeDialog()"
        className="cancel-dialog"
        style="margin-right: 1rem"
      />
      <app-button label="Delete" (clickEvent)="onDeleteProject()" />
    </ng-template>
  </p-dialog>
</div>
