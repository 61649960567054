import { Component } from '@angular/core';
import { DropDownType, TableCols } from '../../types';
import { SharedModule } from '../../shared.module';
import { MessageService } from 'primeng/api';
import { SearchFieldComponent } from '../../Components/searchField/search-text.component';
import { debounceTime, Subject } from 'rxjs';
import {
  CERTIFICATE_VALUE,
  SUBMISSION_REGULATOR_COLS,
  SUBMITTED_BUNDLE_COLS,
} from '../../constants';
import { ButtonComponent } from '../../Components/button/button.component';
import { AuditService } from '../audit/audit.service';
import { NumberInputComponent } from '../../Components/number-input/number-input.component';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DropdownComponent } from '../../Components/dropdown/dropdown.component';
import { SubmissionRegulatorService } from './submissions-to-regulator';
import { unzip } from 'fflate';
import { environment } from '../../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Component({
  selector: 'app-submissions-to-regulator',
  imports: [
    SharedModule,
    SearchFieldComponent,
    ButtonComponent,
    NumberInputComponent,
    DropdownComponent,
  ],
  providers: [MessageService],
  templateUrl: './submissions-to-regulator.component.html',
  styleUrl: './submissions-to-regulator.component.scss',
})
export class SubmissionsToRegulatorComponent {
  cols: TableCols[] = SUBMISSION_REGULATOR_COLS;
  submissionsList: any[] = [];
  isLoading: boolean = true;
  skeletonRows = new Array(10);
  onHover: number = 0;
  searchSubject: Subject<string> = new Subject<string>();
  CertificateList: DropDownType[] = CERTIFICATE_VALUE;
  first = 0;
  pageNumber: number = 0;
  searchQuery: string[] | null = [];
  pageSize: number = 10;
  searchText: string = '';
  bundleListCount: number = 0;
  StatusList: any[] = [{ label: 'AUDITPASS' }, { label: 'SUBMITTED' }];
  formData!: FormGroup;
  onSaveLoad: boolean = false;
  bundleCol: TableCols[] = SUBMITTED_BUNDLE_COLS;
  submittedBundleList: any[] = [];

  constructor(
    private messageService: MessageService,
    private service: AuditService,
    private regulatorService: SubmissionRegulatorService,
    private formBuilder: FormBuilder,
    private http: HttpClient
  ) {
    this.setupSearch();
    this.initializeForms();
  }

  async ngOnInit() {
    await this.getSubmissionBundle(0, 10, '', ['AUDITPASS', 'SUBMITTED']);
    await this.fetchSubmittedBundle();
  }

  private setupSearch() {
    this.searchSubject.pipe(debounceTime(500)).subscribe((value: string) => {
      this.searchText = value;
      this.pageNumber = 0;
      this.first = 0;
      this.handleSearch(this.pageNumber, this.pageSize, value);
    });
  }

  async handleSearch(
    pageNo: number,
    pageSize: number,
    val: string
  ): Promise<void> {
    try {
      if (val !== '') {
        await this.getSubmissionBundle(pageNo, pageSize, val, [
          'AUDITPASS',
          'SUBMITTED',
        ]);
      } else {
        await this.getSubmissionBundle(pageNo, pageSize, '', [
          'AUDITPASS',
          'SUBMITTED',
        ]);
      }
    } catch (error: any) {
      this.errorToast(error.message);
    }
  }

  handleHover = (index: number) => {
    this.onHover = index;
  };

  initializeForms(): void {
    this.formData = this.formBuilder.group({
      value: ['', Validators.required],
      certificate: ['', Validators.required],
    });
  }

  errorToast(detail: string): void {
    this.messageService.add({
      severity: 'error',
      summary: 'Error',
      detail: detail,
    });
  }

  successToast(detail: string): void {
    this.messageService.add({
      severity: 'success',
      summary: 'Success',
      detail: detail,
    });
  }

  getStatusClass(status: string): string {
    switch (status) {
      case 'AUDITPASS':
        return 'tag-auditpass';
      case 'SUBMITTED':
        return 'tag-submitted';
      case 'COMPLETED':
        return 'tag-submitted';
      default:
        return '';
    }
  }

  async handleStatusSearch(searchVal: any): Promise<void> {
    if (searchVal?.length > 0) {
      await this.getSubmissionBundle(
        this.pageNumber,
        this.pageSize,
        this.searchText,
        searchVal
      );
    } else {
      await this.getSubmissionBundle(
        this.pageNumber,
        this.pageSize,
        this.searchText,
        ['AUDITPASS', 'SUBMITTED']
      );
    }
  }

  async getSubmissionBundle(
    pageNo: number,
    pageSize: number,
    search: string,
    searchStatus: string[] | null
  ): Promise<void> {
    try {
      const res = await this.service.fetchBundleActivities(
        pageNo,
        pageSize,
        search,
        searchStatus
      );
      if (res) {
        this.submissionsList = res.data;
        this.bundleListCount = res.totalCount;
        this.isLoading = false;
      }
      return res;
    } catch (error: any) {
      this.isLoading = false;
    }
  }

  async pageChange(event: any): Promise<void> {
    this.pageNumber = event.first / event.rows;
    this.first = event.first;
    if (this.searchQuery?.length !== 0) {
      await this.getSubmissionBundle(
        this.pageNumber,
        this.pageSize,
        this.searchText,
        ['AUDITPASS', 'SUBMITTED']
      );
    } else {
      await this.getSubmissionBundle(
        this.pageNumber,
        this.pageSize,
        this.searchText,
        ['AUDITPASS', 'SUBMITTED']
      );
    }
  }

  async onPageSizeChange(event: any) {
    this.pageSize = event.value;
    if (this.searchQuery?.length !== 0) {
      await this.getSubmissionBundle(
        this.pageNumber,
        event.value,
        this.searchText,
        ['AUDITPASS', 'SUBMITTED']
      );
    } else {
      await this.getSubmissionBundle(
        this.pageNumber,
        event.value,
        this.searchText,
        ['AUDITPASS', 'SUBMITTED']
      );
    }
  }

  async onSubmitToRegulator(): Promise<void> {
    this.onSaveLoad = true;
    try {
      const data = await this.regulatorService.onSubmitToRegulator(
        this.formData.value
      );
      if (data) {
        await this.getSubmissionBundle(
          this.pageNumber,
          this.pageSize,
          this.searchText,
          ['AUDITPASS', 'SUBMITTED']
        );
        this.onSaveLoad = false;
        await this.fetchSubmittedBundle();
        this.successToast(data.message);
      }
      return data;
    } catch (error: any) {
      this.onSaveLoad = false;
      this.errorToast(error.message);
    }
  }

  async fetchSubmittedBundle(): Promise<void> {
    try {
      const data = await this.regulatorService.fetchSubmittedBundle();
      if (data) {
        this.submittedBundleList = data;
      }
    } catch (error: any) {
      this.errorToast(error.message);
    }
  }

  async onDownloadForm(data: any): Promise<void> {
    await this.fetchForms(data);
  }

  private async fetchForms(data: any): Promise<any> {
    const headers = new HttpHeaders({
      Accept: 'application/zip',
    });
    const payload = { ImagePaths: [data] };
    try {
      const response: any = await this.http
        .post(`${environment.baseUrl}/api/images/fetch`, payload, {
          headers,
          responseType: 'arraybuffer',
        })
        .toPromise();

      const files: Record<string, Uint8Array> = await new Promise(
        (resolve, reject) => {
          unzip(new Uint8Array(response), (err, unzippedFiles) => {
            if (err) {
              reject(err);
            } else {
              resolve(unzippedFiles);
            }
          });
        }
      );

      const imageUrls = Object.keys(files).map((filename) => {
        const blob = new Blob([files[filename]]);
        const url = URL.createObjectURL(blob);
        this.downloadFile(url, filename);
        return url;
      });
      return imageUrls;
    } catch (error: any) {
      this.isLoading = false;
      this.errorToast(error.message);
    }
  }

  private downloadFile(url: string, filename: string): void {
    const a = document.createElement('a');
    a.href = url;
    a.download = filename;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);

    URL.revokeObjectURL(url);
  }
}
