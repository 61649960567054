<div class="page-container profile-container">
  <p-toast />
  <div style="padding-top: 2rem">
    <p-tabs [value]="0">
      <p-tablist>
        <p-tab [value]="0">My Profile</p-tab>
        <p-tab [value]="1">Company Profile</p-tab>
      </p-tablist>
      <p-tabpanel [value]="0">
        <form [formGroup]="profileData" class="profile">
          <div class="image-container">
            <div class="profile-picture-container" (click)="profileImg.click()">
              <img
                class="profile-image"
                [src]="profileImage || 'assets/Profile Icon 1.svg'"
              />
              <input
                type="file"
                #profileImg
                (change)="onProfileImage($event)"
                accept="image/*"
                style="display: none"
              />
              <img
                src="../../../assets/profileEdit.icon.svg"
                class="edit-icon"
              />
            </div>
          </div>
          <div class="form-container">
            <app-input
              label="Name"
              [control]="profileData.get('name')"
              [required]="true"
            />
          </div>
        </form>

        <div class="profile-footer">
          <app-button
            label="Save"
            [disabled]="profileData.invalid"
            (clickEvent)="onProfileSave()"
            [icon]="onSaveLoad ? 'pi pi-spin pi-spinner' : ''"
          />
        </div>
      </p-tabpanel>
      <p-tabpanel [value]="1">
        <form class="company-container" [formGroup]="companyData">
          <div class="image-container">
            <div class="company-picture-container" (click)="companyImg.click()">
              <img
                class="company-image"
                [src]="companyImage || 'assets/Profile Icon 1.svg'"
              />
              <input
                type="file"
                #companyImg
                (change)="onCompanyImage($event)"
                accept="image/*"
                style="display: none"
              />
              <img src="assets/profileEdit.icon.svg" class="edit-icon" />
            </div>
          </div>
          <div class="form-container">
            <app-input
              label="ABN"
              [control]="companyData.get('abn')"
              [required]="true"
              (keyup.enter)="onEnter($event)"
            />
            <app-disabled-input
              label="Company Name"
              [control]="companyData.get('name')"
              [required]="true"
            />
            <div style="padding-bottom: 1rem">
              <label class="input-label">
                Address
                <span class="required-label">*</span>
              </label>
              <p-autoComplete
                formControlName="area"
                [suggestions]="filteredAddress"
                optionLabel="address.freeformAddress"
                (completeMethod)="onSearchAddress($event)"
                [appendTo]="'body'"
              />

              <div
                class="error-message"
                *ngIf="
                  companyData.get('area')?.invalid &&
                  companyData.get('area')?.touched
                "
              >
                Address is required.
              </div>
            </div>
            <div style="min-width: 100%">
              <label class="input-label">Activities</label>
              <p-multiSelect
                [options]="allActivities"
                formControlName="activities"
                optionLabel="schemeAndActivity"
                placeholder="Select"
                appendTo="body"
                optionValue="schemeActivityId"
              />
            </div>
            <app-input
              label="Contact Name"
              [control]="companyData.get('contactName')"
              [required]="true"
            />
            <app-input
              label="Contact Email"
              [control]="companyData.get('contactEmail')"
              [required]="true"
            />
            <app-input
              label="Contact Phone"
              [control]="companyData.get('contactPhone')"
              [required]="true"
            />
          </div>
        </form>

        <div class="profile-footer">
          <app-button
            label="Update"
            [icon]="onSaveLoad ? 'pi pi-spin pi-spinner' : ''"
            [disabled]="companyData.invalid"
            (clickEvent)="onCompanySave()"
          />
          <app-button
            label="Cancel"
            className="cancel-dialog"
            style="margin-right: 1rem"
            (clickEvent)="onCancel()"
          />
        </div>

        <div style="margin-bottom: 2rem">
          <!-- <div style="display: flex; justify-content: end; margin-bottom: 1rem">
            <app-search-text (searchEvent)="handleSearch($event)" />
          </div> -->
          <p-table [columns]="activitiesCols" [value]="selectedRows">
            <ng-template pTemplate="header" let-columns>
              <tr>
                <!-- <th style="width: 3rem">
                  <p-tableHeaderCheckbox />
                </th> -->
                <th *ngFor="let col of columns">{{ col.header }}</th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-rowData let-columns="columns">
              <tr [pEditableRow]="rowData">
                <!-- <td>
                  <p-tableCheckbox [value]="rowData" />
                </td> -->
                <td>{{ rowData.schemeAndActivity }}</td>
                <td>{{ rowData.registrationCode || "-" }}</td>
                <td
                  [pEditableColumn]="rowData.accreditationNumber"
                  style="width: 25%"
                >
                  <p-cellEditor
                    *ngIf="isRowEditable(rowData) && rowData.registrationCode"
                    style="font-size: 0.9rem"
                  >
                    <ng-template pTemplate="input">
                      <input
                        (blur)="addAccreditationNo(rowData)"
                        [(ngModel)]="rowData.accreditationNumber"
                      />
                    </ng-template>
                    <ng-template pTemplate="output">
                      <div>
                        <img
                          style="margin-right: 0.5rem"
                          src="assets/edit.icon.svg"
                        />
                        {{ rowData.accreditationNumber || "Select" }}
                      </div>
                    </ng-template>
                  </p-cellEditor>
                </td>
              </tr>
            </ng-template>

            <ng-template pTemplate="emptymessage" *ngIf="userList.length === 0">
              <tr>
                <td colspan="5">No Data found.</td>
              </tr>
            </ng-template>
          </p-table>
        </div>
        <div>
          <form class="user-container" [formGroup]="userData">
            <app-input placeholder="Name" [control]="userData.get('name')" />
            <app-input placeholder="Email" [control]="userData.get('email')" />
            <app-button
              *ngIf="!editUser"
              label="Invite"
              (clickEvent)="onInviteUser()"
              [disabled]="userData.invalid"
              style="margin-top: 0.3rem"
            />
            <app-button
              *ngIf="editUser"
              label="Edit"
              (clickEvent)="onUpdateUser()"
              [disabled]="userData.invalid"
              style="margin-top: 0.3rem"
            />
          </form>

          <p-table [columns]="userCols" [value]="userList">
            <ng-template pTemplate="header" let-columns>
              <tr>
                <th *ngFor="let col of columns">{{ col.header }}</th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-rowData let-columns="columns">
              <tr>
                <td>{{ rowData.name }}</td>
                <td>
                  <div class="email-container">
                    <img src="assets/email.icon.svg" />
                    <div style="font-size: 0.9rem">{{ rowData.email }}</div>
                  </div>
                </td>
                <td>
                  <p-tag
                    [value]="rowData.status"
                    [severity]="statusTag(rowData.status)"
                  />
                </td>
                <td (click)="op.toggle($event)">
                  <img src="assets/ActionMenu.svg" />
                  <p-popover #op>
                    <ng-template pTemplate="content">
                      <div
                        (mouseover)="handleHover(1)"
                        class="list-item"
                        (click)="quoteUser(rowData)"
                        [class.hover-bg-light-blue]="onHover === 1"
                        (mouseleave)="onHover = 0"
                      >
                        <i
                          class="pi pi-user-edit"
                          style="font-size: 1.3rem"
                        ></i>
                        <span style="font-size: 14px">Edit</span>
                      </div>
                      <div
                        (mouseover)="handleHover(2)"
                        class="list-item"
                        [class.hover-bg-light-red]="onHover === 2"
                        (mouseleave)="onHover = 0"
                      >
                        <i class="pi pi-trash" style="font-size: 1.3rem"></i>
                        <span style="font-size: 14px">Deactive</span>
                      </div>
                    </ng-template>
                  </p-popover>
                </td>
              </tr>
            </ng-template>

            <ng-template pTemplate="emptymessage" *ngIf="userList.length === 0">
              <tr>
                <td colspan="5">No Data found.</td>
              </tr>
            </ng-template>
          </p-table>
        </div>
      </p-tabpanel>
    </p-tabs>
  </div>
</div>
