<div class="page-container activity-container">
  <div class="header-container">
    <div style="display: flex; align-items: center">
      <p-multiSelect
        [options]="StatusList"
        optionLabel="label"
        placeholder="Search Status"
        appendTo="body"
        optionValue="label"
        [(ngModel)]="searchQuery"
        (ngModelChange)="handleStatusSearch($event)"
      />
    </div>

    <app-search-text
      (searchEvent)="searchSubject.next($event)"
      class="header"
    />
  </div>
  <div>
    <p-table
      [columns]="BundleCols"
      [value]="activitiesList"
      dataKey="id"
      [rows]="pageSize"
      [lazy]="true"
      (onPage)="pageChange($event)"
      [first]="first"
      [paginator]="true"
      [totalRecords]="bundleListCount"
      [showCurrentPageReport]="true"
    >
      <ng-template pTemplate="header" let-columns>
        <tr>
          <th *ngFor="let col of columns">{{ col.header }}</th>
        </tr>

        <ng-container *ngIf="isLoading">
          <tr *ngFor="let _ of skeletonRows">
            <th class="skeleton" *ngFor="let col of columns">
              <p-skeleton></p-skeleton>
            </th>
          </tr>
        </ng-container>
      </ng-template>

      <ng-template pTemplate="paginatorright">
        <p-select
          [options]="[
            { label: '10', value: 10 },
            { label: '20', value: 20 },
            { label: '50', value: 50 }
          ]"
          [(ngModel)]="pageSize"
          (onChange)="onPageSizeChange($event)"
        >
        </p-select>
      </ng-template>

      <ng-template pTemplate="body" let-rowData>
        <tr>
          <td class="activity-name" (click)="submissionDetails(rowData)">
            {{ rowData.installerName }}
          </td>
          <td>
            <img src="assets/CalendarIcon.svg" style="margin-right: 5px" />
            {{ rowData.createdAt | date : "yyyy-MM-dd hh:mm:ss" }}
          </td>
          <td style="width: 13%">
            <div [ngClass]="getStatusClass(rowData.status)">
              {{ rowData.status }}
            </div>
          </td>
        </tr>
      </ng-template>

      <ng-template pTemplate="emptymessage" *ngIf="activitiesList.length === 0">
        <tr *ngIf="!isLoading">
          <td colspan="5">No Data found.</td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</div>
