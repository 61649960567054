import { trigger, transition, style, animate } from '@angular/animations';
import * as i1 from '@angular/common';
import { CommonModule } from '@angular/common';
import * as i0 from '@angular/core';
import { Injectable, EventEmitter, inject, booleanAttribute, Component, ChangeDetectionStrategy, ViewEncapsulation, Input, Output, ViewChild, ContentChild, ContentChildren, HostListener, NgModule } from '@angular/core';
import { blockBodyScroll, focus, addClass, appendChild, unblockBodyScroll } from '@primeuix/utils';
import { PrimeTemplate, SharedModule } from 'primeng/api';
import { BaseComponent } from 'primeng/basecomponent';
import { FocusTrap } from 'primeng/focustrap';
import { RefreshIcon, EyeIcon, UndoIcon, SearchMinusIcon, SearchPlusIcon, TimesIcon } from 'primeng/icons';
import { ZIndexUtils } from 'primeng/utils';
import { BaseStyle } from 'primeng/base';
const theme = ({
  dt
}) => `
.p-image-mask {
    display: flex;
    align-items: center;
    justify-content: center;
}

.p-image-preview {
    position: relative;
    display: inline-flex;
    line-height: 0;
}

.p-image-preview-mask {
    position: absolute;
    inset-inline-start: 0;
    inset-block-start: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    transition: opacity 0.3s;
    border: 0 none;
    padding: 0;
    cursor: pointer;
    background: transparent;
    color: ${dt('image.preview.mask.color')};
    transition: background ${dt('image.transition.duration')};
}

.p-image-preview:hover > .p-image-preview-mask {
    opacity: 1;
    cursor: pointer;
    background: ${dt('image.preview.mask.background')};
}

.p-image-preview-icon {
    font-size: ${dt('image.preview.icon.size')};
    width: ${dt('image.preview.icon.size')};
    height: ${dt('image.preview.icon.size')};
}

.p-image-toolbar {
    position: absolute;
    inset-block-start: ${dt('image.toolbar.position.top')};
    inset-inline-end: ${dt('image.toolbar.position.right')};
    inset-inline-start: ${dt('image.toolbar.position.left')};
    inset-block-end: ${dt('image.toolbar.position.bottom')};
    display: flex;
    z-index: 1;
    padding: ${dt('image.toolbar.padding')};
    background: ${dt('image.toolbar.background')};
    backdrop-filter: blur(${dt('image.toolbar.blur')});
    border-color: ${dt('image.toolbar.border.color')};
    border-style: solid;
    border-width: ${dt('image.toolbar.border.width')};
    border-radius: ${dt('image.toolbar.border.radius')};
    gap: ${dt('image.toolbar.gap')};
}

.p-image-action {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    color: ${dt('image.action.color')};
    background: transparent;
    width: ${dt('image.action.size')};
    height: ${dt('image.action.size')};
    margin: 0;
    padding: 0;
    border: 0 none;
    cursor: pointer;
    user-select: none;
    border-radius: ${dt('image.action.border.radius')};
    outline-color: transparent;
    transition: background ${dt('image.transition.duration')}, color ${dt('image.transition.duration')}, outline-color ${dt('image.transition.duration')}, box-shadow ${dt('image.transition.duration')};
}

.p-image-action:hover {
    color: ${dt('image.action.hover.color')};
    background: ${dt('image.action.hover.background')};
}

.p-image-action:focus-visible {
    box-shadow: ${dt('toolbar.action.focus.ring.shadow')};
    outline: ${dt('toolbar.action.focus.ring.width')} ${dt('toolbar.action.focus.ring.style')} ${dt('toolbar.action.focus.ring.color')};
    outline-offset: ${dt('toolbar.action.focus.ring.offset')};
}

.p-image-action .p-icon {
    font-size: ${dt('image.action.icon.size')};
    width: ${dt('image.action.icon.size')};
    height: ${dt('image.action.icon.size')};
}

.p-image-action.p-disabled {
    pointer-events: auto;
}

.p-image-original {
    transition: transform 0.15s;
    max-width: 100vw;
    max-height: 100vh;
}

.p-image-original-enter-active {
    transition: all 150ms cubic-bezier(0, 0, 0.2, 1);
}

.p-image-original-leave-active {
    transition: all 150ms cubic-bezier(0.4, 0, 0.2, 1);
}

.p-image-original-enter-from,
.p-image-original-leave-to {
    opacity: 0;
    transform: scale(0.7);
}
`;
const classes = {
  root: ({
    props
  }) => ['p-image p-component', {
    'p-image-preview': props.preview
  }],
  previewMask: 'p-image-preview-mask',
  previewIcon: 'p-image-preview-icon',
  mask: 'p-image-mask p-overlay-mask p-overlay-mask-enter',
  toolbar: 'p-image-toolbar',
  rotateRightButton: 'p-image-action p-image-rotate-right-button',
  rotateLeftButton: 'p-image-action p-image-rotate-left-button',
  zoomOutButton: ({
    instance
  }) => ['p-image-action p-image-zoom-out-button', {
    'p-disabled': instance.isZoomOutDisabled
  }],
  zoomInButton: ({
    instance
  }) => ['p-image-action p-image-zoom-in-button', {
    'p-disabled': instance.isZoomInDisabled
  }],
  closeButton: 'p-image-action p-image-close-button',
  original: 'p-image-original'
};
class ImageStyle extends BaseStyle {
  name = 'image';
  theme = theme;
  classes = classes;
  static ɵfac = /*@__PURE__*/(() => {
    let ɵImageStyle_BaseFactory;
    return function ImageStyle_Factory(__ngFactoryType__) {
      return (ɵImageStyle_BaseFactory || (ɵImageStyle_BaseFactory = i0.ɵɵgetInheritedFactory(ImageStyle)))(__ngFactoryType__ || ImageStyle);
    };
  })();
  static ɵprov = /*@__PURE__*/i0.ɵɵdefineInjectable({
    token: ImageStyle,
    factory: ImageStyle.ɵfac
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ImageStyle, [{
    type: Injectable
  }], null, null);
})();
/**
 *
 * Displays an image with preview and tranformation options. For multiple image, see Galleria.
 *
 * [Live Demo](https://www.primeng.org/image/)
 *
 * @module imagestyle
 *
 */
var ImageClasses;
(function (ImageClasses) {
  /**
   * Class name of the root element
   */
  ImageClasses["root"] = "p-image";
  /**
   * Class name of the preview mask element
   */
  ImageClasses["previewMask"] = "p-image-preview-mask";
  /**
   * Class name of the preview icon element
   */
  ImageClasses["previewIcon"] = "p-image-preview-icon";
  /**
   * Class name of the mask element
   */
  ImageClasses["mask"] = "p-image-mask";
  /**
   * Class name of the toolbar element
   */
  ImageClasses["toolbar"] = "p-image-toolbar";
  /**
   * Class name of the rotate right button element
   */
  ImageClasses["rotateRightButton"] = "p-image-rotate-right-button";
  /**
   * Class name of the rotate left button element
   */
  ImageClasses["rotateLeftButton"] = "p-image-rotate-left-button";
  /**
   * Class name of the zoom out button element
   */
  ImageClasses["zoomOutButton"] = "p-image-zoom-out-button";
  /**
   * Class name of the zoom in button element
   */
  ImageClasses["zoomInButton"] = "p-image-zoom-in-button";
  /**
   * Class name of the close button element
   */
  ImageClasses["closeButton"] = "p-image-close-button";
  /**
   * Class name of the original element
   */
  ImageClasses["original"] = "p-image-original";
})(ImageClasses || (ImageClasses = {}));
const _c0 = ["indicator"];
const _c1 = ["rotaterighticon"];
const _c2 = ["rotatelefticon"];
const _c3 = ["zoomouticon"];
const _c4 = ["zoominicon"];
const _c5 = ["closeicon"];
const _c6 = ["preview"];
const _c7 = ["image"];
const _c8 = ["mask"];
const _c9 = ["previewButton"];
const _c10 = ["closeButton"];
const _c11 = a0 => ({
  errorCallback: a0
});
const _c12 = (a0, a1) => ({
  height: a0,
  width: a1
});
const _c13 = a0 => ({
  "p-image-action p-image-zoom-out-button": true,
  "p-disabled": a0
});
const _c14 = a0 => ({
  "p-image-action p-image-zoom-in-button": true,
  "p-disabled": a0
});
const _c15 = (a0, a1) => ({
  showTransitionParams: a0,
  hideTransitionParams: a1
});
const _c16 = a0 => ({
  value: "visible",
  params: a0
});
const _c17 = (a0, a1) => ({
  class: "p-image-original",
  style: a0,
  previewCallback: a1
});
function Image_ng_container_1_Template(rf, ctx) {
  if (rf & 1) {
    const _r1 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementContainerStart(0);
    i0.ɵɵelementStart(1, "img", 9);
    i0.ɵɵlistener("error", function Image_ng_container_1_Template_img_error_1_listener($event) {
      i0.ɵɵrestoreView(_r1);
      const ctx_r1 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r1.imageError($event));
    });
    i0.ɵɵelementEnd();
    i0.ɵɵelementContainerEnd();
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext();
    i0.ɵɵadvance();
    i0.ɵɵclassMap(ctx_r1.imageClass);
    i0.ɵɵproperty("ngStyle", ctx_r1.imageStyle);
    i0.ɵɵattribute("src", ctx_r1.src, i0.ɵɵsanitizeUrl)("srcset", ctx_r1.srcSet)("sizes", ctx_r1.sizes)("alt", ctx_r1.alt)("width", ctx_r1.width)("height", ctx_r1.height)("loading", ctx_r1.loading);
  }
}
function Image_ng_container_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainer(0);
  }
}
function Image_button_3_ng_container_2_ng_container_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainer(0);
  }
}
function Image_button_3_ng_container_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainerStart(0);
    i0.ɵɵtemplate(1, Image_button_3_ng_container_2_ng_container_1_Template, 1, 0, "ng-container", 12);
    i0.ɵɵelementContainerEnd();
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext(2);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngTemplateOutlet", ctx_r1.indicatorTemplate || ctx_r1._indicatorTemplate);
  }
}
function Image_button_3_ng_template_3_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "EyeIcon", 13);
  }
  if (rf & 2) {
    i0.ɵɵproperty("styleClass", "p-image-preview-icon");
  }
}
function Image_button_3_Template(rf, ctx) {
  if (rf & 1) {
    const _r3 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "button", 10, 0);
    i0.ɵɵlistener("click", function Image_button_3_Template_button_click_0_listener() {
      i0.ɵɵrestoreView(_r3);
      const ctx_r1 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r1.onImageClick());
    });
    i0.ɵɵtemplate(2, Image_button_3_ng_container_2_Template, 2, 1, "ng-container", 11)(3, Image_button_3_ng_template_3_Template, 1, 1, "ng-template", null, 1, i0.ɵɵtemplateRefExtractor);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const defaultTemplate_r4 = i0.ɵɵreference(4);
    const ctx_r1 = i0.ɵɵnextContext();
    i0.ɵɵproperty("ngStyle", i0.ɵɵpureFunction2(4, _c12, ctx_r1.height + "px", ctx_r1.width + "px"));
    i0.ɵɵattribute("aria-label", ctx_r1.zoomImageAriaLabel);
    i0.ɵɵadvance(2);
    i0.ɵɵproperty("ngIf", ctx_r1.indicatorTemplate || !ctx_r1._indicatorTemplate)("ngIfElse", defaultTemplate_r4);
  }
}
function Image_div_4_RefreshIcon_4_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "RefreshIcon");
  }
}
function Image_div_4_5_ng_template_0_Template(rf, ctx) {}
function Image_div_4_5_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtemplate(0, Image_div_4_5_ng_template_0_Template, 0, 0, "ng-template");
  }
}
function Image_div_4_UndoIcon_7_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "UndoIcon");
  }
}
function Image_div_4_8_ng_template_0_Template(rf, ctx) {}
function Image_div_4_8_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtemplate(0, Image_div_4_8_ng_template_0_Template, 0, 0, "ng-template");
  }
}
function Image_div_4_SearchMinusIcon_10_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "SearchMinusIcon");
  }
}
function Image_div_4_11_ng_template_0_Template(rf, ctx) {}
function Image_div_4_11_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtemplate(0, Image_div_4_11_ng_template_0_Template, 0, 0, "ng-template");
  }
}
function Image_div_4_SearchPlusIcon_13_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "SearchPlusIcon");
  }
}
function Image_div_4_14_ng_template_0_Template(rf, ctx) {}
function Image_div_4_14_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtemplate(0, Image_div_4_14_ng_template_0_Template, 0, 0, "ng-template");
  }
}
function Image_div_4_TimesIcon_17_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "TimesIcon");
  }
}
function Image_div_4_18_ng_template_0_Template(rf, ctx) {}
function Image_div_4_18_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtemplate(0, Image_div_4_18_ng_template_0_Template, 0, 0, "ng-template");
  }
}
function Image_div_4_div_19_ng_container_1_Template(rf, ctx) {
  if (rf & 1) {
    const _r7 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementContainerStart(0);
    i0.ɵɵelementStart(1, "img", 20);
    i0.ɵɵlistener("click", function Image_div_4_div_19_ng_container_1_Template_img_click_1_listener() {
      i0.ɵɵrestoreView(_r7);
      const ctx_r1 = i0.ɵɵnextContext(3);
      return i0.ɵɵresetView(ctx_r1.onPreviewImageClick());
    });
    i0.ɵɵelementEnd();
    i0.ɵɵelementContainerEnd();
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext(3);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngStyle", ctx_r1.imagePreviewStyle());
    i0.ɵɵattribute("src", ctx_r1.previewImageSrc ? ctx_r1.previewImageSrc : ctx_r1.src, i0.ɵɵsanitizeUrl)("srcset", ctx_r1.previewImageSrcSet)("sizes", ctx_r1.previewImageSizes);
  }
}
function Image_div_4_div_19_ng_container_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainer(0);
  }
}
function Image_div_4_div_19_Template(rf, ctx) {
  if (rf & 1) {
    const _r6 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "div");
    i0.ɵɵlistener("@animation.start", function Image_div_4_div_19_Template_div_animation_animation_start_0_listener($event) {
      i0.ɵɵrestoreView(_r6);
      const ctx_r1 = i0.ɵɵnextContext(2);
      return i0.ɵɵresetView(ctx_r1.onAnimationStart($event));
    })("@animation.done", function Image_div_4_div_19_Template_div_animation_animation_done_0_listener($event) {
      i0.ɵɵrestoreView(_r6);
      const ctx_r1 = i0.ɵɵnextContext(2);
      return i0.ɵɵresetView(ctx_r1.onAnimationEnd($event));
    });
    i0.ɵɵtemplate(1, Image_div_4_div_19_ng_container_1_Template, 2, 4, "ng-container", 5)(2, Image_div_4_div_19_ng_container_2_Template, 1, 0, "ng-container", 6);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext(2);
    i0.ɵɵproperty("@animation", i0.ɵɵpureFunction1(7, _c16, i0.ɵɵpureFunction2(4, _c15, ctx_r1.showTransitionOptions, ctx_r1.hideTransitionOptions)));
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", !ctx_r1.previewTemplate && !ctx_r1._previewTemplate);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngTemplateOutlet", ctx_r1.previewTemplate || ctx_r1._previewTemplate)("ngTemplateOutletContext", i0.ɵɵpureFunction2(9, _c17, ctx_r1.imagePreviewStyle(), ctx_r1.onPreviewImageClick.bind(ctx_r1)));
  }
}
function Image_div_4_Template(rf, ctx) {
  if (rf & 1) {
    const _r5 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "div", 14, 2);
    i0.ɵɵlistener("click", function Image_div_4_Template_div_click_0_listener() {
      i0.ɵɵrestoreView(_r5);
      const ctx_r1 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r1.onMaskClick());
    })("keydown", function Image_div_4_Template_div_keydown_0_listener($event) {
      i0.ɵɵrestoreView(_r5);
      const ctx_r1 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r1.onMaskKeydown($event));
    });
    i0.ɵɵelementStart(2, "div", 15);
    i0.ɵɵlistener("click", function Image_div_4_Template_div_click_2_listener($event) {
      i0.ɵɵrestoreView(_r5);
      const ctx_r1 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r1.handleToolbarClick($event));
    });
    i0.ɵɵelementStart(3, "button", 16);
    i0.ɵɵlistener("click", function Image_div_4_Template_button_click_3_listener() {
      i0.ɵɵrestoreView(_r5);
      const ctx_r1 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r1.rotateRight());
    });
    i0.ɵɵtemplate(4, Image_div_4_RefreshIcon_4_Template, 1, 0, "RefreshIcon", 5)(5, Image_div_4_5_Template, 1, 0, null, 12);
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(6, "button", 17);
    i0.ɵɵlistener("click", function Image_div_4_Template_button_click_6_listener() {
      i0.ɵɵrestoreView(_r5);
      const ctx_r1 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r1.rotateLeft());
    });
    i0.ɵɵtemplate(7, Image_div_4_UndoIcon_7_Template, 1, 0, "UndoIcon", 5)(8, Image_div_4_8_Template, 1, 0, null, 12);
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(9, "button", 18);
    i0.ɵɵlistener("click", function Image_div_4_Template_button_click_9_listener() {
      i0.ɵɵrestoreView(_r5);
      const ctx_r1 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r1.zoomOut());
    });
    i0.ɵɵtemplate(10, Image_div_4_SearchMinusIcon_10_Template, 1, 0, "SearchMinusIcon", 5)(11, Image_div_4_11_Template, 1, 0, null, 12);
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(12, "button", 18);
    i0.ɵɵlistener("click", function Image_div_4_Template_button_click_12_listener() {
      i0.ɵɵrestoreView(_r5);
      const ctx_r1 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r1.zoomIn());
    });
    i0.ɵɵtemplate(13, Image_div_4_SearchPlusIcon_13_Template, 1, 0, "SearchPlusIcon", 5)(14, Image_div_4_14_Template, 1, 0, null, 12);
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(15, "button", 19, 3);
    i0.ɵɵlistener("click", function Image_div_4_Template_button_click_15_listener() {
      i0.ɵɵrestoreView(_r5);
      const ctx_r1 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r1.closePreview());
    });
    i0.ɵɵtemplate(17, Image_div_4_TimesIcon_17_Template, 1, 0, "TimesIcon", 5)(18, Image_div_4_18_Template, 1, 0, null, 12);
    i0.ɵɵelementEnd()();
    i0.ɵɵtemplate(19, Image_div_4_div_19_Template, 3, 12, "div", 5);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext();
    i0.ɵɵattribute("aria-modal", ctx_r1.maskVisible);
    i0.ɵɵadvance(3);
    i0.ɵɵattribute("aria-label", ctx_r1.rightAriaLabel());
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", !ctx_r1.rotateRightIconTemplate && !ctx_r1._rotateRightIconTemplate);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngTemplateOutlet", ctx_r1.rotateRightIconTemplate || ctx_r1._rotateRightIconTemplate);
    i0.ɵɵadvance();
    i0.ɵɵattribute("aria-label", ctx_r1.leftAriaLabel());
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", !ctx_r1.rotateLeftIconTemplate && !ctx_r1._rotateLeftIconTemplate);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngTemplateOutlet", ctx_r1.rotateLeftIconTemplate || ctx_r1._rotateLeftIconTemplate);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngClass", i0.ɵɵpureFunction1(21, _c13, ctx_r1.isZoomOutDisabled))("disabled", ctx_r1.isZoomOutDisabled);
    i0.ɵɵattribute("aria-label", ctx_r1.zoomOutAriaLabel());
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", !ctx_r1.zoomOutIconTemplate && !ctx_r1._zoomOutIconTemplate);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngTemplateOutlet", ctx_r1.zoomOutIconTemplate || ctx_r1._zoomOutIconTemplate);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngClass", i0.ɵɵpureFunction1(23, _c14, ctx_r1.isZoomOutDisabled))("disabled", ctx_r1.isZoomInDisabled);
    i0.ɵɵattribute("aria-label", ctx_r1.zoomInAriaLabel());
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", !ctx_r1.zoomInIconTemplate && !ctx_r1._zoomInIconTemplate);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngTemplateOutlet", ctx_r1.zoomInIconTemplate || ctx_r1._zoomInIconTemplate);
    i0.ɵɵadvance();
    i0.ɵɵattribute("aria-label", ctx_r1.closeAriaLabel());
    i0.ɵɵadvance(2);
    i0.ɵɵproperty("ngIf", !ctx_r1.closeIconTemplate && !ctx_r1._closeIconTemplate);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngTemplateOutlet", ctx_r1.closeIconTemplate || ctx_r1._closeIconTemplate);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", ctx_r1.previewVisible);
  }
}
/**
 * Displays an image with preview and tranformation options. For multiple image, see Galleria.
 * @group Components
 */
class Image extends BaseComponent {
  /**
   * Style class of the image element.
   * @group Props
   */
  imageClass;
  /**
   * Inline style of the image element.
   * @group Props
   */
  imageStyle;
  /**
   * Class of the element.
   * @group Props
   */
  styleClass;
  /**
   * Inline style of the element.
   * @group Props
   */
  style;
  /**
   * The source path for the main image.
   * @group Props
   */
  src;
  /**
   * The srcset definition for the main image.
   * @group Props
   */
  srcSet;
  /**
   * The sizes definition for the main image.
   * @group Props
   */
  sizes;
  /**
   * The source path for the preview image.
   * @group Props
   */
  previewImageSrc;
  /**
   * The srcset definition for the preview image.
   * @group Props
   */
  previewImageSrcSet;
  /**
   * The sizes definition for the preview image.
   * @group Props
   */
  previewImageSizes;
  /**
   * Attribute of the preview image element.
   * @group Props
   */
  alt;
  /**
   * Attribute of the image element.
   * @group Props
   */
  width;
  /**
   * Attribute of the image element.
   * @group Props
   */
  height;
  /**
   * Attribute of the image element.
   * @group Props
   */
  loading;
  /**
   * Target element to attach the dialog, valid values are "body" or a local ng-template variable of another element (note: use binding with brackets for template variables, e.g. [appendTo]="mydiv" for a div element having #mydiv as variable name).
   * @group Props
   */
  appendTo;
  /**
   * Controls the preview functionality.
   * @group Props
   */
  preview = false;
  /**
   * Transition options of the show animation
   * @group Props
   */
  showTransitionOptions = '150ms cubic-bezier(0, 0, 0.2, 1)';
  /**
   * Transition options of the hide animation
   * @group Props
   */
  hideTransitionOptions = '150ms cubic-bezier(0, 0, 0.2, 1)';
  /**
   * Triggered when the preview overlay is shown.
   * @group Emits
   */
  onShow = new EventEmitter();
  /**
   * Triggered when the preview overlay is hidden.
   * @group Emits
   */
  onHide = new EventEmitter();
  /**
   * This event is triggered if an error occurs while loading an image file.
   * @param {Event} event - Browser event.
   * @group Emits
   */
  onImageError = new EventEmitter();
  mask;
  previewButton;
  closeButton;
  /**
   * Custom template of indicator.
   * @group Templates
   */
  indicatorTemplate;
  /**
   * Custom template of rotaterighticon.
   * @group Templates
   */
  rotateRightIconTemplate;
  /**
   * Custom template of rotatelefticon.
   * @group Templates
   */
  rotateLeftIconTemplate;
  /**
   * Custom template of zoomouticon.
   * @group Templates
   */
  zoomOutIconTemplate;
  /**
   * Custom template of zoominicon.
   * @group Templates
   */
  zoomInIconTemplate;
  /**
   * Custom template of closeicon.
   * @group Templates
   */
  closeIconTemplate;
  /**
   * Custom template of preview.
   * @group Templates
   */
  previewTemplate;
  /**
   * Custom template of image.
   * @group Templates
   */
  imageTemplate;
  maskVisible = false;
  previewVisible = false;
  rotate = 0;
  scale = 1;
  previewClick = false;
  container;
  wrapper;
  _componentStyle = inject(ImageStyle);
  get isZoomOutDisabled() {
    return this.scale - this.zoomSettings.step <= this.zoomSettings.min;
  }
  get isZoomInDisabled() {
    return this.scale + this.zoomSettings.step >= this.zoomSettings.max;
  }
  zoomSettings = {
    default: 1,
    step: 0.1,
    max: 1.5,
    min: 0.5
  };
  constructor() {
    super();
  }
  templates;
  _indicatorTemplate;
  _rotateRightIconTemplate;
  _rotateLeftIconTemplate;
  _zoomOutIconTemplate;
  _zoomInIconTemplate;
  _closeIconTemplate;
  _imageTemplate;
  _previewTemplate;
  ngAfterContentInit() {
    this.templates?.forEach(item => {
      switch (item.getType()) {
        case 'indicator':
          this._indicatorTemplate = item.template;
          break;
        case 'rotaterighticon':
          this._rotateRightIconTemplate = item.template;
          break;
        case 'rotatelefticon':
          this._rotateLeftIconTemplate = item.template;
          break;
        case 'zoomouticon':
          this._zoomOutIconTemplate = item.template;
          break;
        case 'zoominicon':
          this._zoomInIconTemplate = item.template;
          break;
        case 'closeicon':
          this._closeIconTemplate = item.template;
          break;
        case 'image':
          this._imageTemplate = item.template;
          break;
        case 'preview':
          this._previewTemplate = item.template;
          break;
        default:
          this._indicatorTemplate = item.template;
          break;
      }
    });
  }
  onImageClick() {
    if (this.preview) {
      this.maskVisible = true;
      this.previewVisible = true;
      blockBodyScroll();
    }
  }
  onMaskClick() {
    if (!this.previewClick) {
      this.closePreview();
    }
    this.previewClick = false;
  }
  onMaskKeydown(event) {
    switch (event.code) {
      case 'Escape':
        this.onMaskClick();
        setTimeout(() => {
          focus(this.previewButton.nativeElement);
        }, 25);
        event.preventDefault();
        break;
      default:
        break;
    }
  }
  onPreviewImageClick() {
    this.previewClick = true;
  }
  rotateRight() {
    this.rotate += 90;
    this.previewClick = true;
  }
  rotateLeft() {
    this.rotate -= 90;
    this.previewClick = true;
  }
  zoomIn() {
    this.scale = this.scale + this.zoomSettings.step;
    this.previewClick = true;
  }
  zoomOut() {
    this.scale = this.scale - this.zoomSettings.step;
    this.previewClick = true;
  }
  onAnimationStart(event) {
    switch (event.toState) {
      case 'visible':
        this.container = event.element;
        this.wrapper = this.container?.parentElement;
        this.appendContainer();
        this.moveOnTop();
        setTimeout(() => {
          focus(this.closeButton.nativeElement);
        }, 25);
        break;
      case 'void':
        addClass(this.wrapper, 'p-overlay-mask-leave');
        break;
    }
  }
  onAnimationEnd(event) {
    switch (event.toState) {
      case 'void':
        ZIndexUtils.clear(this.wrapper);
        this.maskVisible = false;
        this.container = null;
        this.wrapper = null;
        this.cd.markForCheck();
        this.onHide.emit({});
        break;
      case 'visible':
        this.onShow.emit({});
        break;
    }
  }
  moveOnTop() {
    ZIndexUtils.set('modal', this.wrapper, this.config.zIndex.modal);
  }
  appendContainer() {
    if (this.appendTo) {
      if (this.appendTo === 'body') this.document.body.appendChild(this.wrapper);else appendChild(this.appendTo, this.wrapper);
    }
  }
  imagePreviewStyle() {
    return {
      transform: 'rotate(' + this.rotate + 'deg) scale(' + this.scale + ')'
    };
  }
  get zoomImageAriaLabel() {
    return this.config.translation.aria ? this.config.translation.aria.zoomImage : undefined;
  }
  containerClass() {
    return {
      'p-image p-component': true,
      'p-image-preview': this.preview
    };
  }
  handleToolbarClick(event) {
    event.stopPropagation();
  }
  closePreview() {
    this.previewVisible = false;
    this.rotate = 0;
    this.scale = this.zoomSettings.default;
    unblockBodyScroll();
  }
  imageError(event) {
    this.onImageError.emit(event);
  }
  rightAriaLabel() {
    return this.config.translation.aria ? this.config.translation.aria.rotateRight : undefined;
  }
  leftAriaLabel() {
    return this.config.translation.aria ? this.config.translation.aria.rotateLeft : undefined;
  }
  zoomInAriaLabel() {
    return this.config.translation.aria ? this.config.translation.aria.zoomIn : undefined;
  }
  zoomOutAriaLabel() {
    return this.config.translation.aria ? this.config.translation.aria.zoomOut : undefined;
  }
  closeAriaLabel() {
    return this.config.translation.aria ? this.config.translation.aria.close : undefined;
  }
  onKeydownHandler(event) {
    if (this.previewVisible) {
      this.closePreview();
    }
  }
  static ɵfac = function Image_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || Image)();
  };
  static ɵcmp = /*@__PURE__*/i0.ɵɵdefineComponent({
    type: Image,
    selectors: [["p-image"]],
    contentQueries: function Image_ContentQueries(rf, ctx, dirIndex) {
      if (rf & 1) {
        i0.ɵɵcontentQuery(dirIndex, _c0, 4);
        i0.ɵɵcontentQuery(dirIndex, _c1, 4);
        i0.ɵɵcontentQuery(dirIndex, _c2, 4);
        i0.ɵɵcontentQuery(dirIndex, _c3, 4);
        i0.ɵɵcontentQuery(dirIndex, _c4, 4);
        i0.ɵɵcontentQuery(dirIndex, _c5, 4);
        i0.ɵɵcontentQuery(dirIndex, _c6, 4);
        i0.ɵɵcontentQuery(dirIndex, _c7, 4);
        i0.ɵɵcontentQuery(dirIndex, PrimeTemplate, 4);
      }
      if (rf & 2) {
        let _t;
        i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.indicatorTemplate = _t.first);
        i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.rotateRightIconTemplate = _t.first);
        i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.rotateLeftIconTemplate = _t.first);
        i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.zoomOutIconTemplate = _t.first);
        i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.zoomInIconTemplate = _t.first);
        i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.closeIconTemplate = _t.first);
        i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.previewTemplate = _t.first);
        i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.imageTemplate = _t.first);
        i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.templates = _t);
      }
    },
    viewQuery: function Image_Query(rf, ctx) {
      if (rf & 1) {
        i0.ɵɵviewQuery(_c8, 5);
        i0.ɵɵviewQuery(_c9, 5);
        i0.ɵɵviewQuery(_c10, 5);
      }
      if (rf & 2) {
        let _t;
        i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.mask = _t.first);
        i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.previewButton = _t.first);
        i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.closeButton = _t.first);
      }
    },
    hostBindings: function Image_HostBindings(rf, ctx) {
      if (rf & 1) {
        i0.ɵɵlistener("keydown.escape", function Image_keydown_escape_HostBindingHandler($event) {
          return ctx.onKeydownHandler($event);
        }, false, i0.ɵɵresolveDocument);
      }
    },
    inputs: {
      imageClass: "imageClass",
      imageStyle: "imageStyle",
      styleClass: "styleClass",
      style: "style",
      src: "src",
      srcSet: "srcSet",
      sizes: "sizes",
      previewImageSrc: "previewImageSrc",
      previewImageSrcSet: "previewImageSrcSet",
      previewImageSizes: "previewImageSizes",
      alt: "alt",
      width: "width",
      height: "height",
      loading: "loading",
      appendTo: "appendTo",
      preview: [2, "preview", "preview", booleanAttribute],
      showTransitionOptions: "showTransitionOptions",
      hideTransitionOptions: "hideTransitionOptions"
    },
    outputs: {
      onShow: "onShow",
      onHide: "onHide",
      onImageError: "onImageError"
    },
    features: [i0.ɵɵProvidersFeature([ImageStyle]), i0.ɵɵInputTransformsFeature, i0.ɵɵInheritDefinitionFeature],
    decls: 5,
    vars: 11,
    consts: [["previewButton", ""], ["defaultTemplate", ""], ["mask", ""], ["closeButton", ""], [3, "ngClass", "ngStyle"], [4, "ngIf"], [4, "ngTemplateOutlet", "ngTemplateOutletContext"], ["type", "button", "class", "p-image-preview-mask", 3, "ngStyle", "click", 4, "ngIf"], ["class", "p-image-mask p-overlay-mask p-overlay-mask-enter", "role", "dialog", "pFocusTrap", "", 3, "click", "keydown", 4, "ngIf"], [3, "error", "ngStyle"], ["type", "button", 1, "p-image-preview-mask", 3, "click", "ngStyle"], [4, "ngIf", "ngIfElse"], [4, "ngTemplateOutlet"], [3, "styleClass"], ["role", "dialog", "pFocusTrap", "", 1, "p-image-mask", "p-overlay-mask", "p-overlay-mask-enter", 3, "click", "keydown"], [1, "p-image-toolbar", 3, "click"], ["type", "button", 1, "p-image-action", "p-image-rotate-right-button", 3, "click"], ["type", "button", 1, "p-image-action", "p-image-rotate-left-button", 3, "click"], ["type", "button", 3, "click", "ngClass", "disabled"], ["type", "button", 1, "p-image-action", "p-image-close-button", 3, "click"], [1, "p-image-original", 3, "click", "ngStyle"]],
    template: function Image_Template(rf, ctx) {
      if (rf & 1) {
        i0.ɵɵelementStart(0, "span", 4);
        i0.ɵɵtemplate(1, Image_ng_container_1_Template, 2, 10, "ng-container", 5)(2, Image_ng_container_2_Template, 1, 0, "ng-container", 6)(3, Image_button_3_Template, 5, 7, "button", 7)(4, Image_div_4_Template, 20, 25, "div", 8);
        i0.ɵɵelementEnd();
      }
      if (rf & 2) {
        i0.ɵɵclassMap(ctx.styleClass);
        i0.ɵɵproperty("ngClass", ctx.containerClass())("ngStyle", ctx.style);
        i0.ɵɵadvance();
        i0.ɵɵproperty("ngIf", !ctx.imageTemplate && !ctx._imageTemplate);
        i0.ɵɵadvance();
        i0.ɵɵproperty("ngTemplateOutlet", ctx.imageTemplate || ctx._imageTemplate)("ngTemplateOutletContext", i0.ɵɵpureFunction1(9, _c11, ctx.imageError.bind(ctx)));
        i0.ɵɵadvance();
        i0.ɵɵproperty("ngIf", ctx.preview);
        i0.ɵɵadvance();
        i0.ɵɵproperty("ngIf", ctx.maskVisible);
      }
    },
    dependencies: [CommonModule, i1.NgClass, i1.NgIf, i1.NgTemplateOutlet, i1.NgStyle, RefreshIcon, EyeIcon, UndoIcon, SearchMinusIcon, SearchPlusIcon, TimesIcon, FocusTrap, SharedModule],
    encapsulation: 2,
    data: {
      animation: [trigger('animation', [transition('void => visible', [style({
        transform: 'scale(0.7)',
        opacity: 0
      }), animate('{{showTransitionParams}}')]), transition('visible => void', [animate('{{hideTransitionParams}}', style({
        transform: 'scale(0.7)',
        opacity: 0
      }))])])]
    },
    changeDetection: 0
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(Image, [{
    type: Component,
    args: [{
      selector: 'p-image',
      standalone: true,
      imports: [CommonModule, RefreshIcon, EyeIcon, UndoIcon, SearchMinusIcon, SearchPlusIcon, TimesIcon, FocusTrap, SharedModule],
      template: `
        <span [ngClass]="containerClass()" [class]="styleClass" [ngStyle]="style">
            <ng-container *ngIf="!imageTemplate && !_imageTemplate">
                <img [attr.src]="src" [attr.srcset]="srcSet" [attr.sizes]="sizes" [attr.alt]="alt" [attr.width]="width" [attr.height]="height" [attr.loading]="loading" [ngStyle]="imageStyle" [class]="imageClass" (error)="imageError($event)" />
            </ng-container>

            <ng-container *ngTemplateOutlet="imageTemplate || _imageTemplate; context: { errorCallback: imageError.bind(this) }"></ng-container>

            <button *ngIf="preview" [attr.aria-label]="zoomImageAriaLabel" type="button" class="p-image-preview-mask" (click)="onImageClick()" #previewButton [ngStyle]="{ height: height + 'px', width: width + 'px' }">
                <ng-container *ngIf="indicatorTemplate || !_indicatorTemplate; else defaultTemplate">
                    <ng-container *ngTemplateOutlet="indicatorTemplate || _indicatorTemplate"></ng-container>
                </ng-container>
                <ng-template #defaultTemplate>
                    <EyeIcon [styleClass]="'p-image-preview-icon'" />
                </ng-template>
            </button>
            <div #mask class="p-image-mask p-overlay-mask p-overlay-mask-enter" *ngIf="maskVisible" [attr.aria-modal]="maskVisible" role="dialog" (click)="onMaskClick()" (keydown)="onMaskKeydown($event)" pFocusTrap>
                <div class="p-image-toolbar" (click)="handleToolbarClick($event)">
                    <button class="p-image-action p-image-rotate-right-button" (click)="rotateRight()" type="button" [attr.aria-label]="rightAriaLabel()">
                        <RefreshIcon *ngIf="!rotateRightIconTemplate && !_rotateRightIconTemplate" />
                        <ng-template *ngTemplateOutlet="rotateRightIconTemplate || _rotateRightIconTemplate"></ng-template>
                    </button>
                    <button class="p-image-action p-image-rotate-left-button" (click)="rotateLeft()" type="button" [attr.aria-label]="leftAriaLabel()">
                        <UndoIcon *ngIf="!rotateLeftIconTemplate && !_rotateLeftIconTemplate" />
                        <ng-template *ngTemplateOutlet="rotateLeftIconTemplate || _rotateLeftIconTemplate"></ng-template>
                    </button>
                    <button [ngClass]="{ 'p-image-action p-image-zoom-out-button': true, 'p-disabled': isZoomOutDisabled }" (click)="zoomOut()" type="button" [disabled]="isZoomOutDisabled" [attr.aria-label]="zoomOutAriaLabel()">
                        <SearchMinusIcon *ngIf="!zoomOutIconTemplate && !_zoomOutIconTemplate" />
                        <ng-template *ngTemplateOutlet="zoomOutIconTemplate || _zoomOutIconTemplate"></ng-template>
                    </button>
                    <button [ngClass]="{ 'p-image-action p-image-zoom-in-button': true, 'p-disabled': isZoomOutDisabled }" (click)="zoomIn()" type="button" [disabled]="isZoomInDisabled" [attr.aria-label]="zoomInAriaLabel()">
                        <SearchPlusIcon *ngIf="!zoomInIconTemplate && !_zoomInIconTemplate" />
                        <ng-template *ngTemplateOutlet="zoomInIconTemplate || _zoomInIconTemplate"></ng-template>
                    </button>
                    <button class="p-image-action p-image-close-button" type="button" (click)="closePreview()" [attr.aria-label]="closeAriaLabel()" #closeButton>
                        <TimesIcon *ngIf="!closeIconTemplate && !_closeIconTemplate" />
                        <ng-template *ngTemplateOutlet="closeIconTemplate || _closeIconTemplate"></ng-template>
                    </button>
                </div>
                <div
                    *ngIf="previewVisible"
                    [@animation]="{
                        value: 'visible',
                        params: { showTransitionParams: showTransitionOptions, hideTransitionParams: hideTransitionOptions }
                    }"
                    (@animation.start)="onAnimationStart($event)"
                    (@animation.done)="onAnimationEnd($event)"
                >
                    <ng-container *ngIf="!previewTemplate && !_previewTemplate">
                        <img [attr.src]="previewImageSrc ? previewImageSrc : src" [attr.srcset]="previewImageSrcSet" [attr.sizes]="previewImageSizes" class="p-image-original" [ngStyle]="imagePreviewStyle()" (click)="onPreviewImageClick()" />
                    </ng-container>
                    <ng-container
                        *ngTemplateOutlet="
                            previewTemplate || _previewTemplate;
                            context: {
                                class: 'p-image-original',
                                style: imagePreviewStyle(),
                                previewCallback: onPreviewImageClick.bind(this)
                            }
                        "
                    >
                    </ng-container>
                </div>
            </div>
        </span>
    `,
      animations: [trigger('animation', [transition('void => visible', [style({
        transform: 'scale(0.7)',
        opacity: 0
      }), animate('{{showTransitionParams}}')]), transition('visible => void', [animate('{{hideTransitionParams}}', style({
        transform: 'scale(0.7)',
        opacity: 0
      }))])])],
      changeDetection: ChangeDetectionStrategy.OnPush,
      encapsulation: ViewEncapsulation.None,
      providers: [ImageStyle]
    }]
  }], () => [], {
    imageClass: [{
      type: Input
    }],
    imageStyle: [{
      type: Input
    }],
    styleClass: [{
      type: Input
    }],
    style: [{
      type: Input
    }],
    src: [{
      type: Input
    }],
    srcSet: [{
      type: Input
    }],
    sizes: [{
      type: Input
    }],
    previewImageSrc: [{
      type: Input
    }],
    previewImageSrcSet: [{
      type: Input
    }],
    previewImageSizes: [{
      type: Input
    }],
    alt: [{
      type: Input
    }],
    width: [{
      type: Input
    }],
    height: [{
      type: Input
    }],
    loading: [{
      type: Input
    }],
    appendTo: [{
      type: Input
    }],
    preview: [{
      type: Input,
      args: [{
        transform: booleanAttribute
      }]
    }],
    showTransitionOptions: [{
      type: Input
    }],
    hideTransitionOptions: [{
      type: Input
    }],
    onShow: [{
      type: Output
    }],
    onHide: [{
      type: Output
    }],
    onImageError: [{
      type: Output
    }],
    mask: [{
      type: ViewChild,
      args: ['mask']
    }],
    previewButton: [{
      type: ViewChild,
      args: ['previewButton']
    }],
    closeButton: [{
      type: ViewChild,
      args: ['closeButton']
    }],
    indicatorTemplate: [{
      type: ContentChild,
      args: ['indicator', {
        descendants: false
      }]
    }],
    rotateRightIconTemplate: [{
      type: ContentChild,
      args: ['rotaterighticon', {
        descendants: false
      }]
    }],
    rotateLeftIconTemplate: [{
      type: ContentChild,
      args: ['rotatelefticon', {
        descendants: false
      }]
    }],
    zoomOutIconTemplate: [{
      type: ContentChild,
      args: ['zoomouticon', {
        descendants: false
      }]
    }],
    zoomInIconTemplate: [{
      type: ContentChild,
      args: ['zoominicon', {
        descendants: false
      }]
    }],
    closeIconTemplate: [{
      type: ContentChild,
      args: ['closeicon', {
        descendants: false
      }]
    }],
    previewTemplate: [{
      type: ContentChild,
      args: ['preview', {
        descendants: false
      }]
    }],
    imageTemplate: [{
      type: ContentChild,
      args: ['image', {
        descendants: false
      }]
    }],
    templates: [{
      type: ContentChildren,
      args: [PrimeTemplate]
    }],
    onKeydownHandler: [{
      type: HostListener,
      args: ['document:keydown.escape', ['$event']]
    }]
  });
})();
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassDebugInfo(Image, {
    className: "Image",
    filePath: "image.ts",
    lineNumber: 116
  });
})();
class ImageModule {
  static ɵfac = function ImageModule_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || ImageModule)();
  };
  static ɵmod = /*@__PURE__*/i0.ɵɵdefineNgModule({
    type: ImageModule
  });
  static ɵinj = /*@__PURE__*/i0.ɵɵdefineInjector({
    imports: [Image, SharedModule, SharedModule]
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ImageModule, [{
    type: NgModule,
    args: [{
      imports: [Image, SharedModule],
      exports: [Image, SharedModule]
    }]
  }], null, null);
})();
(function () {
  (typeof ngJitMode === "undefined" || ngJitMode) && i0.ɵɵsetNgModuleScope(ImageModule, {
    imports: [Image, SharedModule],
    exports: [Image, SharedModule]
  });
})();

/**
 * Generated bundle index. Do not edit.
 */

export { Image, ImageClasses, ImageModule, ImageStyle };
