import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DropdownModule } from 'primeng/dropdown';
import { TableModule } from 'primeng/table';
import { DialogModule } from 'primeng/dialog';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { MultiSelectModule } from 'primeng/multiselect';
import { RadioButtonModule } from 'primeng/radiobutton';
import { TagModule } from 'primeng/tag';
import { SkeletonModule } from 'primeng/skeleton';
import { ToastModule } from 'primeng/toast';
import { InputNumberModule } from 'primeng/inputnumber';
import { ImageModule } from 'primeng/image';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { Popover } from 'primeng/popover';
import { DatePickerModule } from 'primeng/datepicker';
import { Select } from 'primeng/select';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    FormsModule,
    DropdownModule,
    TableModule,
    DialogModule,
    OverlayPanelModule,
    ReactiveFormsModule,
    MultiSelectModule,
    RadioButtonModule,
    TagModule,
    SkeletonModule,
    ToastModule,
    InputNumberModule,
    ImageModule,
    AutoCompleteModule,
    Popover,
    DatePickerModule,
    Select,
  ],
  exports: [
    CommonModule,
    FormsModule,
    DropdownModule,
    TableModule,
    DialogModule,
    OverlayPanelModule,
    ReactiveFormsModule,
    MultiSelectModule,
    RadioButtonModule,
    TagModule,
    SkeletonModule,
    ToastModule,
    InputNumberModule,
    ImageModule,
    AutoCompleteModule,
    Popover,
    DatePickerModule,
    Select,
  ],
})
export class SharedModule {}
