import { Component } from '@angular/core';
import { SharedModule } from '../../shared.module';
import { SearchFieldComponent } from '../../Components/searchField/search-text.component';
import { TableCols } from '../../types';
import { ACTIVITIES_BUNDLE_COLS, BUNDLE_COLS } from '../../constants';
import { NavigationExtras, Router } from '@angular/router';
import { SubmissionService } from './submissions.service';
import ROUTES from '../../routes.const';
import { MessageService } from 'primeng/api';
import { debounceTime, Subject } from 'rxjs';

@Component({
  selector: 'app-submission',
  templateUrl: './submission.component.html',
  styleUrl: './submission.component.scss',
  imports: [SharedModule, SearchFieldComponent],
  providers: [MessageService],
})
export class ActivitiesComponent {
  activitiesList: any[] = [];
  isLoading: boolean = true;
  skeletonRows = new Array(10);
  onHover: number = 0;
  ActivitiesCols: TableCols[] = ACTIVITIES_BUNDLE_COLS;
  BundleCols: TableCols[] = BUNDLE_COLS;
  first = 0;
  pageNumber: number = 0;
  searchQuery: string[] | null = [];
  pageSize: number = 10;
  searchText: string = '';
  searchSubject: Subject<string> = new Subject<string>();
  bundleListCount: number = 0;
  StatusList: any[] = [
    { label: 'AUDIT' },
    { label: 'ACCEPTED' },
    { label: 'CONFIRMED' },
    { label: 'GENERATED' },
    { label: 'NEW' },
    { label: 'SUBMITTED' },
  ];

  constructor(
    private router: Router,
    private service: SubmissionService,
    private messageService: MessageService
  ) {
    this.setupSearch();
  }

  async ngOnInit() {
    await this.getSubmissionBundle(0, 10, '', null);
  }

  async getSubmissionBundle(
    pageNo: number,
    pageSize: number,
    search: string,
    searchStatus: string[] | null
  ): Promise<void> {
    try {
      const res = await this.service.fetchBundles(
        pageNo,
        pageSize,
        search,
        searchStatus
      );
      if (res) {
        this.activitiesList = res.data;
        this.bundleListCount = res.totalCount;
        this.isLoading = false;
      }
      return res;
    } catch (error: any) {
      this.isLoading = false;
    }
  }

  errorToast(detail: string): void {
    this.messageService.add({
      severity: 'error',
      summary: 'Error',
      detail: detail,
    });
  }

  successToast(detail: string): void {
    this.messageService.add({
      severity: 'success',
      summary: 'Success',
      detail: detail,
    });
  }
  handleHover = (index: number) => {
    this.onHover = index;
  };

  submissionDetails(rowData: any): void {
    const params: NavigationExtras = { state: { rowData } };
    this.router.navigate([ROUTES.SUBMISSIONS_DETAILS], params);
  }

  getStatusClass(status: string): string {
    switch (status) {
      case 'NEW':
        return 'tag-new';
      case 'AUDIT':
        return 'tag-audit';
      case 'CONFIRMED':
        return 'tag-complete';
      case 'GENERATED':
        return 'tag-external-audit';
      case 'ACCEPTED':
        return 'tag-audit-complete';
      case 'SUBMITTED':
        return 'tag-submitted';
      default:
        return '';
    }
  }

  async pageChange(event: any): Promise<void> {
    this.pageNumber = event.first / event.rows;
    this.first = event.first;
    if (this.searchQuery?.length !== 0) {
      await this.getSubmissionBundle(
        this.pageNumber,
        this.pageSize,
        this.searchText,
        this.searchQuery
      );
    } else {
      await this.getSubmissionBundle(
        this.pageNumber,
        this.pageSize,
        this.searchText,
        null
      );
    }
  }

  async onPageSizeChange(event: any) {
    this.pageSize = event.value;
    if (this.searchQuery?.length !== 0) {
      await this.getSubmissionBundle(
        this.pageNumber,
        event.value,
        this.searchText,
        this.searchQuery
      );
    } else {
      await this.getSubmissionBundle(
        this.pageNumber,
        event.value,
        this.searchText,
        null
      );
    }
  }

  private setupSearch() {
    this.searchSubject.pipe(debounceTime(500)).subscribe((value: string) => {
      this.searchText = value.trim();
      this.pageNumber = 0;
      this.first = 0;
      this.handleSearch(this.pageNumber, this.pageSize, value.trim(), null);
    });
  }

  async handleSearch(
    pageNo: number,
    pageSize: number,
    val: string,
    searchStatus: string[] | null
  ): Promise<void> {
    try {
      if (val !== '') {
        await this.getSubmissionBundle(pageNo, pageSize, val, this.searchQuery);
      } else {
        await this.getSubmissionBundle(pageNo, pageSize, '', this.searchQuery);
      }
    } catch (error: any) {
      this.errorToast(error.message);
    }
  }

  async handleStatusSearch(searchVal: any): Promise<void> {
    if (searchVal?.length > 0) {
      await this.getSubmissionBundle(
        this.pageNumber,
        this.pageSize,
        this.searchText,
        searchVal
      );
    } else {
      await this.getSubmissionBundle(
        this.pageNumber,
        this.pageSize,
        this.searchText,
        null
      );
    }
  }
}
