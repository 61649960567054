import { Component, Input } from '@angular/core';
import { CalendarModule } from 'primeng/calendar';
import { SharedModule } from '../../shared.module';

@Component({
  selector: 'app-calendar',
  imports: [CalendarModule, SharedModule],
  templateUrl: './calendar.component.html',
  styleUrl: './calendar.component.scss',
})
export class CalendarComponent {
  @Input() selectedDate: Date | string = '';
  @Input() label: string = '';
  @Input() required: boolean = false;

  @Input() control!: any;
}
