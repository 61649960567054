import { Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { ApiService } from '../../Services/common-services.service';

@Injectable({ providedIn: 'root' })
export class SubmissionService {
  constructor(private api: ApiService) {}

  async fetchBundles(
    pageNo: number,
    pageSize: number,
    searchText: string,
    status: any[] | null
  ): Promise<any> {
    try {
      const data = await firstValueFrom(
        this.api.post(
          `api/bundle?PageNumber=${
            pageNo + 1
          }&PageSize=${pageSize}&searchText=${searchText}`,
          status
        )
      );
      return data;
    } catch (error) {
      throw error;
    }
  }
}
