<div class="page-container submission-container">
  <p-toast />
  <div class="header-container">
    <div style="padding-bottom: 1rem">
      <p-multiSelect
        [options]="StatusList"
        optionLabel="label"
        placeholder="Search Status"
        appendTo="body"
        optionValue="label"
        [(ngModel)]="searchQuery"
        (ngModelChange)="handleStatusSearch($event)"
      />
    </div>
    <app-search-text
      (searchEvent)="searchSubject.next($event)"
      class="header"
    />

    <div>
      <form
        style="display: flex; align-items: stretch; gap: 1rem"
        [formGroup]="formData"
      >
        <app-dropdown
          [options]="CertificateList"
          [control]="formData.get('certificate')"
          placeholder="Select Certificate Type"
          optionLabel="label"
          optionValue="value"
        />
        <app-number-input
          [control]="formData.get('value')"
          [useGrouping]="false"
          inputId="minmaxfraction"
          mode="decimal"
          [minFractionDigits]="0"
          [maxFractionDigits]="5"
        />
      </form>
    </div>

    <app-button
      [disabled]="formData.invalid"
      [icon]="onSaveLoad ? 'pi pi-spin pi-spinner' : ''"
      label="Submit To Regulator"
      class="header"
      (clickEvent)="onSubmitToRegulator()"
    />
  </div>

  <p-table
    [columns]="cols"
    [value]="submissionsList"
    dataKey="id"
    [rows]="pageSize"
    [lazy]="true"
    (onPage)="pageChange($event)"
    [first]="first"
    [paginator]="true"
    [totalRecords]="bundleListCount"
    [showCurrentPageReport]="true"
  >
    <ng-template pTemplate="header" let-columns>
      <tr>
        <th *ngFor="let col of columns">{{ col.header }}</th>
      </tr>

      <ng-container *ngIf="isLoading">
        <tr *ngFor="let _ of skeletonRows">
          <th class="skeleton" *ngFor="let col of columns">
            <p-skeleton></p-skeleton>
          </th>
        </tr>
      </ng-container>
    </ng-template>

    <ng-template pTemplate="paginatorright">
      <p-select
        [options]="[
          { label: '10', value: 10 },
          { label: '20', value: 20 },
          { label: '50', value: 50 }
        ]"
        [(ngModel)]="pageSize"
        (onChange)="onPageSizeChange($event)"
      >
      </p-select>
    </ng-template>

    <ng-template pTemplate="body" let-rowData>
      <tr>
        <td>{{ rowData.schemeName }}</td>
        <td>{{ rowData.activityName }}</td>
        <td>{{ rowData.certificateName || "-" }}</td>
        <td>{{ rowData.certificateValue || "-" }}</td>
        <td>
          <img src="assets/CalendarIcon.svg" style="margin-right: 5px" />
          {{ rowData.createdAt | date : "yyyy-MM-dd" }}
        </td>
        <td>
          <div *ngIf="rowData.status === 'SUBMITTED'">
            <img src="assets/CalendarIcon.svg" style="margin-right: 5px" />
            {{ rowData.updatedAt | date : "yyyy-MM-dd" }}
          </div>
          <div *ngIf="rowData.status !== 'SUBMITTED'">-</div>
        </td>
        <td style="width: 13%">
          <div [ngClass]="getStatusClass(rowData.status)">
            {{ rowData.status }}
          </div>
        </td>
      </tr>
    </ng-template>

    <ng-template pTemplate="emptymessage" *ngIf="submissionsList.length === 0">
      <tr *ngIf="!isLoading">
        <td colspan="9">No Data found.</td>
      </tr>
    </ng-template>
  </p-table>

  <div>
    <div class="title">Bundles</div>

    <p-table [columns]="bundleCol" [value]="submittedBundleList">
      <ng-template pTemplate="header" let-columns>
        <tr>
          <th *ngFor="let col of columns">{{ col.header }}</th>
        </tr>

        <ng-container *ngIf="isLoading">
          <tr *ngFor="let _ of skeletonRows">
            <th class="skeleton" *ngFor="let col of columns">
              <p-skeleton></p-skeleton>
            </th>
          </tr>
        </ng-container>
      </ng-template>
      <ng-template pTemplate="body" let-rowData let-columns="columns">
        <tr>
          <td>{{ rowData.name }}</td>
          <td>{{ rowData.type || "-" }}</td>
          <td>
            <img src="assets/CalendarIcon.svg" style="margin-right: 5px" />
            {{ rowData.updatedAt | date : "yyyy-MM-dd" }}
          </td>
          <td style="width: 13%">
            <div [ngClass]="getStatusClass(rowData.status)">
              {{ rowData.status }}
            </div>
          </td>
          <td style="padding: 0.5rem 0 0 0">
            <img
              src="assets/DownloadIcon.svg"
              width="35px"
              height="35px"
              (click)="onDownloadForm(rowData.path)"
            />
          </td>
        </tr>
      </ng-template>

      <ng-template
        pTemplate="emptymessage"
        *ngIf="submittedBundleList.length === 0"
      >
        <tr *ngIf="!isLoading">
          <td colspan="7">No Data found.</td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</div>
