import { Component } from '@angular/core';
import { ButtonComponent } from '../../../Components/button/button.component';
import { SharedModule } from '../../../shared.module';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { InputComponent } from '../../../Components/input/input.component';
import { DropdownComponent } from '../../../Components/dropdown/dropdown.component';
import { NavigationExtras, Router } from '@angular/router';
import ROUTES from '../../../routes.const';
import { ProjectService } from '../project.services';
import { MessageService } from 'primeng/api';
import { AzureMapComponent } from '../azure-map/azure-map.component';
import { DisabledInputComponent } from '../../../Components/disabled-input/disabled-input.component';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { JobsMapComponent } from '../jobs-map/jobs-map.component';
import { SearchFieldComponent } from '../../../Components/searchField/search-text.component';
import { environment } from '../../../../environments/environment';
import {
  DwellingType,
  JobListType,
  ProjectListType,
  TableCols,
} from '../../../types';
import { DWELLING_TYPE, JOB_COLS } from '../../../constants';
import { trimFormData } from '../../common-methods';

@Component({
  selector: 'app-project-details',
  templateUrl: './project-details.component.html',
  styleUrl: './project-details.component.scss',
  providers: [MessageService],
  imports: [
    ButtonComponent,
    SharedModule,
    InputComponent,
    AzureMapComponent,
    DisabledInputComponent,
    JobsMapComponent,
    SearchFieldComponent,
  ],
})
export class ProjectDetailsComponent {
  projectData!: any;
  display: boolean = false;
  cols: TableCols[] = JOB_COLS;
  JobList: JobListType[] = [];
  formData!: FormGroup;
  DwellingType: DwellingType[] = DWELLING_TYPE;
  isLoading: boolean = true;
  onSaveLoad: boolean = false;
  onHover: number = 0;
  rowData!: JobListType;
  deleteDialog: boolean = false;
  editJob: boolean = false;
  jobDataClone: any[] = [];
  jobsOnMap!: any[];
  fullAddress: any;

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private service: ProjectService,
    private messageService: MessageService,
    private http: HttpClient
  ) {
    this.initializeForm();
  }

  initializeForm(): void {
    this.formData = this.formBuilder.group({
      jobName: ['', Validators.required],
      locationName: ['', Validators.required],
      preciseLocation: ['', Validators.required],
      resolvedZone: [''],
      // dwellingType: ['', Validators.required],
      resolvedAddress: ['', Validators.required],
    });
  }

  handleAddJob(): void {
    this.display = true;
    this.editJob = false;
    this.formData.patchValue({
      jobName: '',
      locationName: '',
      preciseLocation: '',
      resolvedZone: '',
      resolvedAddress: '',
      // dwellingType: this.projectData.type === 0 ? 'Commercial' : 'Residential',
    });
  }

  handleHover = (index: number) => {
    this.onHover = index;
  };

  getStatusClass(status: string): string {
    switch (status) {
      case 'NEW':
        return 'tag-new';
      case 'ACTIVE':
        return 'tag-active';
      case 'ASSESSMENTCOMPLETE':
        return 'tag-assessment-complete';
      case 'AUDIT':
        return 'tag-audit';
      case 'Audit_Aggregator':
        return 'tag-audit-aggregator';
      case 'SUBMITTED':
        return 'tag-submitted';
      case 'ASSESSMENT':
        return 'tag-job-assessment';
      default:
        return '';
    }
  }

  errorToast(detail: string): void {
    this.messageService.add({
      severity: 'error',
      summary: 'Error',
      detail: detail,
    });
  }

  successToast(detail: string): void {
    this.messageService.add({
      severity: 'success',
      summary: 'Success',
      detail: detail,
    });
  }

  async ngOnInit(): Promise<void> {
    this.projectData = history.state.rowData;
    this.JobList = await this.getJobData();
  }

  async getJobData(): Promise<any[]> {
    try {
      const data = await this.service.getByProjectId(this.projectData.id);

      if (data) {
        this.jobDataClone = data.jobs;
        this.jobsOnMap = data.jobs;
        this.isLoading = false;
        this.projectData = data;
      }
      return data.jobs;
    } catch (error: any) {
      this.errorToast(error.message);
      return [];
    }
  }

  navigateBack(): void {
    window.history.back();
  }

  handleEdit(rowData: JobListType): void {
    this.display = true;
    this.editJob = true;
    this.rowData = rowData;
    this.formData.patchValue({
      ...rowData,
      resolvedAddress: rowData.resolvedAddress.freeformAddress,
    });
  }

  handleDelete(rowData: JobListType): void {
    this.deleteDialog = true;
    this.rowData = rowData;
  }

  async getAddress(data: {
    address: any;
    postalCode: number;
    coordinates: string;
  }): Promise<void> {
    this.prefillZoneName(data.postalCode);
    this.fullAddress = data.address;

    this.formData.patchValue({
      resolvedAddress: data.address.freeformAddress,
      preciseLocation: data.coordinates,
    });
  }

  private async prefillZoneName(val: number): Promise<string> {
    try {
      const res = await this.service.getZoneName(val);
      this.formData.patchValue({
        resolvedZone: res,
      });
      return res;
    } catch (error: any) {
      return '';
    }
  }

  closeDialog(): void {
    this.display = false;
    this.deleteDialog = false;
  }

  async onSave(): Promise<void> {
    this.onSaveLoad = true;
    try {
      const payload = {
        ...this.formData.value,
        projectId: this.projectData.id,
        resolvedAddress: this.fullAddress,
      };
      const res = await this.service.createJobData(trimFormData(payload));
      if (res) {
        this.display = false;
        this.JobList = await this.getJobData();
        this.onSaveLoad = false;
        await this.getJobData();
        this.successToast('Job Created Successfully');
        window.location.reload();
      }
    } catch (error: any) {
      this.onSaveLoad = false;
      this.errorToast(error.error.message);
    }
  }

  async onDeleteJob(): Promise<void> {
    this.onSaveLoad = true;
    try {
      const res = await this.service.deleteJobData(this.rowData.id);
      if (res) {
        this.deleteDialog = false;
        this.onSaveLoad = false;
        this.JobList = await this.getJobData();
        this.successToast('Job Deleted Successfully');
        window.location.reload();
      }
    } catch (error: any) {
      this.errorToast(error.message);
    }
  }

  async onUpdate(): Promise<void> {
    this.onSaveLoad = true;
    try {
      const payload = {
        ...this.formData.value,
        id: this.rowData.id,
        projectId: this.projectData.id,
        resolvedAddress: this.fullAddress,
      };
      const res = await this.service.updateJobData(trimFormData(payload));
      if (res) {
        this.display = false;
        this.JobList = await this.getJobData();
        this.onSaveLoad = false;
        await this.getJobData();
        this.successToast('Job Updated Successfully');
        window.location.reload();
      }
    } catch (error: any) {
      this.onSaveLoad = false;
      this.errorToast(error.message);
    }
  }

  async handleSearch(searchVal: string): Promise<void> {
    this.JobList = this.jobDataClone.filter((item) =>
      item.jobName.toLowerCase().includes(searchVal.toLowerCase())
    );
  }
}
