import { Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { ApiService } from '../../Services/common-services.service';

@Injectable({ providedIn: 'root' })
export class SubmissionRegulatorService {
  constructor(private api: ApiService) {}

  async onSubmitToRegulator(payload: any): Promise<any> {
    try {
      const data = await firstValueFrom(
        this.api.post(`api/bundle/submit-to-regulator`, payload)
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async fetchSubmittedBundle(): Promise<any> {
    try {
      const data = await firstValueFrom(
        this.api.get(`api/bundle/submitted-documents-to-regulator`)
      );
      return data;
    } catch (error) {
      throw error;
    }
  }
}
