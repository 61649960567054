import { Component, EventEmitter, Input, Output } from '@angular/core';
import { SharedModule } from '../../shared.module';
import { ReactiveFormsModule } from '@angular/forms';

@Component({
  selector: 'app-dropdown',
  imports: [SharedModule, ReactiveFormsModule],
  templateUrl: './dropdown.component.html',
  styleUrl: './dropdown.component.scss',
})
export class DropdownComponent {
  @Input() options: any[] = [];
  @Input() label: string = '';
  @Input() selectedOption: any;
  @Input() required: boolean = false;
  @Input() placeholder: string = '';
  @Input() optionLabel: string = '';
  @Input() optionValue: string = '';

  @Input() control: any;
}
