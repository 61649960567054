import { Component } from '@angular/core';
import { SharedModule } from '../../../shared.module';
import { TableCols } from '../../../types';
import { ACTIVITIES_BUNDLE_COLS } from '../../../constants';
import { SearchFieldComponent } from '../../../Components/searchField/search-text.component';
import { NavigationExtras, Router } from '@angular/router';

@Component({
  selector: 'app-details',
  imports: [SharedModule, SearchFieldComponent],
  templateUrl: './details.component.html',
  styleUrl: './details.component.scss',
})
export class DetailsComponent {
  activitiesBundleList: any[] = [];
  isLoading: boolean = false;
  skeletonRows = new Array(10);
  onHover: number = 0;
  ActivitiesCols: TableCols[] = ACTIVITIES_BUNDLE_COLS;

  constructor(private router: Router) {}

  async ngOnInit() {
    this.activitiesBundleList = history.state.rowData.bundleActivities;
  }

  handleHover = (index: number) => {
    this.onHover = index;
  };

  getStatusClass(status: string): string {
    switch (status) {
      case 'NEW':
        return 'tag-new';
      case 'SUBMITTED':
        return 'tag-submitted';
      case 'AUDITPASS':
        return 'tag-audit-complete';
      case 'AUDITFAIL':
        return 'tag-fail';
      case 'AUDIT':
        return 'tag-audit';
      default:
        return '';
    }
  }

  activitiesDetails(rowData: any): void {
    const params: NavigationExtras = { state: { rowData } };
    this.router.navigate([`submissions/audit/${rowData.id}`], params);
  }
}
