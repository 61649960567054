import { Component } from '@angular/core';
import { TableCols } from '../../types';
import { SharedModule } from '../../shared.module';
import { DASHBOARD_JOB_COLS, DASHBOARD_TABLE } from '../../constants';
import { DASHBOARD, DASHBOARD_JOB_DATA } from '../../mock-data';
import { CertificateValueComponent } from './certificate-value/certificate-value.component';

@Component({
  selector: 'app-dashboard',
  imports: [SharedModule, CertificateValueComponent],
  templateUrl: './dashboard.component.html',
  styleUrl: './dashboard.component.scss',
})
export class DashboardComponent {
  installersData: any[] = DASHBOARD;
  cols: TableCols[] = DASHBOARD_TABLE;
  jobCols: TableCols[] = DASHBOARD_JOB_COLS;
  jobData: any[] = DASHBOARD_JOB_DATA;

  ngOnInit() {}

  getStatusClass(status: string): string {
    switch (status) {
      case 'New':
        return 'tag-new';
      case 'Audit':
        return 'tag-audit';
      case 'Completed':
        return 'tag-complete';
      case 'External Audit':
        return 'tag-external-audit';
      case 'Audit Complete':
        return 'tag-audit-complete';
      case 'Submitted':
        return 'tag-submitted';
      default:
        return '';
    }
  }
}
