import { Component } from '@angular/core';
import { SharedModule } from '../../shared.module';
import { SearchFieldComponent } from '../../Components/searchField/search-text.component';
import { ACTIVITIES_COLS } from '../../constants';
import { TableCols } from '../../types';
import { NavigationExtras, Router } from '@angular/router';
import { AuditService } from './audit.service';
import { MessageService } from 'primeng/api';
import { debounceTime, Subject } from 'rxjs';

@Component({
  selector: 'app-audit',
  imports: [SharedModule, SearchFieldComponent],
  providers: [MessageService],
  templateUrl: './audit.component.html',
  styleUrl: './audit.component.scss',
})
export class AuditComponent {
  cols: TableCols[] = ACTIVITIES_COLS;
  activitiesList: any[] = [];
  isLoading: boolean = true;
  skeletonRows = new Array(10);
  onHover: number = 0;
  first = 0;
  pageNumber: number = 0;
  searchQuery: string[] | null = [];
  pageSize: number = 10;
  searchText: string = '';
  searchSubject: Subject<string> = new Subject<string>();
  activitiesListCount: number = 0;
  StatusList: any[] = [
    { label: 'AUDIT' },
    { label: 'AUDITPASS' },
    { label: 'AUDITFAIL' },
    { label: 'GENERATED' },
    { label: 'REJECTED' },
    { label: 'NEW' },
    { label: 'SUBMITTED' },
  ];
  constructor(
    private router: Router,
    private service: AuditService,
    private messageService: MessageService
  ) {
    this.setupSearch();
  }

  async ngOnInit() {
    await this.getBundleActivities(0, 10, '', null);
  }

  activitiesDetails(rowData: any): void {
    const params: NavigationExtras = { state: { rowData } };
    this.router.navigate([`audit/${rowData.id}`], params);
  }

  private setupSearch() {
    this.searchSubject.pipe(debounceTime(500)).subscribe((value: string) => {
      this.searchText = value.trim();
      this.pageNumber = 0;
      this.first = 0;
      this.handleSearch(this.pageNumber, this.pageSize, value.trim(), null);
    });
  }

  async handleStatusSearch(searchVal: any): Promise<void> {
    if (searchVal?.length > 0) {
      await this.getBundleActivities(
        this.pageNumber,
        this.pageSize,
        this.searchText,
        searchVal
      );
    } else {
      await this.getBundleActivities(
        this.pageNumber,
        this.pageSize,
        this.searchText,
        null
      );
    }
  }

  async handleSearch(
    pageNo: number,
    pageSize: number,
    val: string,
    searchStatus: string[] | null
  ): Promise<void> {
    try {
      if (val !== '') {
        await this.getBundleActivities(pageNo, pageSize, val, this.searchQuery);
      } else {
        await this.getBundleActivities(pageNo, pageSize, '', this.searchQuery);
      }
    } catch (error: any) {
      this.errorToast(error.message);
    }
  }

  handleHover = (index: number) => {
    this.onHover = index;
  };

  errorToast(detail: string): void {
    this.messageService.add({
      severity: 'error',
      summary: 'Error',
      detail: detail,
    });
  }

  successToast(detail: string): void {
    this.messageService.add({
      severity: 'success',
      summary: 'Success',
      detail: detail,
    });
  }

  async getBundleActivities(
    pageNo: number,
    pageSize: number,
    search: string,
    searchStatus: string[] | null
  ): Promise<void> {
    try {
      const res = await this.service.fetchBundleActivities(
        pageNo,
        pageSize,
        search,
        searchStatus
      );
      if (res) {
        this.activitiesList = res.data;
        this.activitiesListCount = res.totalCount;
        this.isLoading = false;
      }
    } catch (error: any) {
      this.isLoading = false;
    }
  }

  async pageChange(event: any): Promise<void> {
    this.pageNumber = event.first / event.rows;
    this.first = event.first;
    if (this.searchQuery?.length !== 0) {
      await this.getBundleActivities(
        this.pageNumber,
        this.pageSize,
        this.searchText,
        this.searchQuery
      );
    } else {
      await this.getBundleActivities(
        this.pageNumber,
        this.pageSize,
        this.searchText,
        null
      );
    }
  }

  async onPageSizeChange(event: any) {
    this.pageSize = event.value;
    if (this.searchQuery?.length !== 0) {
      await this.getBundleActivities(
        this.pageNumber,
        event.value,
        this.searchText,
        this.searchQuery
      );
    } else {
      await this.getBundleActivities(
        this.pageNumber,
        event.value,
        this.searchText,
        null
      );
    }
  }

  getStatusClass(status: string): string {
    switch (status) {
      case 'NEW':
        return 'tag-new';
      case 'AUDIT':
        return 'tag-audit';
      case 'AUDITPASS':
        return 'tag-complete';
      case 'AUDITFAIL':
        return 'tag-external-audit';
      case 'GENERATED':
        return 'tag-audit-complete';
      case 'SUBMITTED':
        return 'tag-submitted';
      case 'REJECTED':
        return 'tag-paid';
      default:
        return '';
    }
  }
}
