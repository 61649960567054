<p-toast />
<div class="page-container project-details">
  <div>
    <div class="header-search">
      <div class="title">
        <i
          class="pi pi-arrow-left"
          style="font-size: 1rem; cursor: pointer"
          (click)="navigateBack()"
        ></i>
        <h1 class="header">Project Details</h1>
      </div>
      <app-search-text class="header" (searchEvent)="handleSearch($event)" />
    </div>

    <div class="grid-container">
      <div class="details">
        <h4>Project Name :</h4>
        {{ projectData.name }}
      </div>
      <div class="details">
        <h4>Address :</h4>
        {{ projectData.billingAddress.freeformAddress }}
      </div>
      <div class="details">
        <h4>Customer :</h4>
        {{ projectData.customerName || projectData.companyName }}
      </div>
      <div class="details">
        <h4>Status :</h4>
        {{ projectData.status }}
      </div>
      <div
        *ngIf="projectData.documents.length > 0"
        class="details"
        style="align-items: center"
      >
        <h4>Nomination Form :</h4>
        <div style="margin-top: 0.5rem">
          <img src="assets/DownloadIcon.svg" width="35px" height="35px" />
        </div>
      </div>
      <div
        *ngIf="projectData.documents.length > 0"
        class="details"
        style="align-items: center"
      >
        <h4>Quote Form :</h4>
        <div style="margin-top: 0.5rem">
          <img src="assets/DownloadIcon.svg" width="35px" height="35px" />
        </div>
      </div>
    </div>
  </div>

  <div class="horizontal-line"></div>

  <div class="header-container">
    <app-button label="Add Job" (clickEvent)="handleAddJob()" class="header" />
  </div>

  <p-table [columns]="cols" [value]="JobList">
    <ng-template pTemplate="header" let-columns>
      <tr>
        <th *ngFor="let col of columns">{{ col.header }}</th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-rowData let-columns="columns">
      <tr>
        <td class="job-name">
          {{ rowData.jobName }}
        </td>
        <td>{{ rowData.locationName }}</td>
        <td>{{ rowData.dwellingType }}</td>
        <td style="width: 15%">
          <div [ngClass]="getStatusClass(rowData.status)">
            {{ rowData.status }}
          </div>
        </td>
        <td (click)="op.toggle($event)">
          <img src="assets/ActionMenu.svg" style="cursor: pointer" />
          <p-popover #op>
            <ng-template pTemplate="content">
              <div
                (mouseover)="handleHover(1)"
                (click)="handleEdit(rowData)"
                class="list-item"
                [class.hover-bg-light-blue]="onHover === 1"
                (mouseleave)="onHover = 0"
              >
                <i class="pi pi-user-edit" style="font-size: 1.3rem"></i>
                <span style="font-size: 14px">Edit</span>
              </div>
              <div
                (mouseover)="handleHover(2)"
                (click)="handleDelete(rowData)"
                class="list-item"
                [class.hover-bg-light-red]="onHover === 2"
                (mouseleave)="onHover = 0"
              >
                <i class="pi pi-trash" style="font-size: 1.3rem"></i>
                <span style="font-size: 14px">Delete</span>
              </div>
            </ng-template>
          </p-popover>
        </td>
      </tr>
    </ng-template>
    <ng-template pTemplate="emptymessage">
      <tr>
        <td colspan="5">No Data found.</td>
      </tr>
    </ng-template>
  </p-table>

  <p-dialog
    header="Header"
    [(visible)]="display"
    [modal]="true"
    [closable]="false"
    [style]="{ width: '70vw' }"
  >
    <ng-template pTemplate="header">
      <h3>Add Job</h3>
    </ng-template>
    <ng-template pTemplate="content">
      <div class="flex">
        <form class="dialog-form" [formGroup]="formData">
          <div>
            <app-input
              label="Job Name"
              [control]="formData.get('jobName')"
              [required]="true"
            />
            <app-input
              label="Location Name"
              [control]="formData.get('locationName')"
              [required]="true"
            />
            <app-disabled-input
              label="Precise location"
              [control]="formData.get('preciseLocation')"
              [required]="true"
            />
            <app-disabled-input
              label="Resolved Zone"
              [control]="formData.get('resolvedZone')"
              [required]="true"
            />
            <app-disabled-input
              label="Resolved Address"
              [control]="formData.get('resolvedAddress')"
              [required]="true"
            />
            <!-- <app-dropdown
              [options]="DwellingType"
              [control]="formData.get('dwellingType')"
              label="Dwelling Type"
              [required]="true"
              placeholder="Select"
              optionLabel="label"
              optionValue="label"
            /> -->
          </div>
        </form>
        <div class="map-container">
          <app-azure-map
            (emitAddress)="getAddress($event)"
            [jobDetails]="formData.value"
            [projectData]="projectData"
          ></app-azure-map>
        </div>
      </div>
    </ng-template>
    <ng-template pTemplate="footer">
      <app-button
        label="Cancel"
        (clickEvent)="closeDialog()"
        className="cancel-dialog"
        style="margin-right: 1rem"
      />
      <app-button
        [label]="editJob ? 'Update' : 'Save'"
        (clickEvent)="editJob ? onUpdate() : onSave()"
        [disabled]="formData.invalid"
        [icon]="onSaveLoad ? 'pi pi-spin pi-spinner' : ''"
      />
    </ng-template>
  </p-dialog>

  <div style="padding: 2rem 0" *ngIf="jobsOnMap">
    <app-jobs-map [jobs]="jobsOnMap"></app-jobs-map>
  </div>

  <p-dialog
    header="Header"
    [(visible)]="deleteDialog"
    [modal]="true"
    [closable]="false"
    [style]="{ width: '30vw' }"
  >
    <ng-template pTemplate="header"> </ng-template>
    <ng-template pTemplate="content">
      <h1>Are you sure you want to delete this Job ?</h1>
    </ng-template>
    <ng-template pTemplate="footer">
      <app-button
        label="Cancel"
        (clickEvent)="closeDialog()"
        className="cancel-dialog"
        style="margin-right: 1rem"
      />
      <app-button label="Delete" (clickEvent)="onDeleteJob()" />
    </ng-template>
  </p-dialog>
</div>
