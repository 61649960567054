import { Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { ApiService } from '../../Services/common-services.service';

@Injectable({ providedIn: 'root' })
export class AuditService {
  constructor(private api: ApiService) {}

  async fetchBundleActivities(
    pageNo: number,
    pageSize: number,
    search?: string,
    payload?: string[] | null
  ): Promise<any> {
    try {
      const statusSearch = payload?.length !== 0 ? payload : null;
      const data = await firstValueFrom(
        this.api.post(
          `api/audit/bundle-activities?PageNumber=${
            pageNo + 1
          }&PageSize=${pageSize}&searchText=${search}`,
          statusSearch
        )
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async fetchAuditFields(id: number): Promise<any> {
    try {
      const data = await firstValueFrom(
        this.api.get(`api/audit/job-activity/${id}/audit-fields`)
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async createFieldStatus(payload: any, id: number): Promise<any> {
    try {
      const data = await firstValueFrom(this.api.post(`api/audit`, payload));
      return data;
    } catch (error) {
      throw error;
    }
  }

  async updateFieldStatus(payload: any, id: number): Promise<any> {
    try {
      const data = await firstValueFrom(this.api.put(`api/audit`, payload));
      return data;
    } catch (error) {
      throw error;
    }
  }
}
